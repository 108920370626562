import { TextField } from "@mui/material";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { Country, State, City } from "country-state-city";
import * as yup from "yup";
import { useState } from "react";
import AddRoadIcon from '@mui/icons-material/AddRoad';
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import { useNavigate } from "react-router-dom";
import { Toast } from "../helpers/sweetAlert";
import { updateAddress,addAddress } from '../Network/appApis'
import { useDispatch } from "react-redux";
import { editAddressAction, addAddressAction} from '../store/Actions/isAuthAction'
import { useTranslation } from 'react-i18next';


const AddressForm = ({state,title,btnName,functionId}) => {
  
    const { t } = useTranslation(); // Hook to access translations
  
    const validationSchema = yup.object({
      country: yup.string("Enter your Country").required(t("AddressForm.CountryValidation")),
      city: yup.string("Enter your City").required(t("AddressForm.CityValidation")),
      phoneNumber: yup
        .number("Enter your Phone Number")
        .required(t("AddressForm.PhoneNumberValidation")),
      address: yup
        .string("Enter your Address")
        .required(t("AddressForm.FullAddressValidation")),
    });
  
    const Navigate = useNavigate() 
    const [country] = useState(state.country ? Country.getAllCountries().filter(
      (item) => item.name === state.country
      )[0]:null)
      
    const [city] = useState(state.city ? State.getStatesOfCountry(country?.isoCode).filter(
        (item) => item.name === state.city
        )[0]:null)
        
    const [location] = useState(state.location ? City.getCitiesOfState(country?.isoCode, city?.isoCode).filter(
          (item) => item.name === state.location
          )[0]:null)
          
    const [countryCode, setCountryCode] = useState(state?.country ? country?.isoCode : '');
    const [cityCode, setCityCode] = useState(state.city ? city?.isoCode : '');
    const dispatch = useDispatch()

  const formik = useFormik({
    initialValues:state,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(functionId === 'ADDADDRESS'){
        (async function(){
          try{
            const res = await addAddress(values)
            
            if(res.status === 201){
              dispatch(addAddressAction(res.data.data))
              Toast('success',`Address Successfully Added!`)
              Navigate('/shoppingcart/choosePaymentMethod')
            }
          }catch(err){
            Toast('error',`Something Went Wrong Please Try Again Later!`)
            console.log(err)
          }
        })()


      }else if(functionId === 'EDITADDRESS'){
        (async function(){
          try{
            const res = await updateAddress(values)
            
            if(res.status === 201){
              dispatch(editAddressAction(res.data.data))
              Toast('success',`Address Successfully Edited!`)
              Navigate('/shoppingcart/choosePaymentMethod')
            }
          }catch(err){
            Toast('error',`Something Went Wrong Please Try Again Later!`)
            console.log(err)
          }
        })()
      }
    },
  });

  return (
    <>
    <div className="container d-flex justify-content-center my-5">
      <div className="col-12 col-lg-7">
       <h4>{title}</h4>
        <form
          className="d-flex flex-wrap justify-content-center"
          onSubmit={formik.handleSubmit}
        >
          <div className="my-2 col-12 col-sm-4">
             <Autocomplete
                onChange={(e, value) => {
                formik.setFieldValue("country", value?.name || "");
                if (value !== null) {
                  setCountryCode(e.target.attributes.isocode.value);
                }
             }}
             size="small"
             defaultValue={country}
             onBlur={formik.handleBlur}
             options={Country.getAllCountries()}
             getOptionLabel={(option) => option.name}
             renderOption={(props, option) => (
               <Box
                 isocode={option.isoCode}
                 component="li"
                 sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                 {...props}
               >
                 <img
                   loading="lazy"
                   width="20"
                   src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                   alt=""
                 />
                 {option.name}
               </Box>
             )}
             renderInput={(params) => (
                 <div className="mx-1 d-flex align-items-center">
                 <PublicIcon style={{width:'30px',height:'30px'}}/>
                 <TextField
                 {...params}
                 label={t("AddressForm.Country")}
                 name="country"
                 error={
                   formik.touched.country && Boolean(formik.errors.country)
                 }
                 helperText={formik.touched.country && formik.errors.country}
                 inputProps={{
                   ...params.inputProps,
                   autoComplete: "new-password",
                 }}
               />
               </div>
             )}
           />
          </div>
          
          <div className="my-2 col-12 col-sm-4">
              <Autocomplete
                onChange={(e, value) => {
                  formik.setFieldValue("city", value?.name || "");
                  if (value !== null) {
                    setCityCode(e.target.attributes.isocode.value);
                  }
                }}
                defaultValue={city}
                size="small"
                onBlur={formik.handleBlur}
                options={State.getStatesOfCountry(countryCode)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    isocode={option.isoCode}
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  
                    <div className="mx-1 d-flex align-items-center">
                    <LocationCityIcon style={{width:'30px',height:'30px'}}/>
                    <TextField
                    {...params}
                    label={t("AddressForm.City")}
                    name="city"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                  </div>
                )}
              />
          </div>

          <div className="my-2 col-12 col-sm-4">
              <Autocomplete
                onChange={(e, value) =>
                  formik.setFieldValue("location", value?.name || "")
                }
                size="small"
                defaultValue={location}
                options={City.getCitiesOfState(countryCode, cityCode)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                    <div className="mx-1 d-flex align-items-center">
                    <LocationOnIcon style={{width:'30px',height:'30px'}}/>
                          <TextField
                            {...params}
                            label={t("AddressForm.Location")}
                            name="location"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            />
                    </div>
                )}
              />
          </div>

          <div className="my-2 col-12 col-sm-4">
            <div className="mx-1 d-flex align-items-center">
            <ApartmentIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                name="building"
                type="text"
                size="small"
                label={t("AddressForm.Building")}
                value={formik.values.building}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-12 col-sm-4">
            <div className="mx-1 d-flex align-items-center">
            <FormatListNumberedIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                name="floor"
                type="text"
                size="small"
                label={t("AddressForm.Floor")}
                value={formik.values.floor}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-12 col-sm-4">
            <div className="mx-1 d-flex align-items-center">
            <HomeIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                type="text"
                name="apartment"
                size="small"
                label={t("AddressForm.Apartment")}
                value={formik.values.apartment}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-12 col-sm-6">
            <div className="mx-1 d-flex align-items-center">
            <PhoneIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                type="number"
                name="phoneNumber"
                size="small"
                label={t("AddressForm.PhoneNumber")}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              />
            </div>
          </div>

          <div className="my-2 col-12 col-sm-6">
            <div className="mx-1 d-flex align-items-center">
            <PhoneLockedIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                type="number"
                name="secondaryPhoneNumber"
                size="small"
                label={t("AddressForm.AdditionalPhoneNumber")}
                value={formik.values.secondaryPhoneNumber}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-12">
            <div className="mx-1 d-flex align-items-center">
            <AddRoadIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                multiline
                minRows={2}
                type="text"
                size="small"
                name="address"
                label={t("AddressForm.FullAddress")}
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </div>
          </div>

          <button className="btn btn-dark my-2" type="submit">
            {btnName}
          </button>
        </form>
      </div>
    </div>
    </>

  );
};

export default AddressForm;
