import { useDispatch } from "react-redux";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useSelector } from "react-redux";
import NotAuth from "../../Components/notAuthenticated";
import cashImg from '../../images/cash.png'
import visaImg from '../../images/visa.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Toast } from "../../helpers/sweetAlert";
import { createSession } from "../../Network/appApis";
import { resetShoppingCartAction } from '../../store/Actions/shoppingCartAction'
import { useTranslation } from 'react-i18next';

function ChoosePaymentMethod() {

    const Dsipatch = useDispatch()
    const Navigate = useNavigate()
    
    const { t } = useTranslation(); // Hook to access translations
    const isLogged = useSelector((state) => state.isAuth.isLogged);  
    const allCartProducts = useSelector((state)=>state.shoppingCart.products)
    const user = useSelector((state) => state.isAuth.user?._id);
    const address = useSelector((state) => state.isAuth.user?.address);
    let lang = useSelector((state)=>state.lang)
    
    const handleConfirmOrder = () => {
        if(!allCartProducts.length){
            Navigate('/productList')
            Toast('warning',`Please Add Products First!`)
            return
        }else if(!address){
            Navigate('/addAddress')
            Toast('warning',`Please Add Address First!`)
            return
        }
        else{
            Navigate('/order/confirmOrder')
        }    
    }

    const handleGateway = () =>{
        
    if(!allCartProducts.length){
        Navigate('/productList')
        Toast('warning',`Please Add Products First!`)
        return
    }else if(!address){
        Navigate('/addAddress')
        Toast('warning',`Please Add Address First!`)
        return
    }

    let cartProducts = []
    allCartProducts.forEach((item)=>{
        cartProducts.push({_id:item._id, quantity:item.quantity})
    })

    let orderObj = {
        user,
        cartProducts,
        lang
    };

    (async function(){
        try{
            const res = await createSession(orderObj)
            if(res.status === 201){
                const sessionId = res.data?.data?.data?.session_id
                if(sessionId){
                    Dsipatch(resetShoppingCartAction())
                    window.location.replace(`${process.env.REACT_APP_THAWANI_URL}/${sessionId}?key=${process.env.REACT_APP_THAWANI_PUBLISHABLE_KEY}`);
                }else{
                    Toast('warning',`Session didn't Created Successfully, Try Agian!`)
                }
                    Toast('success',`Session Successfully Created!`)
                }

        }catch(err){
            if(err?.response?.data?.Error === 'Error: StockError '){
                Dsipatch(resetShoppingCartAction())
                Navigate('/shoppingCart')
                Toast('warning',`Stock Error! Please Refill Your Shopping Cart`)
            }else{
                Toast('error',`Something Went Wrong Please Try Again Later!`)
            }
        }
        })()
    } 

    return ( 
    <>
        {isLogged ? (
            <>
                <div className="container my-5">
                   <h4>{t("PaymentMethod.ChoosePaymentMethod")}</h4>
                   <div className="d-flex justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-9 col-10 d-flex justify-content-around align-items-center">
                    <Card onClick={handleGateway} className='my-5' sx={{ minWidth: 200 }}>
                        <CardActionArea>
                            <CardMedia
                              component="img"
                              height="150"
                              image={visaImg}
                              alt={t("PaymentMethod.Visa")}
                            />
                            <CardContent className='text-center'>
                                <Typography gutterBottom variant="h6" component="div">
                                   <CreditCardIcon/> {t("PaymentMethod.Visa")}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>

                    <Card sx={{ minWidth: 200 }} onClick={handleConfirmOrder}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="150"
                            image={cashImg}
                            alt={t("PaymentMethod.Cash")}
                            />
                        <CardContent className='text-center'>
                            <Typography gutterBottom variant="h6" component="div">
                                <AttachMoneyIcon/> {t("PaymentMethod.Cash")}
                            </Typography>
                        </CardContent>
                        </CardActionArea>
                    </Card>
                    </div>
                   </div>
                </div>
            </>
            ) : (<NotAuth path='/shoppingcart/choosePaymentMethod'/>)
        }
    </> );
}

export default ChoosePaymentMethod;