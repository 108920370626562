import UserForm from "../../Components/userForm";

function NewUser() {

  const values ={
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: "",
    password: "",
    confirmPassword: "",
    }

  return (
    <>
      <UserForm state={values} functionId='ADDUSER' btnName="Add New User" />
    </>
  );
}

export default NewUser;
