import { useEffect, useState } from "react";
import { getAboutUsInfo } from "../Network/appApis";
import { useSelector } from "react-redux";
import NoImage from "../images/noImage.jpg";

const About = () => {
    const [aboutInfo, setAboutInfo] = useState(null)
    let lang = useSelector((state)=>state.lang)

    useEffect(()=>{
    (async function(){
      try{
        const res = await getAboutUsInfo()
        setAboutInfo(res.data.data)
      }catch(err){}
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
  <>
  <div className="container my-5">
  <div>
  {aboutInfo?.aboutUs?.length ? aboutInfo.aboutUs.map((item, i)=>{
  return(
    <div key={item._id} className="container py-3">
      <div className="row h-100 align-items-center py-3">
        {
          i % 2 === 0 ?
          <>
            <div className='col-lg-6'>
              <h1 className="display-4 my-3">{item?.title?.[lang]}</h1>
              <p className="lead text-muted mb-0">{item?.description?.[lang]}</p>
            </div>
            <div className="col-lg-6 d-none d-lg-block"><img 
                src={item?.image ? item?.image: NoImage} 
                alt='' className="img-fluid"/>
            </div>
          </>
          :
          <>
            <div className="col-lg-6 d-none d-lg-block"><img 
              src={item?.image ? item?.image: NoImage} 
              alt='' className="img-fluid"/>
            </div>
            <div className='col-lg-6'>
              <h1 className="display-4">{item?.title?.[lang]}</h1>
              <p className="lead text-muted mb-0">{item?.description?.[lang]}</p>
            </div>
          </>
        }
      </div>
    </div>
)
}):''}
  </div>

<div className="py-5">
{ aboutInfo?.members?.length ?
  <div className="container">
      <div className="row mb-4">
        <div className="col-lg-5">
          <h2 className="display-4 font-weight-light">Our team</h2>
          <p className="font-italic text-muted">We're Pleased To Introduce Our Team To You.</p>
        </div>
        </div>
    </div>: ""
}

<div className="row text-center">
{aboutInfo?.members?.length ? aboutInfo.members.map((item)=>{
  return(
        <div key={item._id} className="col-xl-3 col-sm-6 mb-2">
          <div className="rounded shadow-sm py-2 px-4"><img src={item?.image} alt={item.name} width="100" className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"/>
            <h5 className="mb-0">{item?.name}</h5><span className="small text-uppercase text-muted">{item?.position}</span>
          </div>
        </div>
 )
}):""}
</div>
</div>  
</div>  
</>
)};

 export default About