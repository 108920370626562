
import "../styles/chart.css";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function Chart({data, title}) {
    return ( <>
    <div className="col-12 col-sm-12 col-md-6 col-lg-8" >
    <div className="chart p-3 m-2" style={{minHeight:'500px'}}>
    <div className="chart-title">{title}</div>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="totalProfit" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="count" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey={'_id'} stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chart-Grid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="totalProfit"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#totalProfit)"
          />
          <Area type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#count)" />
         </AreaChart>
      </ResponsiveContainer>
    </div>
    </div>
    </> );
}

export default Chart;