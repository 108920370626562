import "../styles/widget.css";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEffect, useState } from "react";

const Widget = ({ type, count }) => {
  
  let [data, setData] = useState([])
  
    useEffect(()=>{
          switch (type) {
            case "user":
              
              setData({
                title: "USERS",
                diff: 5,     
                link: "See all users",
                router:'/users',
                icon: (
                  <PersonOutlinedIcon
                    className="widget-icon"
                    style={{
                      color: "crimson",
                      backgroundColor: "rgba(255, 0, 0, 0.2)",
                    }}
                  />
                ),
              })
              break;

            case "order":
   
              setData({
                title: "ORDERS",
                diff: -10,
                link: "View all Orders",
                router:'/orders',
                icon: (
                  <ShoppingCartOutlinedIcon
                    className="widget-icon"
                    style={{
                      backgroundColor: "rgba(218, 165, 32, 0.2)",
                      color: "goldenrod",
                    }}
                  />
                ),
              })
              break;
            
              case "delivered":
              
              setData({
                title: "DELIVERED",
                diff: 10,
                link: "View All Orders",
                router:'/orders',
                icon: (
                  <DoneAllIcon
                    className="widget-icon"
                    style={{ 
                      backgroundColor: "rgba(0, 128, 0, 0.2)",
                       color: "green"}}
                  />
                ),
              })

              break;

            case "product":

              setData({
                title: "PRODUCTS",
                diff: 20,
                link: "See All Products",
                router:'/products',
                icon: (
                  <ShoppingBagIcon
                    className="widget-icon"
                    style={{
                      backgroundColor: "rgba(128, 0, 128, 0.2)",
                      color: "purple",
                    }}
                  />
                ),
              })
              break;
            default:
              break;
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }},[])
  
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
      <div className="widget p-3 m-2">
        <div className="d-flex flex-column justify-content-between">
          <span style={{fontWeight:'bold'}} className="text-secondary">{data.title}</span>
          <span className="py-2" style={{ fontSize: "28px", fontWeight: "300" }}>
            {count}
          </span>
            <Link className="widget-link text-secondary" to={data.router}> {data.link} </Link>
        </div>
        <div className="d-flex flex-column justify-content-between">
          
          {/* {
            data.diff > 0 ? <div style={{ color: "green" }}>
            <KeyboardArrowUpIcon />
            {data.diff} %
          </div>
          :
          <div style={{ color: "red" }}>
            <KeyboardArrowDownIcon />
            {data.diff} %
          </div>
          }
        */}
          {data.icon}
        </div>
      </div>
    </div>
  );
};

export default Widget;
