import { styled } from '@mui/material/styles';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from "@mui/icons-material/Delete";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { removeAboutUs } from '../../../Network/appApis';
import { Toast } from '../../../helpers/sweetAlert';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AboutUs({list, parentCallback}) {

  const [aboutUsList, setAboutUsList] = useState(list);
 

  function deleteSection(row){
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Delete Section',
      text: `Are you sure you want to Delete ${row.title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        (async ()=>{
          try {
            const res = await removeAboutUs(row._id)
            if (res.status === 200) {
              Toast("success",`Section Successfully Deleted`);
              setAboutUsList(res.data.data)
              parentCallback(res.data.data)
            }
          } catch (err) {
            Toast("error","Something Went Wrong");
          }                  
        })()
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
  }
  
  return ( 
    <>
      <div className="d-flex flex-wrap justify-content-around mx-5 my-2">
        <div className='d-flex w-100 justify-content-between my-2'>
            <h2>All Sections</h2>
          <Link to='addAboutUs'>
            <button className='btn btn-outline-dark' >Add Section</button>
          </Link>
        </div>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#Id</StyledTableCell>
            <StyledTableCell align="right">Image</StyledTableCell>
            <StyledTableCell align="right">Title</StyledTableCell>
            <StyledTableCell align="right">Description</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {aboutUsList.map((row) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {row._id}
              </StyledTableCell>
              <StyledTableCell align="right">
              <img
                  style={{width:"50px", height:"50px"}}
                  src={row.image}
                  alt=""
                  className="userdetails-itemImg"
              />
              </StyledTableCell>
              <StyledTableCell align="right">{row.title?.['en']}</StyledTableCell>
              <StyledTableCell align="right">
            
                  {
                    row.description?.['en'] && row.description?.['en'].split(' ').length <= 5 ?
                    row.description?.['en'] :
                    row.description?.['en'] && row.description?.['en'].split(' ').splice(0,5).join(' ')+ "..."
                  }
              </StyledTableCell>
              <StyledTableCell align="right">
                
                <Link to='updateAboutUs' state={row}>
                  <BorderColorIcon className='m-2' style={{color:'black',cursor:'pointer'}}/>
                </Link>
                
                <DeleteIcon onClick={()=>deleteSection(row)} className='m-2' style={{cursor:'pointer'}}/>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          <div className='d-flex justify-content-center w-100'>
            {aboutUsList.length === 0 && <h3>NO DATA FOUND</h3>}
          </div>
  </div>    
    </> 
    );
}

export default AboutUs;