import { useSelector } from "react-redux";


function Profile() {

    let user = useSelector((state)=>state.isAuth.user)

    return ( 
    <>
        <div className='container'>    
      <div className="user-container d-flex justify-content-between align-items-center">
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>Profile</h1> 
      </div>
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
           <div className="p-2">
                Account ID: #{user?._id}
           </div>
           <div className="p-2">
                First Name: {user?.firstName}
           </div>
           <div className="p-2">
               Last Name: {user?.lastName}
           </div>
           <div className="p-2">
               Email: {user?.email}
           </div> 
            <div className="p-2">
               Created At: {new Date(user?.createdAt).toDateString()}
          </div>
        </div>
     </div>  

    </> );
}

export default Profile;