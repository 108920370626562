import { SET_LOGIN_IS_AUTH, SET_LOGOUT_IS_AUTH, SET_EDIT_ADDRESS, SET_DELETE_ADDRESS, SET_ADD_ADDRESS, SET_EDIT_PROFILE } from "./types";

export const loginAction = (payload) => ({
    type:SET_LOGIN_IS_AUTH,
    payload
})

export const logoutAction = () => ({
    type:SET_LOGOUT_IS_AUTH
})

export const addAddressAction = (payload) =>({
    type:SET_ADD_ADDRESS,
    payload
})

export const editAddressAction = (payload) =>({
    type:SET_EDIT_ADDRESS,
    payload
})

export const editProfileAction = (payload) =>({
    type:SET_EDIT_PROFILE,
    payload
})

export const deleteAddressAction = (payload) =>({
    type:SET_DELETE_ADDRESS,
    payload
})
