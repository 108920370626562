import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from './Translation/en.json';
import arTranslation from './Translation/ar.json';

// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ar: { translation: arTranslation }
  },
  // lng: 'en', // Set the default language
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
  fallbackLng: 'en', // Fallback language if a translation is missing
  interpolation: { escapeValue: false } // React already escapes the output
});
