import CategoryForm from "../../Components/categoryForm";
import { useLocation } from 'react-router-dom'

function EditCategory() {

    const location = useLocation()
    let values = location.state
    values.id = values._id
  
    return ( 
    <>
      <CategoryForm state={values} functionId='EDITCATEGORY' btnName="Update Category" />
    </> );
}

export default EditCategory;