import { Facebook, Instagram, Twitter, WhatsApp, } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { updateSocialMediaLinkes } from "../../../Network/appApis";
import { Toast } from "../../../helpers/sweetAlert";
import { useState } from "react";


function SocialMediaLinksForm({state, parentCallback}) {

  const [update, setUpdate] = useState(true)
  const [formState, setFormState] = useState({...state})

    const formik = useFormik({
        initialValues:formState,
        enableReinitialize:true,
        onSubmit: (values) => {
            (async function(){
              try{
                const res = await updateSocialMediaLinkes(values)
                
                if(res.status === 200){
                  Toast('success',`Social Media Links Updated!`)
                  setFormState(res.data.data)
                  setUpdate(!update)
                  parentCallback(res.data.data)
                }
              }catch(err){
                Toast('error',`Something Went Wrong Please Try Again Later!`)
              }
            })()
          },
        })

        
    return ( 
    <>
    <form
          className="d-flex flex-wrap justify-content-around mx-5 my-2"
          onSubmit={formik.handleSubmit}
        >
          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <Facebook style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="facebook"
                type="text"
                size="small"
                label="Facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <Instagram style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="instagram"
                type="text"
                size="small"
                label="Instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <Twitter style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="twitter"
                type="text"
                size="small"
                label="Twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <WhatsApp style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="whatsapp"
                type="text"
                size="small"
                label="Whatsapp"
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='col-11 col-sm-4 d-flex justify-content-around'>
            {
                !update &&
                <>
                <span className="btn btn-danger my-2" onClick={()=>{
                  formik.handleReset()
                  setUpdate(!update)
                }}>
                    Cancel
                </span>
                <button className="btn btn-success my-2" type="submit">
                    Confirm
                </button>
                </>
            }

            {
                update &&
                <>
                  <span onClick={()=>setUpdate(!update)} className="btn btn-success my-2">
                      Update
                  </span>
                </>
            }
            
          </div>
        </form>
    </> );
}

export default SocialMediaLinksForm;