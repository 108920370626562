import Slider from "../Components/Slider";
import Categories from "../Components/Categories";
import HomeProducts from "../Components/homeProducts";

function Home() {
    return (
    <>
        <Slider/>
        <div className='container'>
            <Categories/>
            <HomeProducts/>
        </div>
    </> );
}

export default Home;