import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "@mui/material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeAction } from "../store/Actions/themeAction";
import { changeLangAction } from "../store/Actions/langAction";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logoutAction } from "../store/Actions/isAuthAction";
import { Toast } from "../helpers/sweetAlert";
import { getAllCategories, getShippingPrice } from '../Network/appApis'
import Swal from "sweetalert2";
import lightLogo from '../images/lightLogo.jpeg'
import darkLogo from '../images/darkLogo.jpeg'
import { useTranslation } from 'react-i18next';


function MyNavbar() {
  let lang = useSelector((state)=>state.lang)
  const { i18n } = useTranslation();
  const { t } = useTranslation(); // Hook to access translations

  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const location = useLocation()
  const [searchKeyword , setSearchKeyword] = useState('')
  let cartCount = useSelector((state)=>state.shoppingCart.count)
  let isLogged = useSelector((state)=>state.isAuth.isLogged)
  let theme = useSelector((state)=>state.theme.color)
  let [allCategories, setAllCategories] = useState([])
  let [shippingOffer, setShippingOffer] = useState({en:"Thanks For Choosing MK Trading", ar:"شكرا لاختياركم MK Trading"})
  let navStyle={color:'grey',textDecoration:'none'}
  const initialState = location.state
    
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLangAction())
  };
  
  let iconClass = {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    marginLeft: "10px",
    color:'gray'
  };

  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllCategories()
        setAllCategories(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
  },[])

  useEffect(()=>{
    (async function(){
      try{
        const res = await getShippingPrice()
        setShippingOffer(
          res.data.data[0]?.shippingOffer?
          res.data.data[0]?.shippingOffer:
          {en:"Thanks For Choosing MK Trading", ar:"شكرا لاختياركم MK Trading"}
          )
      }catch(err){
        console.log(err)
      }
    })()
  },[])

  function setTheme(){
    dispatch(themeAction(theme === 'light' ? 'dark' : 'light'))
  }

  function searchInput(){
      Navigate('/productList',{state : {...initialState,searchKeyword}})
  } 
  
  function logout(){
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Logout',
      text: `Are you sure you want to Logout?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Logout!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutAction())
        Toast('success','Successfully logged out !')
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
  }

  return (
    <>
      <h6 className='bg-dark text-light m-0 p-2 text-center'>{shippingOffer?.[lang]}</h6>    
      <Navbar bg={theme} collapseOnSelect expand="md">
        <Container className="d-flex flex-wrap" fluid>
          <div className="d-flex justify-content-between flex-wrap w-100 px-3">
            <span className="col-sm-3 col-12">
              <div className="input-group h-50">
                <input
                  value={searchKeyword}
                  onChange={(e)=>{setSearchKeyword(e.target.value)}}
                  type="text"
                  className="form-control"
                  placeholder={t("Navbar.SearchProducts")}
                />
                <div className="input-group-append">
                  <button className="btn btn-dark mx-1" onClick={searchInput}>
                    <SearchIcon style={{ cursor: "pointer" }} />
                  </button>
                </div>
              </div>
            </span>
            <span className="col-sm-1 col-3 pt-2">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </span>
            <span className="col-sm-3 col-3 pt-2" style={{ textAlign: "center" }}>
              {
                theme === 'light' ?
                <img src={lightLogo} alt="MK-logo" width={50} height={50}/>
                :
                <img src={darkLogo} alt="MK-logo" width={50} height={50}/>
              }
            </span>
            <div className="col-sm-3 col-6 pt-2" style={{ textAlign: "right" }}>
              {
                theme === 'light' ?
                <DarkModeIcon onClick={setTheme} style={iconClass} />
                :
                <LightModeIcon onClick={setTheme} style={iconClass} /> 
              }
              
                  {isLogged && <Link style={navStyle} to='/notifications'>
                    <Badge variant="dot" color="primary">
                      <NotificationsIcon style={iconClass} />
                    </Badge>
                  </Link>}
                  <Link style={navStyle} to='/profile'>
                    <PersonIcon style={iconClass} />
                  </Link>
                <Badge badgeContent={cartCount} color="primary">
                  <Link style={navStyle} to='/shoppingcart'>
                    <ShoppingCartIcon style={iconClass} />
                  </Link>
                </Badge>
            </div>
          </div>
          <div className="w-100">
            <Navbar.Collapse className="justify-content-center">
              <Nav>
                <Nav.Link eventKey="1" className="mx-3 text-secondary" as={Link} style={navStyle} to='/'>{t('Navbar.Home')}</Nav.Link>
                <Nav.Link eventKey="2" className="mx-3 text-secondary" as={Link} style={navStyle} to='/productList'>{t('Navbar.Products')}</Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">{t('Navbar.Categories')}</span>}
                  className="mx-3"
                >{allCategories.length?
                  allCategories.map((category)=>{
                    return(
                      <NavDropdown.Item eventKey="3" key={category._id} as={Link} to='/productList' state={{...initialState,category:category._id}}>{category.name?.[lang]}</NavDropdown.Item>
                    )
                  })
                :
                <NavDropdown.Item disabled as={Link} to='/productList' state={initialState}>Working On Categories..</NavDropdown.Item>
                }
                  </NavDropdown>
                <Nav.Link eventKey="4" className="mx-3 text-secondary" as={Link} style={navStyle} to='/about'>{t('Navbar.Aboutus')}</Nav.Link>
                <Nav.Link eventKey="5" className="mx-3 text-secondary" as={Link} style={navStyle} to='/contact'>{t('Navbar.Contactus')}</Nav.Link>
                {
                  isLogged ? 
                  <Nav.Link eventKey="6" className="mx-3 text-secondary" as={Link} onClick={logout} style={navStyle}>{t('Navbar.Logout')}</Nav.Link>
                  :
                  <>
                    <Nav.Link eventKey="7" className="mx-3 text-secondary" as={Link} style={navStyle} to='/login'>{t('Navbar.Login')}</Nav.Link>
                    <Nav.Link eventKey="8" className="mx-3 text-secondary" as={Link} style={navStyle} to='/register'>{t('Navbar.Register')}</Nav.Link>
                  </>
                }
                <button onClick={()=>{changeLanguage(lang === 'en' ? "ar" : "en")}} className="btn btn-outline-dark">{lang === 'en' ? "ar" : "en"}</button>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default MyNavbar;
