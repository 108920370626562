import OrderStepper from '../Components/orderStepper';
import { Link } from "react-router-dom";
import { payThawani } from "../../src/Network/appApis";
import { Toast } from '../helpers/sweetAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function OrderCard({order}) {

  const { t } = useTranslation(); // Hook to access translations
  let lang = useSelector((state)=>state.lang)

  let status_Paid = {
    fontWeight: "bold",
    borderRadius:'10px',
    backgroundColor: '#b3cf99',
    padding:'5px'
  }

  let status_Pending = {
    fontWeight: 'bold',
    borderRadius:'10px',
    backgroundColor: '#f1ee8e',
    padding:'5px'
  }

  let handlePayThawani = async () => {
    try{
      let response = await payThawani(order._id, lang)
      if(response.status === 201){
        const sessionId = response.data?.data?.data?.session_id
        if(sessionId){
            window.location.replace(`${process.env.REACT_APP_THAWANI_URL}/${sessionId}?key=${process.env.REACT_APP_THAWANI_PUBLISHABLE_KEY}`);
        }else{
            Toast('warning',`Session didn't Created Successfully, Try Agian!`)
        }
            Toast('success',`Session Successfully Created!`)
        }
    }catch(err){
      Toast('error',`Something Went Wrong Please Try Again Later!`)
    }
  }


  return (
    <>
      <div className="my-2">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex align-items-center fw-bold">
                <div>{t("ConfirmOrder.OrderID")} : #{order.id}</div>
              </div>
              <div className="my-1">
                <div>{t("ConfirmOrder.NumberProducts")} : {order.cartProducts.length}</div>
                <div>{t("ConfirmOrder.ProductsPrice")} : {order.productsPrice} {t("ConfirmOrder.OMR")}</div>
                <div>{t("ConfirmOrder.ShippingPrice")} : {order.shippingPrice} {t("ConfirmOrder.OMR")}</div>
                <div>{t("ConfirmOrder.TotalPrice")} : {order.totalPrice} {t("ConfirmOrder.OMR")}</div>
                <div>{t("ConfirmOrder.PaymentMethod")} : {order.paymentMethod === 'VISA CARD' ? t("PaymentMethods.Visa"): t("PaymentMethods.Cash")}</div>
                <div>{t("ConfirmOrder.PaymentStatus")} : 
                  <span style={order.paymentStatus === 'Paid' ? status_Paid : status_Pending}>
                    {order.paymentStatus === "Paid" ? t("PaymentStatus.Paid"): t("PaymentStatus.Pending")}
                  </span>
                </div>
                <div>{t("ConfirmOrder.PlacedOn")} : {new Date(order.createdAt).toDateString()}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <div className="mx-lg-5 d-flex flex-wrap align-items-start justify-content-between">
              <div className="fw-bold col-lg-4 col-12">{t("ConfirmOrder.OrderStatus")} : 
                {
                  order.status === 'Placed' ? t("Stepper.Placed") :
                  order.status === 'Rejected' ? t("Stepper.Rejected") :
                  order.status === 'Accepted' ? t("Stepper.Accepted") :
                  order.status === 'Shipped' ? t("Stepper.Shipped") :
                  order.status === 'Delivered' ? t("Stepper.Delivered") : ""
                }
              </div>
              
              <div className='d-flex justify-content-around col-lg-8 col-12 my-2'>
                { order.status !== 'Rejected' && order.paymentStatus === 'Pending' && order.paymentMethod === 'VISA CARD' ?
                  <button onClick={handlePayThawani} className='btn btn-warning'>{t("ConfirmOrder.PayNow")}</button>
                  : ''
                }
                <Link to={`/order/${order._id}`} state={{orderObj:order}}>
                  <button className="btn btn-dark text-uppercase">{t("ConfirmOrder.OrderInfo")}</button>
                </Link>
              </div>
              
            </div>
            <div className="d-flex align-items-start justify-content-between">   
                
                <OrderStepper activeStep={
                  order.status === 'Placed' ? 0 :
                  order.status === 'Rejected' ? 1 :
                  order.status === 'Accepted' ? 1 :
                  order.status === 'Shipped' ? 2 :
                  order.status === 'Delivered' ? 3 : 0
                }
                orderStatus = {order.status}
                />
            </div>
          </div>
        </div>
      </div>
      <hr className="mx-4" />
    </>
  );
}

export default OrderCard;

