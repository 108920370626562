import { BrowserRouter as Router} from 'react-router-dom';
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Index from './Pages';
import IndexAdmin from './Admin/Pages/indexAdmin';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './i18.js'

function App() {
  const isAdmin = useSelector((state)=>state.isAuth.user?.isAdmin)
  let theme = useSelector((state)=>state.theme.color)
  
  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
  });

  return (
        <Router>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
              {isAdmin ?
                <IndexAdmin/> : <Index/>
              }
          </ThemeProvider>
       </Router>)
}

export default App;
