import OrderStepper from "../../Components/orderStepper";
import Card from "@mui/material/Card";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SubmitedOrderProductItem from "../../Components/submitedOrderProductItem";
import { getOrderById, payThawani } from "../../Network/appApis";
import { useSelector } from "react-redux";
import NotAuth from "../../Components/notAuthenticated";
import { Toast } from "../../helpers/sweetAlert";
import { useTranslation } from "react-i18next";


function OrderDetails() {
  
  const Navigate = useNavigate()
  const Location = useLocation()
  const [order,setOrder] = useState(Location?.state?.orderObj ? Location?.state?.orderObj : null) 
  let userId = useSelector((state)=>state.isAuth.user?._id)
  let isLogged = useSelector((state)=>state.isAuth.isLogged)
  const { t } = useTranslation(); // Hook to access translations
  let lang = useSelector((state)=>state.lang)

  let status_Paid = {
    fontWeight: "bold",
    borderRadius:'10px',
    backgroundColor: '#b3cf99',
    padding:'5px'
  }

  let status_Pending = {
    fontWeight: 'bold',
    borderRadius:'10px',
    backgroundColor: '#f1ee8e',
    padding:'5px'
  }

  // payThawani()
  useEffect(()=>{
    if(Location?.state?.orderById){
      (async function(){
        try{
          let response = await getOrderById(Location?.state?.orderById,userId)
          setOrder(response.data.data)
        }catch(err){
          console.log(err)
        }
      })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  let handlePayThawani = async () => {
    try{
      let response = await payThawani(order._id, lang)
      if(response.status === 201){
        const sessionId = response.data?.data?.data?.session_id
        if(sessionId){
            window.location.replace(`${process.env.REACT_APP_THAWANI_URL}/${sessionId}?key=${process.env.REACT_APP_THAWANI_PUBLISHABLE_KEY}`);
        }else{
            Toast('warning',`Session didn't Created Successfully, Try Agian!`)
        }
            Toast('success',`Session Successfully Created!`)
        }
    }catch(err){
      Toast('error',`Something Went Wrong Please Try Again Later!`)
    }
  }

  return (
    <>
    {isLogged 
            ?
      order &&
      <section className="my-4">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-10 col-lg-8">
              <Card>
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                      <div className="py-1">
                          <p className="mb-2 fw-bold">{t("ConfirmOrder.OrderID")}: #{order.id}</p>
                          <p className="mb-2 fw-bold">{t("ConfirmOrder.PlacedOn")}: {new Date(order.createdAt).toDateString()}</p>
                          <p className="mb-2 fw-bold">{t("ConfirmOrder.ProductsPrice")}: {order.productsPrice} {t("ConfirmOrder.OMR")}</p>
                          <p className="mb-2 fw-bold">{t("ConfirmOrder.ShippingPrice")}: {order.shippingPrice} {t("ConfirmOrder.OMR")}</p>
                          <p className="mb-2 fw-bold">{t("ConfirmOrder.TotalPrice")}: {order.totalPrice} {t("ConfirmOrder.OMR")}</p>
                          <p>{t("ConfirmOrder.PaymentMethod")} : {order.paymentMethod === 'VISA CARD' ? t("PaymentMethods.Visa"): t("PaymentMethods.Cash")}</p>
                          <p>{t("ConfirmOrder.PaymentStatus")} : 
                            <span style={order.paymentStatus === 'Paid' ? status_Paid : status_Pending}>
                              {order.paymentStatus === "Paid" ? t("PaymentStatus.Paid"): t("PaymentStatus.Pending")}
                            </span>
                          </p>
                        </div>
                      <div>
                          { order.status !== 'Rejected' && order.paymentStatus === 'Pending' && order.paymentMethod === 'VISA CARD' ?
                            <button onClick={handlePayThawani} className='btn btn-warning'>{t("ConfirmOrder.PayNow")}</button>
                            : ''
                          }
                      </div>
                  </div>
                  <OrderStepper activeStep={
                    order.status === 'Placed' ? 0 :
                    order.status === 'Rejected' ? 1 :
                    order.status === 'Accepted' ? 1 :
                    order.status === 'Shipped' ? 2 :
                    order.status === 'Delivered' ? 3 : 0
                  }
                  orderStatus = {order.status} />
                  <hr />
                  <div className="d-flex flex-wrap align-items-center px-3 py-1">
                    {
                      order.cartProducts.map((item)=>{
                        return(
                          <>
                          <SubmitedOrderProductItem key={item._id?._id} item={item} status={order.status}/>
                          </>
                        )
                      })
                    }
                  </div>
                  <div className="my-2">
                        <div>
                          {order?.address?.location}, {order?.address?.city}, {order?.address?.country}
                        </div>
                        <div>
                        {t("AddressForm.Building")}: {order?.address?.building}, {t("AddressForm.Floor")}:{order?.address?.floor}, {t("AddressForm.Apartment")}:
                          {order?.address?.apartment}
                        </div>
                        <div>{t("AddressForm.PhoneNumber")}: {order?.address?.phoneNumber}</div>
                        <div>{t("AddressForm.AdditionalPhoneNumber")}: {order?.address?.secondaryPhoneNumber}</div>
                        <div>{t("AddressForm.FullAddress")}: {order?.address?.address}</div>
                      </div>
                      <hr className="mx-4" />
  
              <div className="d-flex justify-content-center">
                  <button onClick={()=>Navigate('/profile',{state:{id:2}})} className='my-1 btn btn-dark'>
                    <div className='d-flex align-items-center'>
                      <ArrowCircleLeftIcon /> <span className='mx-1'>{t("Products.Return")}</span>
                    </div>
                </button>
              </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
      :
      <NotAuth/>
    }
    </>
  );
}

export default OrderDetails;
