import React from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Formik } from "formik";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { addshippingPrice, updateShippingPrice } from "../../Network/appApis";
import { Toast } from "../../helpers/sweetAlert";
import Swal from "sweetalert2";

function ShippingPriceForm({ state, functionId, btnName, parentCallback, addShippingObj }) {
 
  return (
    <>
      <div className="d-flex justify-content-center mb-3">
      <div className="category-container text-center">
        {functionId === "ADDSHOPPINGPRICE" ? (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>New Shopping Price</h1>
        ) : (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>
            Edit Shopping Price
          </h1>
        )}
      </div>
      </div>
      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};
          if (!values.shippingPrice) {
            errors.shippingPrice = "Shipping Price is Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (functionId === "ADDSHOPPINGPRICE") {
            (async ()=>{
              try {
                const res = await addshippingPrice(values)
                if (res.status === 201) {
                  parentCallback()
                  addShippingObj(res.data.data) 
                  Toast(
                    "success",`Shopping Info Successfully Added`
                  );
                }
              } catch (err) {
                Toast("error", `${err.response.data.Error}`);
              }                  
            })()

          } else if (functionId === "EDITSHOPPINGPRICE") {
                parentCallback()            
                Swal.mixin({
                  toast: true,
                    customClass: {
                    confirmButton: 'btn btn-success m-2',
                    cancelButton: 'btn btn-outline-dark m-2'
                  },
                  buttonsStyling: false
                }).fire({
                  title: 'Update Shipping Info',
                  text: `Are you sure you want to Update Shipping Info?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Update!',
                  cancelButtonText: 'No, cancel!',
                }).then((result) => {
                  if (result.isConfirmed) {
           
                    (async ()=>{
                      try {
                        const res = await updateShippingPrice(values)
                        if (res.status === 201) {
                          addShippingObj(res.data.data) 
                          Toast(
                            "success",`Your Shopping Info Successfully Edited`
                          );
                        }
                      } catch (err) {
                        Toast("error", `Something Went Wrong`);
                      }                  
                    })()
                  } else if (
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Toast('error','Cancelled!')
                  }
                })
               }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center flex-column"
            >
              <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <MonetizationOnIcon className="m-1" />
                  <input
                    type="number"
                    name="shippingPrice"
                    className="form-control"
                    placeholder="Enter Shipping Price"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.shippingPrice}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.shippingPrice && touched.shippingPrice && errors.shippingPrice}
                </span>
              </div>
              
                <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <DriveFileRenameOutlineIcon className="m-1" />
                  <textarea
                    rows='2'
                    type="text"
                    name="shippingOffer.en"
                    className="form-control"
                    placeholder="English Shipping Offer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.shippingOffer.en}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                </span>

                <div className="d-flex align-items-center mb-1">
                  <DriveFileRenameOutlineIcon className="m-1" />
                  <textarea
                    rows='2'
                    type="text"
                    name="shippingOffer.ar"
                    className="form-control"
                    placeholder="Arabic Shipping Offer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.shippingOffer.ar}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                </span>

                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    className="btn btn-danger px-2 m-2"
                    onClick={()=>{parentCallback()}}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    {btnName}
                  </button>
                </div>
              </div>
            </form>
           </div>
        )}
      </Formik>
    </>
  );
}

export default ShippingPriceForm;
