import OrderCard from "../../Components/orderCard";
import AddressCard from "../../Components/addressCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation } from "react-router-dom";
import { SSPaginationUserOrders } from "../../Network/appApis";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import NotAuth from "../../Components/notAuthenticated";
import ChangePasswordForm from "../../Components/updatePasswordForm";
import ChangeUserForm from "../../Components/updateUserForm";
import { Pagination } from "@mui/material";
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const location = useLocation();
  const pageId = location.state?.id;
  const isLogged = useSelector((state) => state.isAuth.isLogged);
  const user = useSelector((state) => state.isAuth.user);

  const [value, setValue] = useState(pageId ? pageId : 0);
  const [userOrders, setUserOrders] = useState([]);
  const { t } = useTranslation(); // Hook to access translations

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let handlePagenation = (e,p) =>{
    setPage(p);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let email = useSelector((state) => state.isAuth.user?.email);
  let firstName = useSelector((state) => state.isAuth.user?.firstName);
  let lastName = useSelector((state) => state.isAuth.user?.lastName);
  let id = useSelector((state) => state.isAuth.user?._id);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    isLogged &&
      (async function () {
        try {
          let response = await SSPaginationUserOrders(user._id, page);
          setUserOrders(response.data.data);
          setPagesCount(response.data.pagesCount)
        } catch (err) {
          console.log(err);
        }
      })();
  }, [isLogged, page, user._id]);

  return (
    <>
      {isLogged ? (
        <div className="container my-3">
          <div className="row">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="secondary"
                  indicatorColor="secondary"
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                  centered
                >
                  <Tab
                    icon={<PersonPinIcon />}
                    label={t("Profile.Profile")}
                    {...a11yProps(0)}
                  />
                  <Tab icon={<HomeIcon />} label={t("Profile.Address")} {...a11yProps(1)} />
                  <Tab
                    icon={<ShoppingBagIcon />}
                    label={t("Profile.Orders")}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4">{t("Profile.YrProfile")}</h4>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      className="text-secondary text-decoration-none"
                      onClick={() => {
                        setOpenUserModal(true)
                        setAnchorEl(null)
                      }}
                    >
                      <EditIcon className="mx-2" />
                      {t("Profile.EditProfile")}
                    </MenuItem>
                    <MenuItem
                      className="text-secondary text-decoration-none"
                      onClick={() => {
                        setOpenPasswordModal(true)
                        setAnchorEl(null)
                      }}
                    >
                      <KeyIcon className="mx-2" />
                      {t("Profile.ChangePassword")}
                    </MenuItem>
                  </Menu>
                </div>

                <div className="mx-5">
                  <h4>
                  {t("Profile.Welcome")} {user.firstName} {user.lastName}
                  </h4>
                  <h6>{t("Profile.SignedAs")} {user.email}</h6>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-4">{t("Profile.YrAddress")}</h4>
                </div>
                <div className="mx-5">
                  {user?.address ? (
                    <AddressCard address={user?.address} />
                  ) : (
                    <div className="text-center h-25">
                      {t("Profile.NoAddress")} <br /> {t("Profile.NoAddressMessage")}
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <h4 className="mb-4">{t("Profile.YrOrders")}</h4>
                <div className="mx-5">
                  {userOrders.length ? (
                    userOrders.map((order) => {
                      return <OrderCard key={order._id} order={order} />;
                    })
                  ) : (
                    <div className="mx-5 text-center">
                      {t("Profile.NoOrders")}
                      <br />
                      {t("Profile.NoOrdersMessage")}
                    </div>
                  )}
                      <div className="my-3 w-100 d-flex justify-content-center">
                        <Pagination count={pagesCount} onChange={handlePagenation}/>
                      </div>
                </div>
              </TabPanel>
            </Box>
          </div>
        </div>
      ) : (
        <NotAuth path="/profile" />
      )}


      {/* MODALS */}
      <Modal
        open={openPasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="p-1">
            <ChangePasswordForm
              state={{
                email,
                password: "",
                newPassword: "",
                confirmNewPassword: "",
              }}
              parentCallback={() => setOpenPasswordModal(false)}
            />
          </div>
        </Box>
      </Modal>
      
      <Modal
        open={openUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="p-1">
            <ChangeUserForm
              state={{
                id,
                firstName,
                lastName,
                email,
                password:""
              }}
              parentCallback={() => setOpenUserModal(false)}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Profile;
