export const SET_ADD_SHOPPING_CART = 'SET_ADD_SHOPPING_CART'
export const SET_EDIT_SHOPPING_CART = 'SET_EDIT_SHOPPING_CART'
export const SET_INCREMENT_SHOPPING_CART = 'SET_INCREMENT_SHOPPING_CART'
export const SET_REMOVE_SHOPPING_CART = 'SET_REMOVE_SHOPPING_CART'
export const SET_RESET_SHOPPING_CART = 'SET_RESET_SHOPPING_CART'

export const SET_LOGIN_IS_AUTH = 'SET_LOGIN_IS_AUTH'
export const SET_LOGOUT_IS_AUTH = 'SET_LOGOUT_IS_AUTH'

export const SET_ADD_ADDRESS = 'SET_ADD_ADDRESS'
export const SET_EDIT_ADDRESS = 'SET_EDIT_ADDRESS'
export const SET_DELETE_ADDRESS = 'SET_DELETE_ADDRESS'
export const SET_EDIT_PROFILE = 'SET_EDIT_PROFILE'

export const SET_THEME = 'SET_THEME'

export const CHANGE_LANG = 'CHANGE_LANG'
