import ProductForm from "../../Components/productForm";

function NewProduct() {

  const values ={
    name: {
      en: "",
      ar: "",
    },
    category:'',
    image:null,
    price:'',
    stock:'',
    status:"Active",
    description:{
      ar:'',
      en:''
    },
    product_tags:[]
  }

  return (
    <>
      <ProductForm state={values} functionId='ADDPRODUCT' btnName="Add New Product" />
    </>
  );
}

export default NewProduct;
