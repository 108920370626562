import { Facebook, Instagram, MailOutline, Phone, Room, Twitter, WhatsApp} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAllCategories, getFooterInfo } from "../Network/appApis";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [allCategories,setAllCategories] = useState([])
  const [footerData,setFooterData] = useState(null)
  const location = useLocation();
  const initialState = location.state;
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllCategories()
        setAllCategories(res.data.data)

        const footer = await getFooterInfo()
        setFooterData(footer.data.data)
      }catch(err){}
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <div
      className="text-light d-flex flex-wrap"
      style={{ backgroundColor: "black" }}
    >
      {/* Left Section */}
      <div className="col-12 col-md-4 flex-column p-2 d-none d-sm-flex">
        <h5 className="my-2">{t("Footer.OurCompany")}</h5>
        {
          footerData?.aboutUs && footerData?.aboutUs.length ? 
              <>
              <p
                style={{
                  fontSize: "12px",
                  padding: "10px 0px 10px 0px",
                  lineHeight: "20px",
                }}
              >
                {footerData?.aboutUs?.[0]?.description?.[lang]}
              </p></>
          :
          <p>{t("Home.CommingSoon")}</p>
        }
      </div>

      {/* Middle Section */}
      <div className="col-12 col-md-4 p-2">
        <h5 className="my-2">{t("Navbar.Categories")}</h5>
        <ul style={{ listStyle: "none" }} className="d-flex flex-wrap">
          {allCategories.length? 
          allCategories.map((item) => {
            return (
              <Link
                to="/productList"
                state={{ ...initialState, category: item._id }}
                key={item._id}
                onClick={window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
                style={{ fontSize: "12px",color:"white",textDecoration:'none', padding: "10px 0px 10px 0px" }}
                className="col-6 col-lg-4"
              >
                {item.name?.[lang]}
              </Link>
            );
          }):<></>}
        </ul>
      </div>

      {/* Right Section */}
      <div className="col-12 col-md-4 p-2">
        <h5 className="my-2">{t("Footer.Contact")}</h5>
        <div className="footer-contact-container d-flex align-items-center">
          <Room className="footer-contact-icons" /> {`${footerData?.contact?.location || ''}, ${footerData?.contact?.city || ''}, ${footerData?.contact?.country || ''}`}
        </div>
        <div className="footer-contact-container d-flex align-items-center">
          <Phone className="footer-contact-icons" /> {`${footerData?.contact?.phoneNumber || ''}`}
        </div>
        <div className="footer-contact-container d-flex align-items-center">
          <MailOutline className="footer-contact-icons" /> {`${footerData?.contact?.email || ''}`}
        </div>
        <div className="footer-contact-container d-flex">
          {footerData?.socialMediaLinkes?.facebook &&
          <div className="footer-social">
            <a style={{color:'white'}} href={footerData?.socialMediaLinkes?.facebook}>
              <Facebook/>
            </a>
          </div>
          }
          {footerData?.socialMediaLinkes?.instagram &&
          <div className="footer-social">
            <a style={{color:'white'}} href={footerData?.socialMediaLinkes?.instagram}>
              <Instagram/>
            </a>
          </div>
          }
          {footerData?.socialMediaLinkes?.twitter &&
          <div className="footer-social">
            <a style={{color:'white'}} href={footerData?.socialMediaLinkes?.twitter}>
              <Twitter/>
            </a>
          </div>
          }
          {footerData?.socialMediaLinkes?.whatsapp &&
          <div className="footer-social">
            <a style={{color:'white'}} href={footerData?.socialMediaLinkes?.whatsapp}>
              <WhatsApp/>
            </a>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Footer;
