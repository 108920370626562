import DataTable from "../../Components/dataTable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteUser, getAllUsers } from "../../../Network/appApis";
import Swal from "sweetalert2";
import { Toast } from "../../../helpers/sweetAlert";

function Users() {
  const[allUsers, setAllUsers] = useState([])
  
  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllUsers()
        setAllUsers(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])  


  const userColumns = [
    { field: "_id", headerName: "ID", width: 220 },
    {
      field: "firstName",
      headerName: "First Name",
      width: 110,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 110,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      renderCell: (params) => {
        return (new Date(params.row.createdAt).toDateString());
    }
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-around align-items-center">
            <Link to={`/users/${params.row._id}`}>
            <VisibilityIcon
              style={{ color: "blue", cursor: "pointer" }}
            />
            </Link>
            <span className="mx-2">|</span>
            <DeleteIcon
            onClick={()=>{deleteItem(params.row)}}
            style={{ color: "red", cursor: "pointer" }}
            />
          </div>
        );
      },
    },
  ];

  function deleteItem(row){
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Delete User',
      text: `Are you sure you want to Delete ${row.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        (async ()=>{
          try {
            const res = await deleteUser(row._id)
            if (res.status === 200) {
              setAllUsers(allUsers.filter((item)=>{
                return item._id !== row._id
              }))
              Toast(
                "success",`User Successfully Deleted ${row.name}`
              );
            }
          } catch (err) {
            Toast("error", `${err.response.data.Error}`);
          }                  
        })()
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
    
  }

  
  return (
    <>
      <div className="user-container d-flex justify-content-between align-items-center">
        <h1 style={{ color: "lightgray", fontSize: "30px" }}>All Users</h1>
          <Link to='newuser'>
            <button className='btn btn-outline-secondary'>
              Add New User
            </button>
          </Link> 
      </div>
      <DataTable rows={allUsers} columns={userColumns} />
    </>
  );
}

export default Users;
