import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from '../../Components/dataTable';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { deleteProduct, getAllProducts } from '../../../Network/appApis';
import Swal from 'sweetalert2';
import { Toast } from '../../../helpers/sweetAlert';
import NoImage from "../../../images/noImage.jpg";

function Products() {

  const[allProducts, setAllProducts] = useState([])
  
  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllProducts('','date','',0)
        setAllProducts(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])  

    const productCoulmns = [
        { field: "_id", headerName: "ID", width: 210 },
        {
          field: "image",
          headerName: "Image",
          width: 75,
          renderCell: (params) => {
            return (
                <img
                  width="30px"
                  height="30px"
                  className="rounded-circle m-2"
                  src={params.row.image
                     ? params.row.image
                     : NoImage}
                  alt={params.row.name}
                />
            );
          },
        },
        {
          field: "name",
          headerName: "Product",
          width: 120,
          valueGetter: (params) => `${params.row?.name?.['en']}`
        },
        {
          field: "status",
          headerName: "Status",
          width: 90,
        },
        {
          field: "price",
          headerName: "Price",
          width: 70,
        },
        {
          field: "stock",
          headerName: "Stock",
          width: 70,
        },{
          field: "category",
          headerName: "category",
          width: 120,
            valueGetter: (params) => `${params.row.category?.name?.['en']}`
        },
        {
          field: "createdAt",
          headerName: "Created At",
          width: 140,
          renderCell: (params) => {
            return (new Date(params.row.createdAt).toDateString());
        }
        },
        {
          field: "Actions",
          headerName: "Actions",
          width: 100,
          renderCell: (params) => {
            return (
              <div className="d-flex justify-content-around align-items-center">
                <Link to={`/products/${params.row._id}`}>
                  <VisibilityIcon
                    style={{ color: "blue", cursor: "pointer" }}
                  />
                  </Link>
                  <span className="mx-2">|</span>
                  <DeleteIcon
                    onClick={() => deleteItem(params.row)}
                    style={{ color: "red", cursor: "pointer" }}
                  />
              </div>
            );
          },
        },
      ];
    
      function deleteItem(row){
        Swal.mixin({
          toast: true,
            customClass: {
            confirmButton: 'btn btn-danger m-2',
            cancelButton: 'btn btn-outline-dark m-2'
          },
          buttonsStyling: false
        }).fire({
          title: 'Delete Product',
          text: `Are you sure you want to Delete ${row.name}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete!',
          cancelButtonText: 'No, cancel!',
        }).then((result) => {
          if (result.isConfirmed) {
            (async ()=>{
              try {
                const res = await deleteProduct(row._id)
                if (res.status === 200) {
                  setAllProducts(allProducts.filter((item)=>{
                    return item._id !== row._id
                  }))
                  Toast(
                    "success",`Product Successfully Deleted ${row.name}`
                  );
                }
              } catch (err) {
                Toast("error", `${err.response.data.Error}`);
              }                  
            })()
            
          } else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Toast('error','Cancelled!')
          }
        })
        
      }
      
    return (    
    <>
      <div className="user-container d-flex justify-content-between align-items-center">
        <h1 style={{ color: "lightgray", fontSize: "30px" }}>All Products</h1>
          <Link to='newproduct'>
            <button className='btn btn-outline-secondary'>
              Add New Product
            </button>
          </Link> 
      </div>
      <DataTable rows={allProducts} columns={productCoulmns} />
    </> 
    );
}

export default Products;
