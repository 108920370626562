import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getHomeSliderImages } from '../Network/appApis';
import { useTranslation } from "react-i18next";

// getHomeSliderImages
function Slider() {

  const [images, setImages] = useState([])
  const { t } = useTranslation(); // Hook to access translations

  useEffect(()=>{
    (async function(){
      try{
        const res = await getHomeSliderImages()
        setImages(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div>
      {images.length ? 
        <Carousel>
           {images.length && 
              images.map((item)=>{
                return(
                  <Carousel.Item style={{height:'500px'}} key={Math.random()*100000000}>
                    <img
                      className="d-block w-100 h-100"
                      src={item}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h2>{t("Slider.MedicalEq")}</h2>
                      <p>{t("Slider.MedicalEqNeed")}<br/> {t("Slider.BestPrice")}</p>
                      <Link className='btn btn-outline-dark btn-light' to='/productList'>{t("Slider.ShopNow")}</Link>
                    </Carousel.Caption>
                  </Carousel.Item>
                )
              })
              }
        </Carousel>
    : '' }
    </div>
  )
}

export default Slider;