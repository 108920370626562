// import { configureStore } from '@reduxjs/toolkit'
// import combineReducers from "./Reducers/combineReducer";

// export const store = configureStore({reducer:combineReducers});

import { configureStore } from "@reduxjs/toolkit";
import { persistedReducer } from "./Reducers/combineReducer";
import {
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist";
  
export const store = configureStore({
    reducer: persistedReducer,
    // reducer:{
    //     persistedReducer,
    //     categories: Any Reducer Not Cashed
    // },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  
export let persistor = persistStore(store);
