import "../styles/userForm.css";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { Key } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Person3Icon from "@mui/icons-material/Person3";
import { Formik } from "formik";
import { addUser, updateUser } from "../../Network/appApis";
import { Toast } from "../../helpers/sweetAlert";
import { useNavigate } from "react-router-dom";


function UserForm({ state, functionId, btnName }) {
  
  const Navigate = useNavigate();
  
  return (
    <>
      <div className="user-container">
        {functionId === "ADDUSER" ? (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>New User</h1>
        ) : (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>Edit User</h1>
        )}
      </div>
          <Formik
            initialValues={state}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid Email Address";
              }
              if (!values.firstName) {
                errors.firstName = "First Name is Required";
              }
              if (!values.lastName) {
                errors.lastName = "Last Name is Required";
              }
              if (!values.isAdmin) {
                errors.isAdmin = "Role is Required";
              }
              if (functionId === "ADDUSER" && !values.password) {
                errors.password = "Password is Required";
              } else if (functionId === "ADDUSER" && values.password.length < 8) {
                errors.password = "Minimum 8 Characters Required";
              }
              if (functionId === "ADDUSER" && !values.confirmPassword) {
                errors.confirmPassword = "Password Confirmation is Required";
              } else if (functionId === "ADDUSER" && values.confirmPassword !== values.password) {
                errors.confirmPassword = "Password Not Identical";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (functionId === "ADDUSER") {
                
                (async ()=>{
                  try {
                    const res = await addUser(values)
                    if (res.status === 200) 
                    {
                      Toast("success",`User Successfully Added`);
                      Navigate(-1)
                    }
                  } catch (err) {
                    Toast("error", `${err.response.data.Error}`);
                  }                  
                })()
                    
              } else if (functionId === "EDITUSER") {
                (async ()=>{
                  try {
                    const res = await updateUser(values)
                    if (res.status === 201) 
                    {
                      Toast("success",`User Successfully Updated`);
                      Navigate(-1)
                    }
                  } catch (err) {
                    Toast("error", `${err.response.data.Error}`);
                  }                  
                })()
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <div className="user-container">
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-wrap justify-content-around"
                >

                  <div className="col-12 col-lg-8 my-2 d-flex flex-wrap justify-content-around form-user">
                    <div className="col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <PersonIcon className="m-1" />
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.firstName &&
                          touched.firstName &&
                          errors.firstName}
                      </span>
                    </div>

                    <div className=" col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <Person3Icon className="m-1" />
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.lastName && touched.lastName && errors.lastName}
                      </span>
                    </div>

                    <div className=" col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <EmailIcon className="m-1" />
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>

                    <div className=" col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <QuestionMarkIcon className="m-1" />
                        <select
                          name="isAdmin"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.isAdmin}
                        >
                          <option value="" disabled>
                            Choose Role
                          </option>
                          <option value={true} >Admin</option>
                          <option value={false}>User</option>
                        </select>
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.isAdmin && touched.isAdmin && errors.isAdmin}
                      </span>
                    </div>
                    
                    {functionId === "ADDUSER" 
                    ? <><div className=" col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <LockIcon className="m-1" />
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.password && touched.password && errors.password}
                      </span>
                    </div>

                    <div className=" col-12 col-sm-5">
                      <div className="d-flex align-items-center mb-1">
                        <Key className="m-1" />
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm your password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />
                      </div>
                      <span
                        style={{ color: "red", marginLeft: "30px" }}
                        className="p-1 text-bold"
                      >
                        {errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword}
                      </span>
                    </div>
                    </> : <></>}
                    <div className="d-flex">
                      <button
                        type="button"
                        onClick={()=>Navigate(-1)}
                        className="btn btn-danger px-2 m-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-success px-2 m-2"
                      >
                        {btnName}
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            )}
          </Formik>
    </>
  );
}

export default UserForm;
