import { useEffect } from "react"
import { useState } from "react"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { getShippingPrice } from "../../Network/appApis"
import ShippingPriceForm from "../Components/shippingPriceForm";
import ChangePasswordForm from "../Components/updatePasswordForm";
import { useSelector } from "react-redux";
import ChangeUserForm from '../../Components/updateUserForm'

function Setting() {

    const [openShippingModal, setOpenShippingModal] = useState(false);  
    const [openPasswordModal, setOpenPasswordModal] = useState(false);  
    const [openProfileModal, setOpenProfileModal] = useState(false);  
    const [shippingPrice, setShippingPrice] = useState([])
    let email = useSelector((state)=>state.isAuth.user?.email)
    let firstName = useSelector((state) => state.isAuth.user?.firstName);
    let lastName = useSelector((state) => state.isAuth.user?.lastName);
    let id = useSelector((state) => state.isAuth.user?._id);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      
    useEffect(()=>{
      (async function(){
        try{
          const res = await getShippingPrice()
          setShippingPrice(res.data.data)
        }catch(err){
          console.log(err)
        }
      })()
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[])  
  
   const addShippingObj = (obj) =>{
    setShippingPrice([obj])
   }

  return (
    <>
      <div className="col-12">
        <div className="user-container d-flex justify-content-between align-items-center">
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>Account Settings</h1> 
        </div>
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
          <div 
            className="w-100 container d-flex align-items-center justify-content-between"
            style={{background:"#EEE",borderRadius:'10px',padding:'10px'}}
          >
                <div>
                    Shipping Info
                </div>
                <div>
                    {shippingPrice.length
                    ?
                    <button onClick={() => setOpenShippingModal(true)} className="btn btn-dark">   
                        Edit Shipping Info
                    </button>
                    :
                    <button onClick={() => setOpenShippingModal(true)} className="btn btn-dark">   
                        Add Shipping Info
                    </button>
                    }
                </div>
                <Modal
                open={openShippingModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                    <div className='p-1'>
                    {shippingPrice.length
                    ?
                    <ShippingPriceForm 
                        state={{
                            id:shippingPrice[0]._id,
                            shippingPrice: shippingPrice[0].shippingPrice,
                            shippingOffer: shippingPrice[0].shippingOffer
                        }}
                        functionId='EDITSHOPPINGPRICE'
                        parentCallback={()=>setOpenShippingModal(false)}
                        addShippingObj= {addShippingObj}
                        btnName="Edit Shipping Price"/>
                        :
                        <ShippingPriceForm 
                            state={{
                                shippingPrice:"",
                                shippingOffer:{
                                  en:'',
                                  ar:''
                                },
                              }}
                            functionId='ADDSHOPPINGPRICE'
                            addShippingObj= {addShippingObj}
                            parentCallback={()=>setOpenShippingModal(false)}
                            btnName="Add Shipping Price"/>
                    }
                    </div>       
                </Box>
              </Modal>
            </div>

            <div 
            className="w-100 container d-flex align-items-center justify-content-between my-3"
            style={{background:"#EEE",borderRadius:'10px',padding:'10px'}}
          >
                <div>
                   Your Profile
                </div>
                <div>
                    <button onClick={() => setOpenProfileModal(true)} className="btn btn-dark">   
                        Update Profile 
                    </button>
                    
                </div>
                <Modal
                open={openProfileModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                    <div className='p-1'>
                    <ChangeUserForm 
                            state={{
                              id,
                              firstName,
                              lastName,
                              email,
                              password:""
                              }}
                            parentCallback={()=>setOpenProfileModal(false)}/>
                    </div>       
                </Box>
              </Modal>
      </div>


          <div 
            className="w-100 container d-flex align-items-center justify-content-between my-3"
            style={{background:"#EEE",borderRadius:'10px',padding:'10px'}}
          >
                <div>
                    Account Password
                </div>
                <div>
                    <button onClick={() => setOpenPasswordModal(true)} className="btn btn-dark">   
                        Update Password 
                    </button>
                    
                </div>
                <Modal
                open={openPasswordModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                    <div className='p-1'>
                    <ChangePasswordForm 
                            state={{
                                email,
                                password:"",
                                newPassword:"",
                                confirmNewPassword:"",
                              }}
                            parentCallback={()=>setOpenPasswordModal(false)}/>
                    </div>       
                </Box>
              </Modal>
      </div>
        </div>
      </div>

    </>
  );
}

export default Setting;
