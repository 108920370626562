
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

function Loader() {
    return ( 
    <Box className='w-100 d-flex justify-content-center'>
    <CircularProgress color="secondary"/>
    </Box> );
}

export default Loader;