import { Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {addShoppingCartAction, incrementShoppingCartAction} from '../../store/Actions/shoppingCartAction'
import {Toast} from '../../helpers/sweetAlert'
import { getProductById } from "../../Network/appApis";
import NoImage from "../../images/noImage.jpg";
import { useTranslation } from "react-i18next";

const Amount = {
  width: "30px",
  height: "30px",
  borderRadius: "10px",
  border: "1px solid teal",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0px 5px",
};

function ProductDetails() {
  let [product, setProduct] = useState({});
  let [uiQuantity, setUiQuantity] = useState(1);
  const Navigate = useNavigate()
  let Params = useParams();
  const dispatch = useDispatch();
  const shoppingCart = useSelector((state)=>state.shoppingCart.products)
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  useEffect(() => {
    if(shoppingCart.length){
      for(let i=0; i<shoppingCart.length; i++) {
        // eslint-disable-next-line eqeqeq
        if(shoppingCart[i]._id == Params.id ){
         return setProduct(shoppingCart[i])
        }}      
    }
      (async function(){
        try{
          const res = await getProductById(Params.id)
          setProduct(res.data.data[0])
        }catch(err){
          console.log(err)
        }
      })()    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[shoppingCart]);  

  const addToCart= ()=>{
     if(shoppingCart.length && (shoppingCart.map((ob) => ob._id).indexOf(product._id)) > -1){
      if(product.stock!==0){
        setUiQuantity(1)
        dispatch(incrementShoppingCartAction({...product,quantity:uiQuantity ,stock: product.stock-uiQuantity}))
        Toast('success',`${uiQuantity} item(s) of ${product.name?.[lang]} Added to your cart`)
      }
     }else{
       if(product.stock >= uiQuantity){
         setProduct({
          ...product,
          stock : product.stock-uiQuantity,
          quantity: uiQuantity
         })
          Toast('success',`${uiQuantity} item(s) of ${product.name?.[lang]} Added to your cart`)
          dispatch(addShoppingCartAction({...product,quantity: uiQuantity,stock : product.stock-uiQuantity}))
          setUiQuantity(1)
        }
      else if(product.stock < uiQuantity){
        Toast('warning',`${product.name?.[lang]} has been sold out`)
      }
     }
  }

  const addItem = (product) =>{
    if(uiQuantity < product.stock){
      setUiQuantity(uiQuantity + 1)
    }else{
      Toast('warning',`Only ${product.stock} item(s) are Available`)
    }
  }

  const removeItem = () =>{
    if(uiQuantity !== 1){
      setUiQuantity(uiQuantity - 1)
    }else{
      Toast('warning',`Minimum quantity is one`)
    }
  }

  return (
    <>
      <div className="container d-flex flex-wrap my-3">
        <div className="col-md-6 col-12 align-self-center">
          <img
            src={product.image
              ? product.image
              : NoImage}          
            style={{ height:'500px',objectFit: "cover",maxWidth:'100%' }}
            alt={product.name?.[lang]}
          />
        </div>
        <div className="col-md-6 col-12 px-5">
            <div className='d-flex flex-column'>
                <h2>{product.name?.[lang]}</h2>
                <span style={{fontSize:'12px'}}>{t("Products.Category")}: {product.category?.name?.[lang]}</span>
            </div>
          <p className="mt-4 mb-2">{product.description?.[lang]}</p>
          <h1>{product.price ? (product.price).toFixed(3): product.price} {t("ConfirmOrder.OMR")}</h1>
            {product.stock > 0 ? 
                <h6 style={{fontSize:'12px',color:'green',fontWeight:'bold'}}>{t("Products.InStock")}</h6>:
                <h6 style={{fontSize:'12px',color:'red',fontWeight:'bold'}}>{t("Products.OutStock")}</h6>
            }
          <div className="col-lg-6 col-9 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center text-bold">
              <Remove
                onClick={() => {removeItem()}}
                style={{ cursor: "pointer" }}
              />
              <span style={Amount}>{uiQuantity}</span>
              <Add
                onClick={() => addItem(product)}
                style={{ cursor: "pointer" }}
              />
            </div>
            {
            product.stock === 0? 
              <button disabled className="btn btn-dark">
                {t("Products.AddCart")}
              </button>:
              <button className="btn btn-dark" 
              onClick={addToCart}
              >
              {t("Products.AddCart")}
              </button>
            }
          </div>

            <button onClick={()=>{Navigate(-1)}} className="my-3 btn btn-outline-dark btn-light">
              <ArrowBackIcon/> {t("Products.Return")}
            </button>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
