import { useEffect, useState } from "react";
import { getNewArrivalProducts, getBestSellers } from "../Network/appApis";
import ProductCard from "./productCard";
import { useTranslation } from 'react-i18next';

const HomeProducts = () => {

  const [newArrivalProducts,setNewArrivalProducts] = useState([])
  const [bestSellersProducts,setBestSellersProducts] = useState([])
  const { t } = useTranslation(); // Hook to access translations
  
  useEffect(()=>{
    (async function(){
      try{
        const res = await getNewArrivalProducts()
        setNewArrivalProducts(res.data.data)
       }catch(err){
        console.log(err)
      }
    })()
 },[])  

 useEffect(()=>{
  (async function(){
    try{
      const res = await getBestSellers()
      setBestSellersProducts(res.data.data)
     }catch(err){
      console.log(err)
    }
  })()
},[])  

    return (
    <>
      <h3 style={{ margin: "30px 0px 0px 15px" }}>{t("Home.NewArrivals")}</h3>
      <div className="d-flex flex-wrap my-2 justify-content-center ">
        {newArrivalProducts.length?
        newArrivalProducts.map((item) => (
          <ProductCard item={item} key={item._id}/>
        )): ""}
      </div>

      {bestSellersProducts.length ? <h3 style={{ margin: "30px 0px 0px 15px" }}>{t("Home.BestSeller")}</h3>: ""}
      <div className="d-flex flex-wrap my-2 justify-content-center ">
      {bestSellersProducts.length?
        bestSellersProducts.map((item) => (
          <ProductCard item={item?.product?.[0]} key={item._id}/>
        )): ""}
      </div>
    </>
  );
};

export default HomeProducts;
