import { useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useSelector } from "react-redux";
import NotAuth from "../../Components/notAuthenticated";
import FailedImage from "../../images/failed.png";
import { useTranslation } from 'react-i18next';

function OrderRejected() {

    const Navigate = useNavigate()
    let isLogged = useSelector((state)=>state.isAuth.isLogged)
    const { t } = useTranslation(); // Hook to access translations

    return ( 
    <>
            {
            isLogged 
            ?
        <div className='d-flex flex-column my-5 justify-content-center align-items-center'>
            <img
                className='m-4' 
                height='200'
                width='200'
                src={FailedImage} alt='MK TRADING'/>
            <h2>{t("OrderAccepted.Failed")}</h2>
            <div>
                <button className="btn btn-dark m-2" onClick={()=>{Navigate('/')}}>
                     <ArrowCircleLeftIcon />
                     {t("OrderAccepted.Home")}
                </button>
                <button className="btn btn-dark m-2" onClick={()=>{Navigate('/productList')}}>
                {t("OrderAccepted.ContinueShopping")}
                </button>
            </div>
        </div>
        :
        <NotAuth path='/'/>
    }
    </> 
    );
}

export default OrderRejected;