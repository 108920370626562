import { useFormik } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import { updateHomeSliderImages, uploadMulti } from "../../../Network/appApis";
import { Toast } from "../../../helpers/sweetAlert";
import { useState } from "react";

function ImageSlider({list, parentCallback}) {

  const [oldImages, setOldImages] = useState(list);
  const [imgFiles, setImgFiles] = useState('');
  const [updateButton, setUpdateButton] = useState(false);
  
  const formik = useFormik({
    initialValues:{image:oldImages},
    enableReinitialize:true,
    onSubmit: (values) => {
      (async () => {
        try {
          let formData = new FormData();

          let imgUrl = []
          if(imgFiles.length){
            
            for(let item of imgFiles){
              formData.append("image", item);   
            }

            const imgRes = await uploadMulti(formData)
            if(imgRes.status === 200){
              imgUrl = imgRes.data.uploadedImagePath
            }  
          }  

          const res = await updateHomeSliderImages({image: [...imgUrl, ...oldImages]});
          // const res = await updateHomeSliderImages(values);
          if (res.status === 200) {
            Toast("success", `Images Updated`);
            setOldImages(res.data.data)
            setImgFiles({})
            setUpdateButton(false)
            parentCallback(res.data.data)
          }
        } catch (err) {
          console.log(err)
          Toast("error", `Something Went Wrong`);
        }
      })();
      },
  })

  const removeOldImage = (item) => {    
    setOldImages(oldImages.filter((img)=>{
      return item !== img
    }))
  }

  const removeFile = (item) => {    
    let imgFilesClone = {...imgFiles}
    for(let obj in imgFilesClone) {
      if(imgFilesClone[obj] === item) {
          delete imgFilesClone[obj];
      }
    }
    setImgFiles(imgFilesClone)
  }

  return (
    <>
      
  <div>
    <form onSubmit={formik.handleSubmit}>
     <div className="mx-5">
         <div className='d-flex w-100 justify-content-between m-2'>
            <h2>Uploaded Images</h2>
           {updateButton && <label htmlFor="imgFile" className='btn btn-outline-dark'>Add Image</label>}
         </div>
          <input
            multiple
            type="file"
            id="imgFile"
            style={{ display: "none" }}
            onChange={(event) => {
              formik.setFieldValue("image", event.currentTarget.files);
              setImgFiles(event.target.files);
            }}
          />


<div className="w-100 d-flex flex-wrap">          
         {Object.values(imgFiles).length ? Object.values(imgFiles).map((item)=>{
           return(
               <div key={Math.random() * 10000} className="col-lg-2 col-md-3 col-sm-3 col-6">
                 <div className='m-1' style={{width:'100px', position: "relative",textAlign: "center"}}>
                     <img
                       alt=''
                       src={URL.createObjectURL(item)} 
                       className='d-flex justify-content-end p-1'
                       style={{height:"75px", width:'100px'}}
                     />
                    <CloseIcon 
                    onClick={()=>{removeFile(item)}} 
                    fontSize='large' 
                    style={{cursor:"pointer", color:'red',position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                    />
                 </div>
               </div>
           )})
         : ""}
         </div>


       <div className="w-100 d-flex flex-wrap">          
         {oldImages.length ? oldImages.map((item)=>{
           return(
               <div key={Math.random() * 10000} className="col-md-4 col-sm-6 col-12">
                 <div className='m-2'>
                     <div 
                       className='d-flex justify-content-end p-1'
                       style={{
                         height:"200px",
                         backgroundSize:"cover",
                         backgroundImage: `url(${item})`,
                         }}>
                           {updateButton &&
                             <CloseIcon onClick={()=>{removeOldImage(item)}} fontSize='large' style={{cursor:"pointer", color:'red'}}/>
                           }
                     </div>
                 </div>
               </div>
           )}):
           <div className='d-flex justify-content-center w-100'>
             <h3>NO DATA FOUND</h3>
           </div>
         }
           </div>
                <div className="d-flex justify-content-around my-2">
                 <div className='col-11 col-sm-4 d-flex justify-content-around'>
                 {
                   updateButton ?
                   <>
                     <button
                        type="button" 
                        className='btn btn-danger' 
                        onClick={()=>{
                          formik.setFieldValue("image", "")
                          formik.handleReset()
                          setImgFiles([])
                          setUpdateButton(!updateButton)
                          setOldImages(list)
                        }}>Cancel</button>

                     <button className='btn btn-success' type="submit">Confirm</button>
                   </> 
                   :
                   <button type="button" className='btn btn-success' onClick={()=>{setUpdateButton(!updateButton)}}>Update</button>
                 }
               </div>
             </div>
            </div> 
            </form>
          </div>

    </>
  );
}

export default ImageSlider;