import { useSelector } from "react-redux";
import AddressForm from "../../Components/addressForm";
import NotAuth from "../../Components/notAuthenticated";
import { useTranslation } from 'react-i18next';

function AddAddress() {
  const isLogged = useSelector((state) => state.isAuth.isLogged);
  const user = useSelector((state) => state.isAuth.user?._id);
  const { t } = useTranslation(); // Hook to access translations

  const values = {
    user,
    country: "",
    city: "",
    location: "",
    building: "",
    floor: "",
    apartment: "",
    phone: "",
    secondPhone: "",
    address: "",
  };

  return (
    <>
    {
      isLogged?
      <AddressForm
        state={values}
        title={t("AddressForm.AddAddress")}
        btnName={t("AddressForm.SaveContinue")}
        functionId="ADDADDRESS"
      />
      :
      <NotAuth />
    }
    </>
  );
}

export default AddAddress;
