import { useEffect, useState } from "react";
import { adminCards, lastSixMonthsOrders } from "../../Network/appApis";
import Chart from "../Components/chart";
import CircularChart from "../Components/circularChart";
import Widget from "../Components/widget";

function Dashboard() {

  const [lastSixMonthsData, setLastSixMonthsData] = useState([])
  const [admnCards, setAdminCards] = useState({})
    
    useEffect(()=>{
        (async function(){
          try{
            const res = await lastSixMonthsOrders()
            setLastSixMonthsData(res.data.data)
             }catch(err){
            console.log(err)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
        (async function(){
          try{
            const res = await adminCards()
            setAdminCards(res.data.data)
             }catch(err){
            console.log(err)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
            
    return ( <>
    <div className="d-flex justify-content-center flex-wrap">
        <Widget type='user' count={admnCards?.userCount}/>
        <Widget type='product' count={admnCards?.productCount}/>
        <Widget type='order' count={admnCards?.orderCount}/>
        <Widget type='delivered' count={admnCards?.deliveredCount}/>
    </div>
    <div className="d-flex justify-content-center flex-wrap">
        <CircularChart/>
        <Chart data={lastSixMonthsData} title='Last Six Months Placed Orders'/>
    </div>
    </> );
}

export default Dashboard;