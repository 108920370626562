import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
// import { Link } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { Toast } from "../helpers/sweetAlert";
import { deleteAddress } from "../Network/appApis";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddressAction } from "../store/Actions/isAuthAction";
import { useTranslation } from "react-i18next";

function AddressCard({ address }) {
  const userId = useSelector((state) => state.isAuth.user?._id);
  const dispatch = useDispatch();
  const { t } = useTranslation(); // Hook to access translations

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeAddress = (address) => {
    Swal.mixin({
      toast: true,
      customClass: {
        confirmButton: "btn btn-danger m-2",
        cancelButton: "btn btn-outline-dark m-2",
      },
      buttonsStyling: false,
    })
      .fire({
        title: "Remove Address?",
        text: `Are you sure you want to remove the following Address? ${address.country} ${address.city} ${address.location}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          (async function () {
            try {
              const res = await deleteAddress(address._id, userId);
              if (res.status === 200) {
                dispatch(deleteAddressAction());
                Toast("success", `Address has been removed`);
              }
            } catch (err) {
              Toast("error", `Something Went Wrong Please Try Again Later`);
              console.log(err);
            }
          })();
        } else {
          Toast("error", "Cancelled!");
        }
      });
    handleClose();
  };

  return (
    <>
      <div className="my-2">
        <div className="d-flex justify-content-between">
          <div className="fw-bold">{t("AddressForm.AddressId")}: #{address._id}</div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <Link
              className="text-secondary text-decoration-none"
              state={address}
              to="/editAddress"
            >
              <MenuItem onClick={handleClose}>
                <EditIcon className="mx-2" />
                Edit
              </MenuItem>
            </Link> */}
            <MenuItem
              className="text-secondary text-decoration-none"
              onClick={() => removeAddress(address)}
            >
              <DeleteIcon className="mx-2" />
              {t("Profile.Delete")}
            </MenuItem>
          </Menu>
        </div>
        <div className="my-2">
          <div>
            {address.location}, {address.city}, {address.country}
          </div>
          <div>
            {t("AddressForm.Building")}: {address.building}, {t("AddressForm.Floor")}:{address.floor}, {t("AddressForm.Apartment")}:
            {address.apartment}
          </div>
          <div>{t("AddressForm.PhoneNumber")}: {address.phoneNumber}</div>
          <div>{t("AddressForm.AdditionalPhoneNumber")}: {address.secondaryPhoneNumber}</div>
          <div>{t("AddressForm.FullAddress")}: {address.address}</div>
        </div>
        <hr className="mx-4" />
      </div>
    </>
  );
}

export default AddressCard;
