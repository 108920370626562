import { Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addNewRating, getUserRating } from "../Network/appApis";
import { Toast } from "../helpers/sweetAlert";
import NoImage from "../images/noImage.jpg";
import { useTranslation } from 'react-i18next';

function SubmitedOrderProductItem({item, status}) {

  const [rating , setRating] = useState(0);
  const userId = useSelector(state=>state.isAuth.user?._id);
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations
  
  useEffect(()=>{
  (async function(){
    try{
      const res = await getUserRating(userId)        
      if(res.status === 200){
        if(res.data.data !== null){
            res.data.data.products.forEach((ratedItems)=>{
            if(ratedItems.productId===item._id._id){
              setRating(ratedItems.rating)
          }
        })
        }}
    }catch(err){
      console.log(err)
    }
  })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const sendRating = (val) =>{
    
    (async function(){
    try{
        await addNewRating({
          userId,
          productId:item._id._id,
          rating:val
        })
        
        Toast('success',`Product rated ${val} Stars Successfully`)

      }catch(err){
        Toast('error','Something went wrong while submmiting your rate')
        console.log(err)
      }
    })()
  }

  return (
    <>  
      <div className="row col-12 text-center">
        <div className="col-sm-3 col-6 mb-4 mb-lg-0">
          <img 
              height='100px'
              width='100px'
              src={item._id.image
                ? item._id.image
                : NoImage}
              alt={item.name?.[lang]}
            />
        </div>

        <div className="col-sm-3 col-6 mb-4 mb-lg-0 d-flex justify-content-center flex-column">
          <h6>{item.name?.[lang]}</h6>
          {item._id.stock > 0 ? 
                <h6 style={{fontSize:'12px',color:'green',fontWeight:'bold'}}>{t("Products.InStock")}</h6>:
                <h6 style={{fontSize:'12px',color:'red',fontWeight:'bold'}}>{t("Products.OutStock")}</h6>
            }
        </div>

        <div className="col-sm-3 col-6 mb-4 mb-lg-0 d-flex align-items-center justify-content-center flex-column">
          <div className="d-flex align-items-center justify-content-center mb-4 text-bold">
          </div>
          <h6>{item.quantity} {item.quantity > 1 ? t("Cart.Items") : t("Cart.Item")}</h6>
          <h6 style={{color:'green'}}>{(item.price * item.quantity).toFixed(3)} {t("ConfirmOrder.OMR")}</h6>
        </div>
        <div className="col-sm-3 col-6 mb-4 mb-lg-0 d-flex align-items-center justify-content-center flex-column">
              
              {status === 'Delivered' ? 'Product Rating:' : t("ConfirmOrder.RatingMessage")}
              
              <Rating
                readOnly={status === 'Delivered' ? false : true}
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue == null ? 0: newValue)
                  sendRating(newValue == null ? 0: newValue)
                }}
            />
        </div>
        <hr className="my-4" />
        </div>
    </>
  );
}

export default SubmitedOrderProductItem;
