import "../styles/userDetails.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from "react";
import Chart from "../Components/chart";
import { bestSellersEarning, lastSixMonthsEarnings } from "../../Network/appApis";
import DataTable from "../Components/dataTable";
import { Link } from "react-router-dom";
import NoImage from "../../images/noImage.jpg";


function Earnings() {
  const [chartData,setChartData] = useState([])
  const [bestSellersEarningData, setBestSellersEarningData] = useState([])
  
  useEffect(()=>{
    (async function(){
      try{
        const res = await lastSixMonthsEarnings()
        setChartData(res.data.data)
         }catch(err){
        console.log(err)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    (async function(){
      try{
        const res = await bestSellersEarning()
          setBestSellersEarningData(res.data.data)
         }catch(err){
        console.log(err)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const ordersCoulmns = [
    { field: "_id", headerName: "ID", width: 210 },
    {
      field: "name",
      headerName: "name",
      width: 150,
      renderCell: (params) => {
        return (
          <span>{params.row.product?.[0]?.name?.['en']}</span>            
        );
      }
    },
    {
      field: "image",
      headerName: "Image",
      width: 75,
      renderCell: (params) => {
        return (
            <img
              width="30px"
              height="30px"
              className="rounded-circle m-2"
              src={params.row.product?.[0]?.image
                ? params.row.product?.[0]?.image
                : NoImage}
              alt={params.row.name?.['en']}
            />
        );
      }
    },
    {
      field: "price",
      headerName: "Current Price",
      width: 125,
      renderCell: (params) => {
        return (
            <span>{params.row.product?.[0]?.price}</span>            
        );
      }
    },
    {
      field: "count",
      headerName: "Delivered Count",
      width: 125,
      renderCell: (params) => {
        return (
            <span>{params.row?.count}</span>            
        );
      }
    },
    {
      field: "Total",
      headerName: "Total Amount",
      width: 125,
      renderCell: (params) => {
        return (
            <span>{params.row?.total}</span>            
        );
      }
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 75,
      renderCell: (params) => {
        return (
            <Link to={`/products/${params.row._id}`}>
              <VisibilityIcon
                style={{ color: "blue", cursor: "pointer" }}
              />
              </Link>
        );
      },
    },
  ];
  

    return ( 
        <>
      <div className="d-flex justify-content-center flex-wrap">
      
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
          <div className="d-flex justify-content-between">
            <h2 className="mb-3" style={{fontSize:'18px'}}>Top Best Sellers</h2>
          </div>
          <div className='py-2 d-flex flex-column'>
          {bestSellersEarningData.length?
          bestSellersEarningData.slice(0, 8).map((item)=>{
            return (
              <div key={item._id} className="userdetails-detailItem m-1">
                  <img
                        src={item?.product?.[0]?.image
                          ? item?.product?.[0]?.image
                          : NoImage}
                        alt=""
                        style={{borderRadius:"10px", height:"40px", width:"40px"}}
                        className="userdetails-itemImg p-1"
                    />
                  <span className="userdetails-itemKey">{item.product?.[0]?.name?.['en']}, </span>
                  <span>Delivered: {item?.count}</span>
              </div>
            )
          }) 
          :
            'No Products Found'
          }
          </div>
        </div>
      </div>
      <Chart data={chartData} title="Total Delivered Orders Revenue Last 6 Months"/>
      </div>
      <DataTable rows={bestSellersEarningData} columns={ordersCoulmns}/>
      </>
    )
}

export default Earnings;