import "../styles/userForm.css";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import React, { useEffect, useState } from "react";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Search from "@mui/icons-material/Search";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import InfoIcon from '@mui/icons-material/Info';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CategoryIcon from '@mui/icons-material/Category';
import { Formik } from "formik";
import { addProduct, getAllCategories, updateProduct, uploadSingle } from "../../Network/appApis";
import {Toast} from '../../helpers/sweetAlert'
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { TagsInput } from "react-tag-input-component";


function ProductForm({ state, functionId, btnName }) {
  const [imgFile, setImgFile] = useState("");
  const Navigate = useNavigate()
  const [selectedTags, setSelectedTags] = useState(state.product_tags);
  const [allCategories, setAllCategories] = useState([])

  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllCategories()
        setAllCategories(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
  },[])

  return (
    <>
      <div className="user-container">
         {
           functionId === 'ADDPRODUCT' ?
           <h1 style={{ color: "lightgray", fontSize: "30px" }}>New Product</h1>
         :
           <h1 style={{ color: "lightgray", fontSize: "30px" }}>Edit Product</h1>
         }
      </div>
      <Formik
             initialValues={state}
             validate={(values) => {
               const errors = {};
               if (!values.name.ar) {
                 errors.nameAr = "Arabic Name is Required";
               }
              //  else if(!/^[؀-ۿ0-9\s]+$/.test(values.name.ar)) {
              //   errors.nameAr = "This Field Accept Arabic Only";
              //   }
                
                if (!values.name.en) {
                  errors.nameEn = "English Name is Required";
                }
                // else if(!/^[A-Za-z0-9 ]+$/.test(values.name.en)) {
                //   errors.nameEn = "This Field Accept English Only";
                // }
               
                if (!values.category) {
                 errors.category = "Category is Required";
               }
               if (typeof (values.stock) != 'number') {
                 errors.stock = "stock is Required";
               }
               if (!values.price) {
                 errors.price = "Price is Required";
               }

               if (!values.description.ar) {
                errors.descriptionAr = "Arabic Description is Required";
              }
              // else if(!/^[؀-ۿ0-9\s]+$/.test(values.description.ar)) {
              //  errors.descriptionAr = "This Field Accept Arabic Only";
              //  }
               
               if (!values.description.en) {
                 errors.descriptionEn = "English Description is Required";
               }
              //  else if(!/^[A-Za-z0-9 ]+$/.test(values.description.en)) {
              //    errors.descriptionEn = "This Field Accept English Only";
              //  }
               return errors;
             }}
             onSubmit={(values, { setSubmitting }) => {
               if(functionId === 'ADDPRODUCT'){
                (async ()=>{
                  try {
                    let imgUrl = ''
                    if(values.image){
                      const imgRes = await uploadSingle({image: values.image})
                      if(imgRes.status === 200){
                        imgUrl = imgRes.data.uploadedImagePath
                      }
                    }

                    const res = await addProduct({...values,image:imgUrl ,product_tags:selectedTags})
                    if (res.status === 201) {
                      Toast("success",`${res.data.data.name?.['en']} Product Successfully Added`);
                      Navigate(-1)
                    }
                  } catch (err) {
                    Toast("error", `${err.response.data.Error}`);
                  }                  
                })()
                
               }else if(functionId === 'EDITPRODUCT'){
                
                Swal.mixin({
                  toast: true,
                    customClass: {
                    confirmButton: 'btn btn-success m-2',
                    cancelButton: 'btn btn-outline-dark m-2'
                  },
                  buttonsStyling: false
                }).fire({
                  title: 'Update Product',
                  text: `Are you sure you want to Update ${values.name?.['en']}?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Update!',
                  cancelButtonText: 'No, cancel!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    (async ()=>{
                      try {
                        let imgUrl = values.image
                        if(imgFile){
                          const imgRes = await uploadSingle({image: values.image})
                          if(imgRes.status === 200){
                            imgUrl = imgRes.data.uploadedImagePath
                          }
                        }
                        
                        const res = await updateProduct({...values,image:imgUrl, product_tags:selectedTags})
                        if (res.status === 201) {
                          Toast(
                            "success",`Product Successfully Updated`
                          );
                          Navigate(-1)
                        }

                      } catch (err) {
                        Toast("error", `Something Went Wrong`);
                      }                  
                    })()
                  } else if (
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Toast('error','Cancelled!')
                  }
                })
               }
               setSubmitting(false);
             }}
           >
             {({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               isSubmitting,
               setFieldValue
             }) => (
              <div className="user-container">
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-wrap justify-content-around"
                >
                  <div className="col-12 col-lg-4">
                    <div className="d-flex flex-column justify-content-center align-items-center my-2">
                      <img
                        width="200px"
                        height="200px"
                        className="img-user"
                        src={
                            imgFile ? URL.createObjectURL(imgFile) :
                            values.image ? values.image :  
                            "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                        alt=""
                      />
                      <label className="label-user" htmlFor="imgFile">
                        Upload Image{" "}
                        <DriveFolderUploadOutlinedIcon className="icon-user" />
                      </label>
                      <input
                        type="file"
                        id="imgFile"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                          setImgFile(event.target.files[0])  
                        }}
                        />
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 my-2 d-flex flex-wrap justify-content-around form-user">
                  <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-1">
                     <DriveFileRenameOutlineIcon className="m-1" />
                     <input
                       type="text"
                       name="name.en"
                       className="form-control"
                       placeholder="English Name"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.name.en}
                     />
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                    {errors.nameEn && touched.name?.en && errors.nameEn}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-1">
                     <DriveFileRenameOutlineIcon className="m-1" />
                     <input
                       type="text"
                       name="name.ar"
                       className="form-control"
                       placeholder="Arabic Name"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.name.ar}
                     />
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                    {errors.nameAr && touched.name?.ar && errors.nameAr}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-1">
                     <CategoryIcon className="m-1" />
                     <select
                       name="category"
                       className="form-control"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.category}
                     >
                      <option value="" disabled>Choose Category</option>
                      {allCategories.length?
                      allCategories.map((category)=>{
                        return(
                          <option key={category._id} value={category._id}>{category.name?.['en']}</option>
                        )
                      }):
                       <option value="" disabled>Please Add Category First</option>
                      }
                     </select>
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                     {errors.category && touched.category && errors.category}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-1">
                     <ProductionQuantityLimitsIcon className="m-1" />
                     <input
                       type="number"
                       name="stock"
                       min={0}
                       className="form-control"
                       placeholder="Stock Quantity"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.stock}
                     />
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                     {errors.stock && touched.stock && errors.stock}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-1">
                     <MonetizationOnIcon className="m-1" />
                     <input
                       type="number"
                       name="price"
                       min={0}
                       step="any"
                       className="form-control"
                       placeholder="Price"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.price}
                     />
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                     {errors.price && touched.price && errors.price}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-5">
                   <div className="d-flex align-items-center mb-4">
                     <QuestionMarkIcon className="m-1" />
                     <select
                       name="status"
                       className="form-control"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       value={values.status}
                     >
                      <option value="" disabled>Choose Status</option>
                      <option value="Active" selected>Active</option>
                      <option value="InActive">InActive</option>
                     </select>
                   </div>
                 </div>
                 
                 <div className="col-12 col-sm-11">
                   <div className="d-flex align-items-center mb-1">
                     <Search className="m-1" />
                     <div style={{color:'black',minWidth: '95%'}}>
                        <TagsInput
                          value={selectedTags}
                          onChange={setSelectedTags}
                          className="col-12 col-sm-11 form-control text-dark"
                          name="product_tags"
                          placeHolder="Enter Product tags separated with Enter"
                        />
                     </div>
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                   </span>
                 </div>
             
                 <div className=" col-12 col-sm-11">
                   <div className="d-flex align-items-center mb-1">
                     <InfoIcon className="m-1" />
                     <textarea
                         style={{ minHeight: "150px" }}
                         className="form-control"
                         rows="2"
                         name="description.en"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.description.en}
                         placeholder="English Description"
                       ></textarea>
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                     {errors.descriptionEn && touched.description?.en && errors.descriptionEn}
                   </span>
                 </div>

                 <div className=" col-12 col-sm-11">
                   <div className="d-flex align-items-center mb-1">
                     <InfoIcon className="m-1" />
                     <textarea
                         style={{ minHeight: "150px" }}
                         className="form-control"
                         rows="2"
                         name="description.ar"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.description.ar}
                         placeholder="Arabic Description"
                       ></textarea>
                   </div>
                   <span
                     style={{ color: "red", marginLeft: "30px" }}
                     className="p-1 text-bold"
                   >
                     {errors.descriptionAr && touched.description?.ar && errors.descriptionAr}
                   </span>
                 </div>

                     <button
                      type="button"
                      onClick={()=>Navigate(-1)}
                      className="btn btn-danger px-2 m-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-success px-2 m-2"
                    >
                      {btnName}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </Formik>
    </>
  );
}

export default ProductForm;
