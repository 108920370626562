import "../styles/circularChart.css";
import { CircularProgressbar } from "react-circular-progressbar";
import HistoryIcon from "@mui/icons-material/History";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import "react-circular-progressbar/dist/styles.css";
import { todaysOrder } from "../../Network/appApis";
import { useEffect, useState } from "react";

function CircularChart() {
  const [data, setData] = useState({});
  const [lastMonth, setLastMonth] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        const res = await todaysOrder();
        setData(res.data.data);
        
        if(res.data.data.month?.length){
          res.data.data.month.forEach((item)=>{
          if(item._id !== new Date().getMonth() + 1){
            setLastMonth(item.totalProfit)
          }else if(item._id === new Date().getMonth() + 1){
            setCurrentMonth(item.totalProfit)
          }
        })
        }
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
      <div className="col-12">
        <div
          className="circularchart-featured p-3 m-2"
          style={{ height: "345px" }}
        >
          <div className="circularchart-top">
            <h1 className="circularchart-title">Month: {new Date().toDateString().split(' ')[1]}</h1>
          </div>
          <div className="circularchart-bottom">
            <div className="circularchart-featuredChart">
              <CircularProgressbar
                value={((currentMonth / lastMonth ) * 100)}
                text={ (isNaN(currentMonth / lastMonth ) * 100)? '0%' : ((currentMonth / lastMonth ) * 100).toFixed(1)+ '%'}
                strokeWidth={9}
              />
            </div>
            <div className="circularchart-summary">
              <div className="circularchart-item">
                <div className="circularchart-itemTitle">Current Month</div>
                <div className="circularchart-itemResult">
                  
                  {
                    currentMonth >= lastMonth ? 
                    <div className="circularchart-resultAmount circularchart-positive">
                        <KeyboardArrowUpOutlinedIcon fontSize="small" /> 
                            {currentMonth ? (currentMonth).toFixed(3) : currentMonth} OMR        
                    </div>:
                      <div className="circularchart-resultAmount circularchart-negative">
                        <KeyboardArrowDownIcon fontSize="small" /> 
                           {currentMonth ? (currentMonth).toFixed(3): currentMonth} OMR
                      </div>
                  }

                </div>
              </div>
              <div className="circularchart-item">
                <div className="circularchart-itemTitle">Last Month</div>
                <div className="circularchart-itemResult">
                  <HistoryIcon fontSize="small" />
                  <div className="circularchart-resultAmount">
                    ${lastMonth}
                  </div>
                </div>
              </div>
            </div>
            <p className="circularchart-desc">
              Last Delivered may not be included.
            </p>
          </div>
        </div>
        </div>
        
        
        <div className="col-12">
        <div
          className="circularchart-featured p-3 m-2"
          style={{ height: "150px" }}
        >
            <h1 className="circularchart-title">Today's Orders</h1>
            <div className="text-center">
              <p className="circularchart-amount">
                ${data?.data?.[0]?.totalProfit || "0"}
              </p>
              <p className="circularchart-title">{data?.data?.[0]?.count} Order(s) Placed Last 24 Hours</p>   
            </div>
            </div>
        </div>
        </div>
    </>
  );
}

export default CircularChart;
