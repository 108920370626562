import UserForm from "../../Components/userForm";
import { useLocation } from 'react-router-dom'


function EditUser() {
  
  const location = useLocation()
  let values = location.state
  values.id = values._id

  return (
    <>
      <UserForm state={values} functionId='EDITUSER' btnName="Update User" />
    </>
  );
}

export default EditUser;
