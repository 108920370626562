import VisibilityIcon from '@mui/icons-material/Visibility';
// import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../../Components/dataTable";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import Swal from 'sweetalert2';
// import { Toast } from '../../../helpers/sweetAlert';
// import { deleteOrder, getAllOrders, updateOrderStatus } from '../../../Network/appApis';
import { getAllOrders } from '../../../Network/appApis';

function Orders() {

  const[allOrders, setAllOrders] = useState([])
 
  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllOrders()
        setAllOrders(res.data.data)
      }catch(err){
        console.log(err)
      }
    })();
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])  

 const ordersCoulmns = [
  { field: "id", headerName: "Number", width: 100,
    renderCell: (params) => {
      return `#${params.row.id}`
    }},
  {
    field: "Email",
    headerName: "User Email",
    width: 200,
      valueGetter: (params) => `${params.row.user?.email}`
  },
  {
    field: "totalPrice",
    headerName: "Price",
    width: 80,
  },{
    field: "paymentMethod",
    headerName: "Payment Method",
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    renderCell: (params) => {
      return (new Date(params.row.createdAt).toDateString());
  }},
  {
    field: "paymentStatus",
    headerName: "Payment Status",
    width: 140,
    renderCell: (params) => {
      return (
        <span style={{margin:'auto'}} className={`dashboardTable-status dashboardTable-status-${params.row.paymentStatus}`}>{params.row.paymentStatus}</span>
        );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
      <span style={{margin:'auto'}} className={`dashboardTable-status dashboardTable-status-${params.row.status}`}>{params.row.status}</span>
      );
    },
  },
  {
    field: "Actions",
    headerName: "Actions",
    width: 100,
    renderCell: (params) => {
      return (
        <div style={{margin:'auto'}} className="d-flex justify-content-around align-items-center">
          <Link to={`/orders/${params.row._id}`}>
            <VisibilityIcon
              style={{ color: "blue", cursor: "pointer" }}
            />
            </Link>
            {/* <span className="mx-2">|</span>
            <DeleteIcon
              onClick={() => deleteItem(params.row)}
              style={{ color: "red", cursor: "pointer" }}
            /> */}
       </div>
      );
    },
  },
];

// function deleteItem(row){
//   Swal.mixin({
//     toast: true,
//       customClass: {
//       confirmButton: 'btn btn-danger m-2',
//       cancelButton: 'btn btn-outline-dark m-2'
//     },
//     buttonsStyling: false
//   }).fire({
//     title: 'Delete Order?',
//     text: `Are you sure you want to Delete Order with total: ${row.totalPrice}?`,
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonText: 'Delete!',
//     cancelButtonText: 'No, cancel!',
//   }).then((result) => {
//     if (result.isConfirmed) {
//       Swal.mixin({
//         toast: true,
//           customClass: {
//           confirmButton: 'btn btn-danger m-2',
//           cancelButton: 'btn btn-outline-dark m-2'
//         },
//         buttonsStyling: false
//       }).fire({
//         title: 'Are You Sure?',
//         text: `The Order Status Automatically will switched to 'Rejected' before deleting it to return the stock Number, this will delete the order from the user's profile too Are you sure to continue?`,
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonText: 'Delete!',
//         cancelButtonText: 'No, cancel!',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           (async function(){
//             try{
//               const res = await updateOrderStatus(row._id,{status:"Rejected"})
//               if(res.status === 200){
//                 (async ()=>{
//                   try {
//                     const res = await deleteOrder(row._id)
//                     if (res.status === 200) {
//                       setAllOrders(allOrders.filter((item)=>{
//                         return item._id !== row._id
//                       }))
//                       Toast(
//                         "success",`${row._id} Order Successfully Deleted`
//                       );
//                     }
//                   } catch (err) {
//                     Toast("error", `${err.response.data.Error}`);
//                   }                  
//                 })()
      
//               }
//             }catch(err){
//               Toast('error','Something Went Wrong!')
//               console.log(err)
//             }
//           })()
          
//         } else if (
//           result.dismiss === Swal.DismissReason.cancel
//         ) {
//           Toast('error','Cancelled!')
//         }
//       })
       
//     } else if (
//       result.dismiss === Swal.DismissReason.cancel
//     ) {
//       Toast('error','Cancelled!')
//     }
//   })
  
// }

    return ( 
    <>
        <div className="user-container d-flex justify-content-between align-items-center">
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>All Transactions</h1> 
        </div>
        <DataTable rows={allOrders} columns={ordersCoulmns}/>
    </> );
}

export default Orders;