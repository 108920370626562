import { useLocation } from "react-router-dom";
import AboutUsForm from "../../Components/general/aboutUsForm";

function UpdateAboutUs() {

    const location = useLocation()
    let values = location.state
    values.id = values._id

    return ( 
    <>
          <AboutUsForm state = {values} functionId = 'EDITABOUTUS' btnName ="Edit Section"/>    
    </> );
}

export default UpdateAboutUs;