import { Formik } from "formik";
import Swal from "sweetalert2";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from '@mui/icons-material/Message';
import { sendMessage, getContact } from "../Network/appApis";
import { Toast} from '../helpers/sweetAlert'
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function Contact() {

  const [contactInfo, setContactInfo] = useState(null)
  const { t } = useTranslation(); // Hook to access translations

  useEffect(()=>{
    (async function(){
      try{
        const res = await getContact()
        setContactInfo(res.data.data)
      }catch(err){}
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <section >
        <div className="container d-flex justify-content-center align-items-center flex-wrap">
          <div className=" my-4" style={{ borderRadius: "25px" }}>
            <div className="p-md-5 row justify-content-center">
              <div className="col-md-10 col-lg-6 col-12">
                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                {t("ContactUs.ContactUs")}
                </p>
                <Formik
              initialValues={{ email: "", name: "", message: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = t("Login.EmailRequired");
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = t("Login.InvalidEmail");
                }
                if (!values.name) {
                  errors.name = t("ContactUs.NameReq");
                }
                if (!values.message) {
                  errors.message = t("ContactUs.MessageReq");
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                
                (async function () {
                  try {
                    const res = await sendMessage(values);
                    if (res.status === 200) {
                      Swal.fire(
                      `Thanks ${values.name}!`,
                      "Your message has been sent",
                      "success"
                    );
                    resetForm({values:''})
                    }
                  } catch (err) {
                    Toast("error", `${err.response.data.Error}`);
                  }
                })();

                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (<form onSubmit={handleSubmit} className="mx-1 mx-md-4">
                  
                  <div className="d-flex flex-row align-items-center mb-1">
                    <PersonIcon className="m-1" />
                  <input
                        className="form-control"
                        type="text"
                        placeholder={t("ContactUs.Name")}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                  </div>
                    <span style={{ color: "red",marginLeft:'30px' }} className="p-1 text-bold">
                        {errors.name && touched.name && errors.name}
                    </span>

                  <div className="d-flex flex-row align-items-center mb-1">
                  <EmailIcon className="m-1" />
                  <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder={t("Login.Email")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                  </div>
                    <span style={{ color: "red",marginLeft:'30px' }} className="p-1 text-bold">
                        {errors.email && touched.email && errors.email}
                    </span>

                    <div className="d-flex flex-row align-items-center mb-1">
                    <MessageIcon className="m-1" />
                    <textarea
                        style={{ minHeight: "200px" }}
                        className="form-control"
                        rows="3"
                        name="message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        placeholder={t("ContactUs.Message")}
                      ></textarea>
                  </div>
                    <span style={{ color: "red",marginLeft:'30px' }} className="p-1 text-bold">
                        {errors.message && touched.message && errors.message}
                    </span>

                  
                  <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                      <button
                        type="submit"
                        className="btn btn-dark"
                        disabled={isSubmitting}
                      >
                        {t("ContactUs.Send")}
                      </button>
                  </div>

                </form>
                )}
                </Formik>
              </div>
              <div className="col-md-10 col-lg-6 col-12 d-flex align-items-center">
                <img
                  // src="https://d2o7rqynhxcgmp.cloudfront.net/uploads/images/modules/column/United-States/_960x480_crop_center-center_50_none/bambora-contact-sales_2021-09-20-202948_vszr.svg"
                  src="https://seifstores.net/wp-content/uploads/2020/06/contact-us.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
   
      {/* icons */}
      <div className="container col-lg-12">
        <div className=" mt-4 border-0 mb-4">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="d-flex align-items-center justify-content-center pl-0">
                <div className="mr-3 align-self-center p-2">
                  <img
                    src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon1.png"
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <h5>{t("ContactUs.Address")}</h5>
                  <p>
                    {contactInfo?.location} <br /> {contactInfo?.city}, {contactInfo?.country}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="mr-3 align-self-center p-2">
                  <img
                    src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon2.png"
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <h5>{t("ContactUs.PhoneNumber")}</h5>
                  <p>
                  {contactInfo?.phoneNumber}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="mr-3 align-self-center p-2">
                  <img
                    src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/contact/icon3.png"
                    alt=""
                  />
                </div>
                <div className='p-2'>
                  <h5>{t("Login.Email")}</h5>
                  <p >
                  {contactInfo?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
}

export default Contact;
