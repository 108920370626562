import { useNavigate } from "react-router-dom";
import emptyCart from '../../images/NoProducts.png'
import { useTranslation } from "react-i18next";

function NoProductsShoppingCart() {
  const Navigate = useNavigate();
  const { t } = useTranslation(); // Hook to access translations

  return (
    <>
      <section>
        <div className="container d-flex justify-content-center align-items-center flex-wrap">
          <div
            className="my-4"
            style={{ borderRadius: "25px" }}
          >
            <div className="p-md-5 row justify-content-center">
              <div className="col-md-10 col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center">
                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                  {t("Cart.Empty")}
                </p>
                <h5 className="my-2">
                    {t("Cart.EmptyMessage")}
                </h5>
                <button
                  onClick={() => Navigate("/productList")}
                  className="my-2 btn btn-dark"
                >
                    {t("Cart.ContinueShopping")}
                </button>
              </div>
              <div className="col-md-10 col-lg-6 col-12 d-flex align-items-center">
                <img
                  src={emptyCart}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NoProductsShoppingCart;
