import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Rating } from "@mui/material";
import NoImage from "../images/noImage.jpg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ProductCard({ item }) {

  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
        <div className="card m-2">
          <Card>
            <CardActionArea>
              <Link to={`/productdetails/${item._id}`}>
                <CardMedia
                  component="img"
                  height="250"
                  image={item.image
                    ? item.image
                    : NoImage}
                  alt={item.name}
                  style={{ objectFit: "fill" }}
                />
              </Link>
            </CardActionArea>
            <CardContent>
              {/* <Typography gutterBottom variant="h5" component="div">
                {item.name?.[lang]}
              </Typography> */}
              <h5> {item.name?.[lang]} </h5>
              <div className="d-flex justify-content-between">
                <Rating size='small' name='read-only' precision={0.5} value={item.rating/ item.numOfReviews} readOnly/> 
                <h6 style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {item.price ? (item.price).toFixed(3): item.price} {t("ConfirmOrder.OMR")}
                </h6>
              </div>
              {item.category?.name?.[lang] && <h6 style={{ fontSize: "12px" }}>{t("Products.Category")}: {item.category?.name?.[lang]}</h6>}
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
