import { useDispatch, useSelector } from "react-redux";
import NotAuth from "../../Components/notAuthenticated";
import { useEffect, useState } from "react";
import { addNewOrder, getShippingPrice } from "../../Network/appApis";
import { Toast } from "../../helpers/sweetAlert";
import { resetShoppingCartAction } from '../../store/Actions/shoppingCartAction'
import { useNavigate } from "react-router-dom";
import SubmitOrderProductItem from "../../Components/submitOrderProductItem";
import { useTranslation } from 'react-i18next';

function ConfirmOrder() {
    const Dsipatch = useDispatch()
    const Navigate = useNavigate()

    const isLogged = useSelector((state) => state.isAuth.isLogged);
    const user = useSelector((state) => state.isAuth.user?._id);
    const address = useSelector((state) => state.isAuth.user?.address);
  
    const productsPrice = useSelector((state)=>state.shoppingCart.totalPrice)
    const [totalPrice ,setTotalPrice] = useState(productsPrice)
    const allCartProducts = useSelector((state)=>state.shoppingCart.products)
    const productsCount = useSelector((state)=>state.shoppingCart.count)
    const [shippingPrice ,setShippingPrice] = useState(0)
    const { t } = useTranslation(); // Hook to access translations

    useEffect(()=>{
        (async function(){
          try{
            const res = await getShippingPrice()
            setTotalPrice(
              res.data.data[0]?.shippingPrice?
              res.data.data[0]?.shippingPrice+productsPrice:
              productsPrice
            )
            setShippingPrice(
              res.data.data[0]?.shippingPrice?
              res.data.data[0]?.shippingPrice:
              0
              )
          }catch(err){
            console.log(err)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      const submitCashOnDoorOrder = ()=>{
    
        if(!allCartProducts.length){
            Navigate('/productList')
            Toast('warning',`Please Add Products First!`)
              return
          }else if(!address){
              Navigate('/addAddress')
              Toast('warning',`Please Add Address First!`)
              return
          }

        let cartProducts = []
        allCartProducts.forEach((item)=>{
          cartProducts.push({_id:item._id, quantity:item.quantity})
        })
    
        let orderObj = {
          user,
          cartProducts,
        };
        
        (async function(){
          try{
            const res = await addNewOrder(orderObj)
            if(res.status === 201){
              Dsipatch(resetShoppingCartAction())
              Navigate('/order/orderAccepted')
              Toast('success',`Order Successfully Added!`)
            }
          }catch(err){
            if(err?.response?.data?.Error === 'Error: StockError '){
              Dsipatch(resetShoppingCartAction())
              Navigate('/shoppingCart')
              Toast('warning',`Stock Error! Please Refill Your Shopping Cart`)
            }else{
              Toast('error',`Something Went Wrong Please Try Again Later!`)
            }
          }
        })()
      }

    return (
        <>
        {isLogged ? (
            <>
                <div className="container my-5">
                <h4>{t("ConfirmOrder.OrderConfirmation")}</h4>

                <div className='d-flex flex-wrap justify-content-center mt-5'>
                {allCartProducts.map((item)=>{
                    return( 
                    <SubmitOrderProductItem item={item} key={item._id} />
                )})}
                </div>
                <div className="text-center">
                    <p className="mb-1 fw-bold">{t("ConfirmOrder.NoOfItems")}: {productsCount}</p>
                    <p className="mb-1 fw-bold">{t("ConfirmOrder.ProductsPrice")}: {productsPrice ? (productsPrice).toFixed(3): productsPrice} {t("ConfirmOrder.OMR")}</p>
                    <p className="mb-1 fw-bold">{t("ConfirmOrder.ShippingPrice")}: {shippingPrice ? (shippingPrice).toFixed(3): 0} {t("ConfirmOrder.OMR")}</p>
                    <p className="mb-1 fw-bold">{t("ConfirmOrder.TotalPrice")}: {totalPrice ? (totalPrice).toFixed(3): totalPrice} {t("ConfirmOrder.OMR")}</p>
                </div>
                <div className='d-flex justify-content-center m-4'>
                    <button onClick={submitCashOnDoorOrder} className="btn btn-dark">{t("ConfirmOrder.SubmitOrder")}</button>
                </div>
                </div>
            </>
            ) : (<NotAuth path='/order/confirmOrder'/>)
        }
        </>
        )   
}

export default ConfirmOrder;