import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddressForm from "../../Components/addressForm";
import NotAuth from "../../Components/notAuthenticated";
import { useTranslation } from 'react-i18next';

function EditAddress() {
  const location = useLocation();
  const values = location?.state;
  const user = useSelector((state) => state.isAuth.user?._id);
  const isLogged = useSelector((state) => state.isAuth.isLogged);
  const { t } = useTranslation(); // Hook to access translations
  values && (values.id = values?._id);
  values && ( values.user = user)

  return (
    <>
    {
      isLogged?
      <AddressForm
        state={values}
        title={t("AddressForm.UpdateAddress")}
        btnName={t("AddressForm.UpdateAddress")}
        functionId="AddressForm.UpdateContinue"
      />
      :
      <NotAuth />
    }
    </>
  );
}

export default EditAddress;
