import {SET_ADD_SHOPPING_CART} from './types'
import {SET_EDIT_SHOPPING_CART} from './types'
import {SET_INCREMENT_SHOPPING_CART} from './types'
import {SET_REMOVE_SHOPPING_CART} from './types'
import {SET_RESET_SHOPPING_CART} from './types'

export const addShoppingCartAction = (payload) => ({
    type:SET_ADD_SHOPPING_CART,
    payload
})

export const editShoppingCartAction = (payload) => ({
    type:SET_EDIT_SHOPPING_CART,
    payload
})

export const incrementShoppingCartAction = (payload) => ({
    type:SET_INCREMENT_SHOPPING_CART,
    payload
})

export const removeShoppingCartAction = (payload) => ({
    type:SET_REMOVE_SHOPPING_CART,
    payload
})

export const resetShoppingCartAction = (payload) => ({
    type:SET_RESET_SHOPPING_CART,
    payload
})
