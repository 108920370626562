import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NotAuth from '../../Components/notAuthenticated';
import NotificationCard from '../../Components/notificationCard'
import { getUserNotifications } from '../../Network/appApis';
import { useTranslation } from 'react-i18next';


function Notification() {
    
    let isLogged = useSelector((state)=>state.isAuth.isLogged)
    let userId = useSelector((state)=>state.isAuth.user?._id)
    let [allNotifications, setAllNotifications] = useState([])
    const { t } = useTranslation(); // Hook to access translations
    
    let removeElement = (id) => {
      setAllNotifications(allNotifications.filter((item)=>{
        return item._id !== id
      }))
     }

    useEffect(()=>{
        (async function(){
          try{
            const res = await getUserNotifications(userId)
            setAllNotifications(res.data.data.notifications)
          }catch(err){
            console.log(err)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    


    return ( 
    <>
    <div className='container'>    
      <h4 className="my-4">{t("Notifications.UrNotifi")}</h4>
        {
            isLogged 
            ?
            allNotifications?.length ?
            allNotifications.slice(0).reverse().map((item)=>{
                return (
                  <NotificationCard item={item} removeElement={removeElement} key={item._id}/>
                  )
            })
            :
            <div>
               {t("Notifications.No")}
            </div> 
            :
            <NotAuth/>
        }
    </div>  
    </>
     );
}

export default Notification;