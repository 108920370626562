import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {getAllCategories} from '../Network/appApis'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import NoImage from "../images/noImage.jpg";

const Categories = () => {

  const location = useLocation()
  const initialState = location.state
  const [allCategories,setAllCategories] = useState([])
  const { t } = useTranslation(); // Hook to access translations
  let lang = useSelector((state)=>state.lang)

  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllCategories()
        setAllCategories(res.data.data)
      }catch(err){
        console.log(err)
      }
    })()
  },[])

  return (
    <>
      <h3 style={{ margin: "30px 0px 0px 15px" }}>{t("Home.Categories")}</h3>
      <div style={{ height: "300px" }}>
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          navigation={{
            clickable: true,
          }}
          breakpoints={{
            769: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
          modules={[Navigation, Pagination]}
          className="mySwiper"
        >
          {allCategories.length? 
            allCategories.map((item) => (
            <SwiperSlide key={item._id}>
              <div style={{ height: "300px" }}>
                <div
                  className="m-2 d-flex align-items-end"
                  style={{
                    height: "100%",
                    border: "1px solid #777777",
                    borderRadius: "5px",
                    ...item.image && {backgroundImage: `url(${item.image})`},
                    ...!item.image && {backgroundImage: `url(${NoImage})`},
                    backgroundSize: "cover",
                  }}
                >
                  <Link className="m-3 btn btn-light" to='/productList' state={{...initialState,category:item._id}}>
                    {item.name?.[lang]} <ArrowForwardIosIcon />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          )):<>{t("Home.CommingSoon")}</>}
        </Swiper>
      </div>
    </>
  );
};

export default Categories;
