import {SET_LOGIN_IS_AUTH} from '../Actions/types'
import {SET_LOGOUT_IS_AUTH} from '../Actions/types'
import {SET_EDIT_ADDRESS} from '../Actions/types'
import {SET_DELETE_ADDRESS} from '../Actions/types'
import {SET_ADD_ADDRESS} from '../Actions/types'
import {SET_EDIT_PROFILE} from '../Actions/types'


const INITIAL_STATE = {
    isLogged:false,
    user:{}
}

export function isAuthReducer(state=INITIAL_STATE,action){
    switch (action.type) {
        case SET_LOGIN_IS_AUTH:
            localStorage.setItem('token',action.payload.token)
            return{
                isLogged:true,
                user:action.payload.data
            }

            case SET_LOGOUT_IS_AUTH:
            localStorage.removeItem('token')
            return{
                isLogged:false,
                user:{}
            }

            case SET_ADD_ADDRESS:
            return{
                isLogged:state.isLogged,
                user:{
                    ...state.user,
                    address:action.payload
                }
            }

            case SET_EDIT_ADDRESS:
            return{
                isLogged:state.isLogged,
                user:{
                    ...state.user,
                    address:action.payload
                }
            }

            case SET_DELETE_ADDRESS:
            return{
                isLogged:state.isLogged,
                user:{
                    ...state.user,
                    address:null
                }
            }

            case SET_EDIT_PROFILE:
            return{
                isLogged:state.isLogged,
                user:{
                    ...state.user,
                    firstName: action.payload.firstName,
                    lastName:action.payload.lastName,
                    email:action.payload.email,
                }
            }
        default:
            return state
    }
}