import { DataGrid } from '@mui/x-data-grid';
import "../styles/dataTable.css";

function DataTable({type,rows,columns}) {
return ( 
    <>
    <div className="col-12">
    <div className="dashboardTable p-3 m-2">
      {
        type ? 
        <h5>{type}</h5>
        : null
      }
      
    <div style={{ height: 635, width: '100%' }}>
      <DataGrid
        rows={rows}
        getRowId={() => Math.floor(Math.random() * 100000000)}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
    </div>
    </div>

    </> );
}

export default DataTable;