import "../../styles/userDetails.css";
import Chart from "../../Components/chart";
import DataTable from "../../Components/dataTable";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserById, getUserOrders, lastSixMonthsEarningsForUser } from "../../../Network/appApis";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const UserDetails = () => {

  const [user,setUser] = useState({})
  const [userOrders,setUserOrders] = useState([])
  const [chartData,setChartData] = useState([])
  let Params = useParams();
  const Navigate = useNavigate()

  useEffect(()=>{
    (async function(){
      try{
        const res = await getUserById(Params.id)
        setUser(res.data.data[0])
      }catch(err){
        console.log(err)
      }
    })()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])
 
 useEffect(()=>{
  (async function(){
    try{
      const res = await getUserOrders(Params.id)
      setUserOrders(res.data.data)
    }catch(err){
      console.log(err)
    }
  })()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

useEffect(()=>{
  (async function(){
    try{
      const res = await lastSixMonthsEarningsForUser(Params.id)
        setChartData(res.data.data)
       }catch(err){
      console.log(err)
    }
  })()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const ordersCoulmns = [
    { field: "_id", headerName: "ID", width: 210 },
    {
      field: "Email",
      headerName: "User Email",
      width: 200,
        valueGetter: (params) => `${params.row.user?.email}`
    },
    {
      field: "totalPrice",
      headerName: "Price",
      width: 60,
    },{
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 130,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      renderCell: (params) => {
        return (new Date(params.row.createdAt).toDateString());
    }
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
        <span className={`dashboardTable-status dashboardTable-status-${params.row.status}`}>{params.row.status}</span>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-around align-items-center">
            <Link to={`/orders/${params.row._id}`}>
              <VisibilityIcon
                style={{ color: "blue", cursor: "pointer" }}
              />
              </Link>
              {/* <span className="mx-2">|</span>
              <DeleteIcon
                onClick={() => deleteItem(params.row)}
                style={{ color: "red", cursor: "pointer" }}
              /> */}
         </div>
        );
      },
    },
  ];
  
  // function deleteItem(row){
  //   Swal.mixin({
  //     toast: true,
  //       customClass: {
  //       confirmButton: 'btn btn-danger m-2',
  //       cancelButton: 'btn btn-outline-dark m-2'
  //     },
  //     buttonsStyling: false
  //   }).fire({
  //     title: 'Delete Order?',
  //     text: `Are you sure you want to Delete Order with total: ${row.totalPrice}?`,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Delete!',
  //     cancelButtonText: 'No, cancel!',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.mixin({
  //         toast: true,
  //           customClass: {
  //           confirmButton: 'btn btn-danger m-2',
  //           cancelButton: 'btn btn-outline-dark m-2'
  //         },
  //         buttonsStyling: false
  //       }).fire({
  //         title: 'Are You Sure?',
  //         text: `The Order Status Automatically will switched to 'Rejected' before deleting it to return the stock Number, this will delete the order from the user's profile too Are you sure to continue?`,
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonText: 'Delete!',
  //         cancelButtonText: 'No, cancel!',
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           (async function(){
  //             try{
  //               const res = await updateOrderStatus(row._id,{status:"Rejected"})
  //               if(res.status === 200){
  //                 (async ()=>{
  //                   try {
  //                     const res = await deleteOrder(row._id)
  //                     if (res.status === 200) {
  //                       setUserOrders(userOrders.filter((item)=>{
  //                         return item._id !== row._id
  //                       }))
  //                       Toast(
  //                         "success",`${row._id} Order Successfully Deleted`
  //                       );
  //                     }
  //                   } catch (err) {
  //                     Toast("error", `${err.response.data.Error}`);
  //                   }                  
  //                 })()
        
  //               }
  //             }catch(err){
  //               Toast('error','Something Went Wrong!')
  //               console.log(err)
  //             }
  //           })()
            
  //         } else if (
  //           result.dismiss === Swal.DismissReason.cancel
  //         ) {
  //           Toast('error','Cancelled!')
  //         }
  //       })
         
  //     } else if (
  //       result.dismiss === Swal.DismissReason.cancel
  //     ) {
  //       Toast('error','Cancelled!')
  //     }
  //   })    
  // }
  
  return (
    <div className="d-flex justify-content-center flex-wrap">
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
          <div className="d-flex justify-content-between">
            <h2 className="mb-3" style={{fontSize:'18px'}}><ArrowBackIosNewIcon style={{cursor:'pointer'}} onClick={()=>Navigate(-1)}/>  Information</h2>
            <Link to='edituser' state={user}>
              <BorderColorIcon style={{cursor:'pointer'}}/>
            </Link>
          </div>
              <h4>{user.firstName} {user.lastName}</h4>
          <div className='py-2 d-flex flex-column'>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">User ID:</span>
                  <span>{user._id}</span>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Role:</span>
                  <span>{user.isAdmin? "Admin" : "User"}</span>
              </div>
              <div className="userdetails-detailItem">
                <span className="userdetails-itemKey">Email:</span>
                  <span>
                    {user.email}
                  </span>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Created At:</span>
                  <span>{new Date(user.createdAt).toDateString()}</span>
              </div>
          </div>
        </div>
      </div>
      <Chart data={chartData} title="Total Delivered User' Revenue Last 6 Months" />
      <DataTable rows={userOrders} columns={ordersCoulmns} type='User Transactions' />      
    </div>
  );
};

export default UserDetails;
