import "../styles/categoryForm.css";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import React, { useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { addCategory, updateCategory, uploadSingle } from "../../Network/appApis";
import { Toast } from "../../helpers/sweetAlert";
import Swal from "sweetalert2";

function CategoryForm({ state, functionId, btnName }) {
  const [imgFile, setImgFile] = useState("");
  const Navigate = useNavigate()


  return (
    <>
      <div className="category-container">
        {functionId === "ADDCATEGORY" ? (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>New Category</h1>
        ) : (
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>
            Edit Category
          </h1>
        )}
      </div>
      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};

          if (!values.name.en) {
            errors.nameEn = "English Name is Required";
          }
          // else if(!/^[A-Za-z0-9 ]+$/.test(values.name.en)) {
          //   errors.nameEn = "This Field Accept English Only";
          // }
  
          if (!values.name.ar) {
            errors.nameAr = "Arabic Name is Required";
          }
          // else if(!/^[؀-ۿ0-9\s]+$/.test(values.name.ar)) {
          //   errors.nameAr = "This Field Accept Arabic Only";
          // }
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          if (functionId === "ADDCATEGORY") {
            (async ()=>{
              try {
                let imgUrl = ''
                if(values.image){
                  const imgRes = await uploadSingle({image: values.image})
                  if(imgRes.status === 200){
                    imgUrl = imgRes.data.uploadedImagePath
                  }
                }
                const res = await addCategory({...values, image: imgUrl})
                if (res.status === 201) {
                  Toast(
                    "success",`${res.data.data.name["en"]} Category Successfully Added`
                  );
                  Navigate(-1)
                }
               } catch (err) {
                Toast("error", `${err.response.data.Error}`);
              }                  
            })()

          } else if (functionId === "EDITCATEGORY") {
            
                Swal.mixin({
                  toast: true,
                    customClass: {
                    confirmButton: 'btn btn-success m-2',
                    cancelButton: 'btn btn-outline-dark m-2'
                  },
                  buttonsStyling: false
                }).fire({
                  title: 'Update Category',
                  text: `Are you sure you want to Update ${values.name["en"]}?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Update!',
                  cancelButtonText: 'No, cancel!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    (async ()=>{
                      try {
                        let imgUrl = values.image
                        if(imgFile){
                          const imgRes = await uploadSingle({image: values.image})
                          if(imgRes.status === 200){
                            imgUrl = imgRes.data.uploadedImagePath
                          }
                        }
                        const res = await updateCategory({...values, image: imgUrl})
                        if (res.status === 201) {
                          Toast(
                            "success",`Your Category Successfully Edited`
                          );
                          Navigate(-1)
                        }
                      } catch (err) {
                        Toast("error", `Something Went Wrong`);
                      }                  
                    })()
                  } else if (
                    result.dismiss === Swal.DismissReason.cancel
                  ) {
                    Toast('error','Cancelled!')
                  }
                })
               }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div className="category-form-container">
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center flex-column"
            >
              <div className="d-flex flex-column justify-content-center align-items-center my-2">
                <img
                  width="100px"
                  height="100px"
                  className="img-category"
                  src={
                    imgFile ? URL.createObjectURL(imgFile) :
                            values.image ? values.image :  
                            "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                        }
                  alt=""
                />
                <label className="label-category" htmlFor="imgFile">
                  Upload Image{" "}
                  <DriveFolderUploadOutlinedIcon className="icon-category" />
                </label>
                <input
                  type="file"
                  id="imgFile"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setFieldValue("image", event.currentTarget.files[0]);
                    setImgFile(event.target.files[0]);
                  }}
                />
              </div>

              <div className="col-12 col-sm-6">
                <div className="d-flex align-items-center mb-1">
                  <DriveFileRenameOutlineIcon className="m-1" />
                  <input
                    type="text"
                    name="name.en"
                    className="form-control"
                    placeholder="English Category Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name.en}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.nameEn && touched.name?.en && errors.nameEn}
                </span>

                
                <div className="d-flex align-items-center mb-1">
                  <DriveFileRenameOutlineIcon className="m-1" />
                  <input
                    type="text"
                    name="name.ar"
                    className="form-control"
                    placeholder="Arabic Category Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name.ar}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.nameAr && touched.name?.ar && errors.nameAr}
                </span>

                <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      onClick={()=>Navigate(-1)}
                      className="btn btn-danger px-2 m-2"
                    >
                      Cancel
                    </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    {btnName}
                  </button>
                </div>
              </div>
            </form>
           </div>
        )}
      </Formik>
    </>
  );
}

export default CategoryForm;
