import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import LinkIcon from '@mui/icons-material/Link';
import GroupsIcon from '@mui/icons-material/Groups';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { getAllGeneral } from "../../Network/appApis";
import SocialMediaLinksForm from "../Components/general/socialMediaLinksForm";
import CompanyInfo from "../Components/general/companyInfo";
import TeamMembers from "../Components/general/teamMembers";
import AboutUs from "../Components/general/aboutUs";
import ImageSlider from "../Components/general/imageSlider";

function General() {
  const [imageHeader, setImageHeader] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(false);
  const [socialLinks, setSocialLinks] = useState(false);
  const [members, setMembers] = useState(false);
  
  const [aboutUsData, setAboutUsData] = useState([]);
  const [sliderImagesData, setSliderImagesData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [socialMediaLinkesData, setSocialMediaLinkesData] = useState(null);
  const [contactData, setContactData] = useState(null);

  const updateAboutUsParent = (data) => {
    setAboutUsData(data)
  }

  const updateSliderImagesParent = (data) => {
    setSliderImagesData(data)
  }

  const updateMembersParent = (data) => {
    setMembersData(data)
  }

  const updateSocialMediaLinkesParent = (data) => {
    setSocialMediaLinkesData(data)
  }

  const updateContactParent = (data) => {
    setContactData(data)
  }
  
 
  useEffect(()=>{
    (async function(){
      try{
        const res = await getAllGeneral()
        if(res.data.data != null){
          setAboutUsData(res.data.data?.aboutUs || [])
          setSliderImagesData(res.data.data?.homeSliderImages || [])
          setMembersData(res.data.data?.members || [])
          setSocialMediaLinkesData(res.data.data?.socialMediaLinkes || null)
          setContactData(res.data.data?.contact || null)
        }
        }catch(err){
        console.log(err)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    
  return (
    <>
      <div className="user-container d-flex justify-content-between align-items-center">
        <h1 style={{ color: "lightgray", fontSize: "30px" }}>General</h1>
      </div>
      <div className="col-12">
      <div className="dashboardTable p-3 m-2" style={{ minHeight: "500px" }}>

      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader">



        {/* ************************************************************************* START IMAGE CUSRSOL */}
        <ListItemButton onClick={()=>{setImageHeader(!imageHeader)}}>
          <ListItemIcon>
            <ViewCarouselIcon />
          </ListItemIcon>
          <ListItemText primary="Carousel Images" />
          {imageHeader ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={imageHeader} timeout={500} unmountOnExit>
            <ImageSlider parentCallback={updateSliderImagesParent} list={sliderImagesData}/>
        </Collapse>



        {/* ************************************************************************* START ABOUT US */}
        <ListItemButton onClick={()=>{setAboutUs(!aboutUs)}}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About Us" />
          {aboutUs ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={aboutUs} timeout={500} unmountOnExit>
            <AboutUs parentCallback={updateAboutUsParent} list={aboutUsData}/>
        </Collapse>



        {/* ************************************************************************* Company Info */}
        <ListItemButton onClick={()=>{setCompanyInfo(!companyInfo)}}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Company Info" />
          {companyInfo ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={companyInfo} timeout={500} unmountOnExit>
        {
            contactData == null ?
            <CompanyInfo state={{
              phoneNumber : null,
              email : "",
              country : "",
              city : "",
              location : ""
            }}
              />
            :
            <CompanyInfo parentCallback={updateContactParent} state={contactData}/>    
        }
        </Collapse>



        {/* ************************************************************************* START SOCIAL LINKS */}
        <ListItemButton onClick={()=>{setSocialLinks(!socialLinks)}}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Social Media Links" />
          {socialLinks ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={socialLinks} timeout={500} unmountOnExit>
          {
            socialMediaLinkesData == null ?
            <SocialMediaLinksForm state={{
                facebook : "",
                instagram : "",
                twitter : "",
                whatsapp : ""}}/>
            :
            <SocialMediaLinksForm parentCallback={updateSocialMediaLinkesParent} state={socialMediaLinkesData}/>    
          }
        </Collapse>


        {/* ************************************************************************* START TEAM MEMBERS */}
        <ListItemButton onClick={()=>{setMembers(!members)}}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Team Members" />
          {members ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={members} timeout={500} unmountOnExit>
          <TeamMembers parentCallback={updateMembersParent} list={membersData}/>
        </Collapse>
      </List>
      
      </div>
      </div>
    </>
  );
}

export default General;
