import {SET_ADD_SHOPPING_CART} from '../Actions/types'
import {SET_EDIT_SHOPPING_CART} from '../Actions/types'
import {SET_REMOVE_SHOPPING_CART} from '../Actions/types'
import {SET_INCREMENT_SHOPPING_CART} from '../Actions/types'
import {SET_RESET_SHOPPING_CART} from '../Actions/types'

const INITIAL_STATE = {
    count:0,
    products:[],
    totalPrice:0
}

export function shoppingCartReducer(state=INITIAL_STATE,action){
    switch (action.type) {
        case SET_ADD_SHOPPING_CART:
            return{
                ...state,
                products:[...state.products,action.payload],
                count:state.count+action.payload.quantity,
                totalPrice:state.totalPrice+(action.payload.price*action.payload.quantity),
            }

        case SET_EDIT_SHOPPING_CART:
                    const indexPos = state.products.map(e => e._id).indexOf(action.payload._id);
                    const products= [...state.products]
                    const newObj = {...products[indexPos]}
                    newObj.quantity = action.payload.quantity
                    newObj.stock = (products[indexPos].stock + products[indexPos].quantity) - action.payload.quantity 
                    products[indexPos] = newObj
                return{
                    ...state,
                    products,
                    count:(state.count - state.products[indexPos].quantity ) + action.payload.quantity,
                    totalPrice: (state.totalPrice - (state.products[indexPos].quantity * state.products[indexPos].price)) + (action.payload.quantity * action.payload.price) 
                }
        
        case SET_INCREMENT_SHOPPING_CART:        
                    const index = state.products.map(e => e._id).indexOf(action.payload._id);
                    const newProducts= [...state.products]
                    const obj = {...newProducts[index]}
                    obj.quantity = newProducts[index].quantity + action.payload.quantity
                    obj.stock = newProducts[index].stock - action.payload.quantity
                    newProducts[index] = obj
                return{
                    ...state,
                    products:newProducts,
                    count:state.count + action.payload.quantity,
                    totalPrice: state.totalPrice  + (action.payload.quantity * action.payload.price) 
                }
                
        case SET_REMOVE_SHOPPING_CART:
                return{
                    ...state,
                    products:[...state.products].filter((item)=>{
                        return item._id !== action.payload._id
                    }),
                    count:state.count-action.payload.quantity,
                    totalPrice:state.totalPrice-(action.payload.price*action.payload.quantity),
                }
                
        case SET_RESET_SHOPPING_CART:
                return{
                    count:0,
                    products:[],
                    totalPrice:0
                }
    
        default:
            return state
    }
}