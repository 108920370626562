import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import MailIcon from '@mui/icons-material/Mail';
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { updateContact } from "../../../Network/appApis";
import { Toast } from "../../../helpers/sweetAlert";
import { useState } from "react";


function CompanyInfo({state, parentCallback}) {

  const [formState, setFormState] = useState({...state})
  const [update, setUpdate] = useState(true)

    const formik = useFormik({
        initialValues:formState,
        enableReinitialize:true,
        onSubmit: (values) => {
            (async function(){
              try{
                const res = await updateContact(values)
                if(res.status === 200){
                  Toast('success',`Company Info Updated!`)
                  setFormState(res.data.data)
                  setUpdate(!update)
                  parentCallback(res.data.data)
                }
              }catch(err){
                console.log(err)
                Toast('error',`Something Went Wrong Please Try Again Later!`)
              }
            })()
          },
        })

        
    return ( 
    <>
    <form
          className="d-flex flex-wrap justify-content-around mx-5 my-2"
          onSubmit={formik.handleSubmit}
        >
          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <MailIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="email"
                type="text"
                size="small"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <PhoneIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="phoneNumber"
                type="text"
                size="small"
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <PublicIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="country"
                type="text"
                size="small"
                label="Country"
                value={formik.values.country}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11 col-sm-5">
            <div className="mx-1 d-flex align-items-center">
            <LocationCityIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                disabled={update}
                name="city"
                type="text"
                size="small"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <div className="my-2 col-11">
            <div className="mx-1 d-flex align-items-center">
            <LocationOnIcon style={{width:'30px',height:'30px'}}/>
              <TextField
                fullWidth
                multiline
                minRows={2}
                disabled={update}
                name="location"
                type="text"
                size="small"
                label="Full Address"
                value={formik.values.location}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className='col-11 col-sm-4 d-flex justify-content-around'>
            {
                !update &&
                <>
                <span className="btn btn-danger my-2" onClick={()=>{
                  formik.handleReset()
                  setUpdate(!update)
                }}>
                    Cancel
                </span>
                <button className="btn btn-success my-2" type="submit">
                    Confirm
                </button>
                </>
            }

            {
                update &&
                <>
                  <span onClick={()=>setUpdate(!update)} className="btn btn-success my-2">
                      Update
                  </span>
                </>
            }
            
          </div>
        </form>
    </> );
}

export default CompanyInfo;