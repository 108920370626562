import Footer from "../Components/Footer";
import MyNavbar from "../Components/Navbar";
import Routing from "../Router/router";
import { useSelector } from "react-redux";

function Index() {
  let lang = useSelector((state)=>state.lang)

  return ( 
    <>
    <div dir={`${lang === 'ar'? 'rtl' : "ltr"}`}>
      <MyNavbar/>
        <div style={{minHeight:'50vh'}}>
          <Routing/>
        </div>
      <Footer/>
    </div>
    </> );
}

export default Index;