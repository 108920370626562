import { Add, Remove } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { editShoppingCartAction, removeShoppingCartAction } from "../store/Actions/shoppingCartAction";
import { Toast } from "../helpers/sweetAlert";
import NoImage from "../images/noImage.jpg";
import { useTranslation } from 'react-i18next';

function ShoppingCardItem({item, index, cartCount}) {
  
  let [uiQuantity,setUiQuantity] = useState(item.quantity)
  const dispatch = useDispatch();
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  const Amount = {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    border: "1px solid teal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 5px",
  };  

  let removeItem=(item)=>{
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Remove Product?',
      text: `Are you sure you want to remove the following product? ${item.name?.[lang]}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeShoppingCartAction(item))
        Toast('success',`${item.name?.[lang]} has been removed`)
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
  }

  let incItem=(item)=>{
    if(item.stock!==0){
      setUiQuantity(uiQuantity +=1)
      dispatch(editShoppingCartAction({...item,quantity:uiQuantity ,stock: item.stock-uiQuantity}))
    }else{
      Toast('warning',`Only ${item.stock} item(s) are Available`)
    }
  }
  
  let decItem=(item)=>{
    if(item.quantity !== 1){
      setUiQuantity(uiQuantity-=1)
      dispatch(editShoppingCartAction({...item,quantity:uiQuantity ,stock: item.stock-uiQuantity}))
    }else{
      Toast('warning',`Minimum quantity is one`)
    }
  }

  return (
    <>  
      <div className='d-flex justify-content-end'>
          <CloseIcon className='p-0 m-0' style={{width:'30px',height:'30px',cursor:'pointer'}} onClick={()=>{removeItem(item)}}/>
      </div>
      <div className="row">
        <div className="col-lg-4 col-6 mb-4 mb-lg-0">
          <img 
              height='150px'
              width='150px'
              src={item.image
                ? item.image
                : NoImage}
              alt={item.name?.[lang]}
            />
        </div>

          <div className="col-lg-7 col-6 mb-4 mb-lg-0 d-flex justify-content-center align-items-center flex-column flex-lg-row">
            <div className='col-lg-6 col-12'>
              <h5>{item.name?.[lang]}</h5>
              <p style={{fontSize:'12px'}}>{t("Products.Category")}: {item.category?.name?.[lang]}</p>
              {item.stock > 0 ? 
                    <h6 style={{fontSize:'12px',color:'green',fontWeight:'bold'}}>{t("Products.InStock")}</h6>:
                    <h6 style={{fontSize:'12px',color:'red',fontWeight:'bold'}}>{t("Products.OutStock")}</h6>
                }
            </div>
            <div className='col-lg-6 col-12'>
              <div className="d-flex align-items-center mb-3 text-bold">
                  <Remove onClick={() => {decItem(item)}} style={{ cursor: "pointer" }}/>
                  <span style={Amount}>{uiQuantity}</span>
                  <Add onClick={() => incItem(item)} style={{ cursor: "pointer" }}/>
              </div>
              <h5 style={{color:'green'}}>{(item.price*item.quantity).toFixed(3)} {t("ConfirmOrder.OMR")}</h5>
            </div>
        </div>



      </div>
      { index + 1 === cartCount ? "" :
        <hr className="my-4" />
      }
    </>
  );
}

export default ShoppingCardItem;
