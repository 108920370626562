import "../../styles/userDetails.css";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCategoryById } from "../../../Network/appApis";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoImage from "../../../images/noImage.jpg";

function CategoryDetails() {

  const Navigate = useNavigate()
  const [category,setCategory] = useState({})
  let Params = useParams();
  
  useEffect(()=>{
    (async function(){
      try{
        const res = await getCategoryById(Params.id)
        setCategory(res.data.data[0])
      }catch(err){
        console.log(err)
      }
    })()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])  


    return (
    <>
    <div className="d-flex justify-content-center flex-wrap">
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="userdetails p-3 m-2" >
          <div className="d-flex justify-content-between">
            <h2 className="mb-3" style={{fontSize:'18px'}}><ArrowBackIosNewIcon style={{cursor:'pointer'}} onClick={()=>Navigate(-1)}/>  Information</h2>
            <Link to='editcategory' state={category}>
              <BorderColorIcon style={{cursor:'pointer'}}/>
            </Link>
          </div>
          <div className='d-flex flex-column align-items-center'>
            <img
                  src={category.image
                    ? category.image
                    : NoImage}
                  alt=""
                  className="userdetails-itemImg"
              />
              <h4>{category.name?.en}</h4>
              <h4>{category.name?.ar}</h4>
          </div>
          <div className='py-2 d-flex flex-column'>
          <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Category ID:</span>
                  <span>{category._id}</span>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Created At:</span>
                  <span>{new Date(category.createdAt).toDateString()}</span>
              </div>
          </div>
        </div>
      </div>
      </div>
    </> );
}

export default CategoryDetails;