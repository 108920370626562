import "../../styles/userDetails.css";
import Chart from "../../Components/chart";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProductById, lastSixMonthsEarningsForProduct } from "../../../Network/appApis";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoImage from "../../../images/noImage.jpg";

function ProductDetails() {
  const [product,setProduct] = useState({})
  const [productData,setProductData] = useState()
  let Params = useParams();
  const Navigate = useNavigate()

  useEffect(()=>{
    (async function(){
      try{
        const res = await getProductById(Params.id)
        setProduct(res.data.data[0])
      }catch(err){
        console.log(err)
      }
    })()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])  

 useEffect(()=>{
  (async function(){
    try{
      const res = await lastSixMonthsEarningsForProduct(Params.id)
      setProductData(res.data.data)
      console.log(res.data.data)
       }catch(err){
      console.log(err)
    }
  })()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

    return ( 
        <div className="d-flex justify-content-center flex-wrap">
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
          <div className="d-flex justify-content-between">
            <h2 className="mb-3" style={{fontSize:'18px'}}><ArrowBackIosNewIcon style={{cursor:'pointer'}} onClick={()=>Navigate(-1)}/>  Information</h2>
            <Link to='editproduct' state={product}>
              <BorderColorIcon style={{cursor:'pointer'}}/>
            </Link>
          </div>
          <div className='d-flex flex-column align-items-center'>
            <img
                  src={product?.image
                    ? product?.image
                    : NoImage}
                  alt=""
                  className="userdetails-itemImg"
              />
              <h5>{product?.name?.['en']}</h5>
              <h5>{product?.name?.['ar']}</h5>
          </div>
          <div className='py-2 d-flex flex-column'>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Product ID:</span>
                  <span>{product?._id}</span>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Price:</span>
                  <span>{product?.price}</span>
              </div>
              <div className="userdetails-detailItem">
                <span className="userdetails-itemKey">Stock:</span>
                  <span>
                    {product?.stock}
                  </span>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Status:</span>
                  {product?.status}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Category:</span>
                  {product?.category?.name?.['en']}
              </div>
              <div className="userdetails-detailItem d-flex">
                  <span className="userdetails-itemKey">Product Tags:</span>
                  <ul className="d-flex flex-wrap list-unstyled m-0">
                    {product?.product_tags?.length ? product?.product_tags?.map(element => {
                        return <li key={Math.random()} style={{marginLeft:'10px'}}>{element}</li>
                    }): ""}
                  </ul>
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">English Description:</span>
                  {product?.description?.['en']}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Arabic Description:</span>
                  {product?.description?.['ar']}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Created At:</span>
                  <span>{new Date(product?.createdAt).toDateString()}</span>
              </div>
          </div>
        </div>
      </div>
      <Chart data={productData} title="Total Delivered Product's Revnue Last 6 Months"/>
    </div>);
}

export default ProductDetails;