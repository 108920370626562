import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function NotAuth({path}) {
  const { t } = useTranslation(); // Hook to access translations

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div className="my-5 h-100 text-center">
          <img src="https://cdn-icons-png.flaticon.com/512/61/61457.png" alt='NOT AUTHENTICATED' height='100px' width="100px"/>
          <h5 className="my-3">
             {t("Auth.NoAuth")}<br /> {t("Auth.NoAuthMessage")}
          </h5>
          <Link
            to="/login"
            className="btn btn-dark"
            state={{ path }}
          >
            {t("Login.Login")}
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotAuth;
