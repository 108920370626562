import { SET_THEME } from '../Actions/types'


const INITIAL_STATE = {
    color:'light'
}

export function themeReducer(state=INITIAL_STATE,action){
    switch (action.type) {
        case SET_THEME:
            return{
                color: action.payload
            }

        default:
            return state
    }
}