import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GridViewIcon from '@mui/icons-material/GridView';
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import CategoryIcon from '@mui/icons-material/Category';
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { themeAction } from "../../store/Actions/themeAction";
import "../styles/sidebar.css";
import Swal from "sweetalert2";
import { Toast } from "../../helpers/sweetAlert";
import { logoutAction } from "../../store/Actions/isAuthAction";
import { Badge } from "@mui/material";
import lightLogo from '../../images/lightLogo.jpeg'
import darkLogo from '../../images/darkLogo.jpeg'
import Navbar from "react-bootstrap/Navbar";

function SideBar() {

  const dispatch = useDispatch()
  let theme = useSelector((state)=>state.theme.color)
  
  let iconClass = {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    marginLeft: "10px",
    color:'gray'
  };

  function setTheme(){
    dispatch(themeAction(theme === 'light' ? 'dark' : 'light'))
  }

  function logout(){
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Logout',
      text: `Are you sure you want to Logout?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Logout!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logoutAction())
        Toast('success','Successfully logged out !')
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
  }

  return (
    <>
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
          <span className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
              {
                theme === 'light' ?
                <img src={lightLogo} alt="MK-logo" width={50} height={50}/>
                :
                <img src={darkLogo} alt="MK-logo" width={50} height={50}/>
              }
          </span>
            <span className="">
              {
                theme === 'light' ?
                <DarkModeIcon onClick={setTheme} style={iconClass} />
                :
                <LightModeIcon onClick={setTheme} style={iconClass} /> 
              }
            </span>
          {/* <button
            className="navbar-toggler position-absolute d-md-none collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
        </header>

        <nav
          // id="sidebarMenu"
          className={`col-md-3 col-lg-2 d-md-block bg-${theme} sidebar`}
        >
          <Navbar collapseOnSelect expand="md">
          <Navbar.Toggle />
          <Navbar.Collapse>     
            <Nav
              className={`d-flex flex-column bg-${theme} p-1 w-100`}
              variant="pills"
            >
              <div className="flex-column mb-auto">
                <span style={{ fontSize: "12px", color: "grey" }}>MAIN</span>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/"
                    eventKey="link-1"
                  >
                    <DashboardIcon className="mx-1" />
                    Dashboard
                  </Nav.Link>
                </Nav.Item>

                <span style={{ fontSize: "12px", color: "grey" }}>LISTS</span>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/users"
                    eventKey="link-2"
                  >
                    <PersonIcon className="mx-1" /> Users
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/products"
                    eventKey="link-3"
                  >
                    <ShoppingBagIcon className="mx-1" /> Products
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/categories"
                    eventKey="link-4"
                  >
                    <CategoryIcon className="mx-1" /> Categories
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/orders"
                    eventKey="link-5"
                  >
                    <ListAltIcon className="mx-1" /> Orders
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/earnings"
                    eventKey="link-6"
                  >
                    <MonetizationOnOutlinedIcon className="mx-1" /> Earnings
                  </Nav.Link>
                </Nav.Item>

                <span style={{ fontSize: "12px", color: "grey" }}>SERVICE</span>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/general"
                    eventKey="link-11"
                  >
                      <GridViewIcon className="mx-1" />
                      General
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/notifications"
                    eventKey="link-7"
                  >
                    <Badge style={{position:"absolute", left:'33px', paddingTop:"10px"}} variant="dot" color="primary">
                    </Badge>
                      <NotificationsIcon className="mx-1" />
                      Notifications
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/setting"
                    eventKey="link-8"
                  >
                    <SettingsIcon className="mx-1" />
                    Setting
                  </Nav.Link>
                </Nav.Item>

                <span style={{ fontSize: "12px", color: "grey" }}>ACCOUNT</span>
                <Nav.Item>
                  <Nav.Link
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    to="/profile"
                    eventKey="link-9"
                  >
                    <AccountCircleIcon className="mx-1" />
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={logout}
                    className={`link-${theme === 'light' ? "dark" : 'light'} d-flex align-items-center`}
                    as={Link}
                    eventKey="link-10"
                  >
                    <LogoutIcon className="mx-1" />
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Navbar.Collapse>     
          </Navbar>
        </nav>
    </>
  );
}

export default SideBar;
