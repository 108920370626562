import { Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from 'react-router-dom';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteUserNotifications } from "../Network/appApis";
import { Toast } from "../helpers/sweetAlert";
import { useTranslation } from "react-i18next";

function NotificationCard({ item , removeElement}) {
  const userId = useSelector((state) => state.isAuth.user?._id);
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeNotification = () => {
    (async function(){
      try{
        const res = await deleteUserNotifications(item._id, userId)
        if(res.status === 200){
          Toast('success','Notification Removed')
          handleClose();
          removeElement(item._id)
        }
      }catch(err){
        Toast('error','Something Went Wrong')
        console.log(err)
      }
    })()
  };

  return (
    <>
    <div className="d-flex justify-content-between mx-5 text-secondary">
      {item.model==='order'?
        <Link to={`/order/${item.path}`} state={{orderById:item.path}} className='text-secondary' style={{textDecoration: 'none'}}>
          <div>
            <span style={{ fontSize: "11px" }}>{new Date(item.createdAt).toDateString()}</span>
              <br/>{item.title?.[lang]}
          </div>
        </Link>
        :
        <div>
        <span style={{ fontSize: "11px" }}>{new Date(item.createdAt).toDateString()}</span>
          <br/>{item.title?.[lang]}
      </div>
      }
      <div>
        <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                className="text-secondary text-decoration-none"
                onClick={() => removeNotification()}
              >
                <DeleteIcon className="mx-2" />
                {t("Profile.Delete")}
              </MenuItem>
            </Menu>
      </div>
    </div>  
      <hr className='mx-5'/>
    </>
  );
}

export default NotificationCard;
