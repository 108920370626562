import { Routes, Route } from 'react-router-dom';

import Login from "../Pages/Auth/Login";
import Home from "../Pages/Home";
import Register from "../Pages/Auth/Register";
import ProductList from "../Pages/Products/ProductList";
import About from "../Pages/About";
import ShoppingCart from '../Pages/Cart/shoppingCart';
import SetUserAddress from '../Pages/Cart/setUserAddress';
import Contact from "../Pages/Contact";
import NotFound from '../Pages/notFound';
import Profile from '../Pages/Profile/profile';
import ProductDetails from '../Pages/Products/ProductDetails';
import OrderDetails from '../Pages/Orders/orderDetails';
import AddAddress from '../Pages/Profile/addAddress'
import EditAddress from '../Pages/Profile/editAddress';
import OrderAccepted from '../Pages/Orders/orderAccepted';
import OrderRejected from '../Pages/Orders/orderRejected';
import ChoosePaymentMethod from '../Pages/Cart/choosePaymentMethod';

import Loader from "../Components/Loader"

import { Suspense } from 'react'
import Notification from '../Pages/Profile/notifications';
import ConfirmOrder from '../Pages/Orders/ConfirmOrder';

function Routing() {    
    
    return ( 
    <>
        <Suspense fallback={Loader}>
            <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/login' element={< Login />}></Route>
                <Route exact path='/register' element={< Register />}></Route>
                <Route exact path='/about' element={< About />}></Route>
                <Route exact path='/contact' element={< Contact />}></Route>
                
                <Route exact path='/shoppingcart' element={< ShoppingCart />}></Route> 
                <Route exact path='/shoppingcart/setUserAddress' element={< SetUserAddress />}></Route> 
                <Route exact path='/shoppingcart/choosePaymentMethod' element={< ChoosePaymentMethod />}></Route> 
                

                <Route exact path='/productList' element={< ProductList />}></Route>
                <Route exact path='/productdetails/:id' element={< ProductDetails />}></Route>
                
                <Route exact path='/addAddress' element={< AddAddress />}></Route> 
                <Route exact path='/editAddress' element={< EditAddress />}></Route> 

                <Route exact path='/profile' element={< Profile />}></Route> 
                <Route exact path='/notifications' element={< Notification />}></Route> 
                
                <Route exact path='/order/:id' element={< OrderDetails />}></Route> 
                <Route exact path='/order/orderAccepted' element={< OrderAccepted />}></Route>                
                <Route exact path='/order/orderRejected' element={< OrderRejected />}></Route>                
                <Route exact path='/order/confirmOrder' element={< ConfirmOrder />}></Route>                
                
                <Route exact path='*' element={< NotFound />}></Route>

            </Routes>
        </Suspense>
    </> );
}

export default Routing;
