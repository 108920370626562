import { useSelector } from "react-redux";
import ShoppingCartItem from "../../Components/shoppingCartItem";
import NoProductsShoppingCart from './noProductsShoppingCart'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getShippingPrice } from "../../Network/appApis";
import { useTranslation } from "react-i18next";

function ShoppingCart() {

  const cartProducts = useSelector((state)=>state.shoppingCart.products)
  const productsCount = useSelector((state)=>state.shoppingCart.count)
  const totalPrice = useSelector((state)=>state.shoppingCart.totalPrice)
  const [shippingPrice,setShippingPrice] = useState(0)
  const { t } = useTranslation(); // Hook to access translations

  useEffect(()=>{
    (async function(){
      try{
        const res = await getShippingPrice()
        setShippingPrice(
          res.data.data[0]?.shippingPrice?
          res.data.data[0]?.shippingPrice:
          0
          )
      }catch(err){
        console.log(err)
      }
    })()
  },[])

  return (
    <>{cartProducts.length === 0 ? <NoProductsShoppingCart/>:
      <div className="container py-5">
        <div className="d-flex justify-content-center flex-wrap my-3">
          <div className="col-md-8 col-12">
              <Card className="border border-secondary">
                  <CardContent>
                    {productsCount === 1 ?
                        <Typography gutterBottom variant="h5" component="div">
                          {t("Cart.Cart")} - {productsCount} {t("Cart.Item")} 
                        </Typography>
                        :
                        <Typography gutterBottom variant="h5" component="div">
                        {t("Cart.Cart")} - {productsCount} {t("Cart.Items")}
                        </Typography>
                        }
                        <hr/>
                      {cartProducts.map((item, index) => {
                            return <ShoppingCartItem item={item} cartCount={cartProducts.length} index={index} key={item._id} />;
                          })}

                  </CardContent>
              </Card>            
          </div>

          <div className="col-md-4 col-12">
          <Card className='mx-md-4 mt-3 mt-md-0 border border-secondary'>
                  <CardContent>
                        <Typography gutterBottom variant="h5" component="div" className='py-1'>
                        {t("Cart.Summary")}
                        </Typography>
                        <hr/>
                        <div>
                  <div className="d-flex justify-content-between align-items-center border-0 px-0 pb-0 m-2">
                    <span>{t("ConfirmOrder.ProductsPrice")}</span>
                    <span>{totalPrice ? (totalPrice).toFixed(3): totalPrice} {t("ConfirmOrder.OMR")}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center px-0 m-2">
                  {t("ConfirmOrder.ShippingPrice")}
                    <span>{totalPrice ? (shippingPrice).toFixed(3): totalPrice} {t("ConfirmOrder.OMR")}</span>
                  </div>
                  <hr className='mx-2'/>
                  <div className="d-flex justify-content-between align-items-center border-0 px-0 m-2">
                    <div>
                      <strong>{t("ConfirmOrder.TotalPrice")}</strong>
                    </div>
                    <span>
                      <strong>{(totalPrice + shippingPrice).toFixed(3)} {t("ConfirmOrder.OMR")}</strong>
                    </span>
                  </div>
                </div>
                <Link to='./setUserAddress' className="mt-3 btn btn-dark">
                  {t("PaymentMethod.ChoosePaymentMethod")} <ArrowRightIcon/>
                </Link>
                  </CardContent>
              </Card>
          </div>
        </div>
      </div>
}</>
  );
}

export default ShoppingCart;
