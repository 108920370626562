import { useSelector } from "react-redux";
import AddressForm from "../../Components/addressForm";
import NotAuth from "../../Components/notAuthenticated";
import { useTranslation } from 'react-i18next';

function SetUserAddress() {

  const isLogged = useSelector((state) => state.isAuth.isLogged);
  const user = useSelector((state) => state.isAuth.user?._id);
  const savedAddress = useSelector((state) => state.isAuth.user?.address);
  const { t } = useTranslation(); // Hook to access translations

  const values = {
    user,
    country: "",
    city: "",
    location: "",
    building: "",
    floor: "",
    apartment: "",
    phone: "",
    secondPhone: "",
    address: "",
  };


  return (
    <>
      {isLogged ? (
        <div className="container my-3">
          <div className="row">
                {
                  savedAddress?
                  <AddressForm
                    state={{...savedAddress,user,id:savedAddress._id}}
                    title={t("AddressForm.UpdateAddress")}
                    btnName={t("AddressForm.UpdateAddress")}
                    functionId="EDITADDRESS"
                  />
                  :
                  <AddressForm
                    state={values}
                    title={t("AddressForm.AddAddress")}
                    btnName={t("AddressForm.SaveContinue")}
                    functionId="ADDADDRESS"
                  />
                }
          </div>
        </div>
      ) : (
        <NotAuth path='/shoppingcart/setUserAddress'/>
      )}
    </>
  );
}

export default SetUserAddress;
