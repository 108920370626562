import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationCard from '../Components/notificationCard'
import { getUserNotifications } from '../../Network/appApis';


function Notification() {
    
    let userId = useSelector((state)=>state.isAuth.user?._id)
    let [allNotifications, setAllNotifications] = useState([])
    
    let removeElement = (id) => {
      setAllNotifications(allNotifications.filter((item)=>{
        return item._id !== id
      }))
     }

    useEffect(()=>{
        (async function(){
          try{
            const res = await getUserNotifications(userId)
            setAllNotifications(res.data.data.notifications)
          }catch(err){
            console.log(err)
          }
        })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    


    return ( 
    <>
    <div className='container'>    
      <div className="user-container d-flex justify-content-between align-items-center">
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>Notifications</h1> 
      </div>
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
        {
            allNotifications?.length ?
            allNotifications.slice(0).reverse().map((item)=>{
                return (
                  <NotificationCard item={item} removeElement={removeElement} key={item._id}/>
                  )
            })
            :
            <div>
                No Notifications 
            </div> 
        }
        </div>
    </div>  
    </>
     );
}

export default Notification;