import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react'

import Loader from "../Components/Loader"
import Dashboard from '../Pages/Dashboard';

import Orders from '../Pages/Orders/Orders';
import OrderDetails from '../Pages/Orders/orderDetails';

import Users from '../Pages/Users/Users';
import UserDetails from '../Pages/Users/userDetails';
import NewUser from '../Pages/Users/newUser';
import EditUser from '../Pages/Users/editUser';

import Products from '../Pages/Products/products';
import ProductDetails from '../Pages/Products/productDetails';
import NewProduct from '../Pages/Products/newProduct';
import EditProduct from '../Pages/Products/editProduct';

import Categories from '../Pages/Categories/Categories';
import NewCategory from '../Pages/Categories/newCaregory';
import CategoryDetails from '../Pages/Categories/categoryDetails';
import EditCategory from '../Pages/Categories/editCategory';

import NotFound from '../Pages/notFound';
import Setting from '../Pages/setting';
import Notification from '../Pages/notifications';
import Earnings from '../Pages/earning';
import Profile from '../Pages/profile';
import General from '../Pages/general';
import NewAboutUs from '../Pages/AboutUs/addAboutUs';
import UpdateAboutUs from '../Pages/AboutUs/updateAboutUs';


function AdminRouting() {
    return ( 
    <>
        <Suspense fallback={Loader}>
            <Routes>
                <Route exact path='/' element={< Dashboard />}></Route>
                
                <Route exact path='/users' element={< Users />}></Route>
                <Route exact path='/users/newuser' element={< NewUser />}></Route>
                <Route exact path='/users/:id' element={< UserDetails />}></Route>
                <Route exact path='/users/:id/edituser' element={< EditUser />}></Route>
                
                <Route exact path='/products' element={< Products />}></Route>
                <Route exact path='/products/newproduct' element={< NewProduct />}></Route>
                <Route exact path='/products/:id' element={< ProductDetails />}></Route>
                <Route exact path='/products/:id/editproduct' element={< EditProduct />}></Route>

                <Route exact path='/categories' element={< Categories />}></Route>
                <Route exact path='/categories/newcategory' element={< NewCategory />}></Route>
                <Route exact path='/categories/:id' element={< CategoryDetails />}></Route>
                <Route exact path='/categories/:id/editcategory' element={< EditCategory />}></Route>

                <Route exact path='/orders' element={< Orders />}></Route>
                <Route exact path='/orders/:id' element={< OrderDetails />}></Route>

                <Route exact path='/earnings' element={< Earnings />}></Route>

                <Route exact path='/setting' element={< Setting />}></Route>
                <Route exact path='/notifications' element={< Notification />}></Route>
                <Route exact path='/profile' element={< Profile />}></Route>
                <Route exact path='/general' element={< General />}></Route>

                <Route exact path='/general/addAboutUs' element={< NewAboutUs />}></Route>                
                <Route exact path='/general/updateAboutUs' element={< UpdateAboutUs />}></Route>                

                <Route exact path='*' element={< NotFound />}></Route>
            </Routes>
        </Suspense>
    </> );
}

export default AdminRouting;
