import { Formik } from "formik";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { addAboutUsMembers, updateAboutUsMembers, uploadSingle,} from "../../../Network/appApis";
import { Toast } from "../../../helpers/sweetAlert";
import { TextField } from "@mui/material";
import { useState } from "react";

function TeamMembersForm({ state, functionId, btnName, closeModal, refreshList }) {
  const [imgFile, setImgFile] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center mb-3">
        <div className="category-container text-center">
          {functionId === "ADDMEMBER" ? (
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>
              Add New Member
            </h1>
          ) : (
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>
              Edit Member
            </h1>
          )}
        </div>
      </div>

      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Name is Required";
          }
          if (!values.position) {
            errors.position = "Position is Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (functionId === "ADDMEMBER") {
            (async () => {
              try {
                let imgUrl = ''
                if(values.image){
                  const imgRes = await uploadSingle({image: values.image})
                  if(imgRes.status === 200){
                    imgUrl = imgRes.data.uploadedImagePath
                  }
                }

                const res = await addAboutUsMembers({...values, image: imgUrl});
                if (res.status === 200) {
                  Toast("success", `Member Added`);
                  closeModal();
                  refreshList(res.data.data)
                }
              } catch (err) {
                Toast("error", `Something Went Wrong`);
              }
            })();
          } else if (functionId === "EDITMEMBER") {
            (async () => {
              try {
                let imgUrl = values.image
                if(imgFile){
                  const imgRes = await uploadSingle({image: values.image})
                  if(imgRes.status === 200){
                    imgUrl = imgRes.data.uploadedImagePath
                  }
                }

                const res = await updateAboutUsMembers({...values, image: imgUrl});
                if (res.status === 200) {
                  Toast("success", `Member Updated`);
                  closeModal();
                  refreshList(res.data.data)
                }
              } catch (err) {
                Toast("error", `Something Went Wrong`);
              }
            })();
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center flex-column"
            >

              <div className="col-12">
                <div className="d-flex flex-column justify-content-center align-items-center my-3">
                  <img
                    width="100px"
                    height="100px"
                    style={{ borderRadius: "10px" }}
                    src={
                      imgFile
                        ? URL.createObjectURL(imgFile)
                        : values.image
                        ? values.image
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt=""
                  />
                  <label className="label-user" htmlFor="imgFile">
                    Upload Image{" "}
                    <DriveFolderUploadOutlinedIcon className="icon-user" />
                  </label>
                  <input
                    type="file"
                    id="imgFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                      setImgFile(event.target.files[0]);
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <PersonIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    name="name"
                    type="text"
                    size="small"
                    label="Member Name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.name && touched.name && errors.name}
                </span>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <WorkIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    name="position"
                    type="text"
                    size="small"
                    label="Member Position"
                    value={values.position}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.position && touched.position && errors.position}
                </span>
              </div>

              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <span
                    onClick={() => closeModal()}
                    className="btn btn-danger px-2 m-2"
                  >
                    Cancel
                  </span>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    {btnName}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
}

export default TeamMembersForm;
