import { AxisInstance } from "./axiosConfig";


//////////////////////////////////////////////// ACCOUNT ///////////////////////////////////////////////

//Change Password
export const changePassword = (body) => {
  return AxisInstance.post(
    `/api/changePassword`,
      body
    );
};


//////////////////////////////////////////////// CONTACT US ///////////////////////////////////////////////

//CONTACT US
export const sendMessage = (body) => {
  return AxisInstance.post(
    `/api/contactUs`,
      body
    );
};

//////////////////////////////////////////////// USERS ///////////////////////////////////////////////

//USERS APIS
export const getAllUsers = () => {
  return AxisInstance.get(
    `/api/users/`
    );
};

export const getUserById = (id) => {
  return AxisInstance.get(
    `/api/users/${id}`
  );
};

export const updateUserProfilePasswordConfirmation = (body) => {
  return AxisInstance.put(
    `/api/updateUserProfilePasswordConfirmation`,
    body
  );
};

//ADMINS APIS
export const addUser = (body) => {
  return AxisInstance.post(
    `/api/users`,
    body,
  );
};

export const updateUser = (body) => {
  return AxisInstance.put(
    `/api/users/`,
    body,
  );
};

export const deleteUser = (id) => {
  return AxisInstance.delete(
    `/api/users/${id}`,
  );
};


//////////////////////////////////////////////// PRODUCTS ///////////////////////////////////////////////

//USERS APIS
export const getAllProducts = (search,sort,filter,page) => {
  // ?filters=(CategoryId)&sort=(1 Asc or -1 Desc or date:default)&search=(string)&page=(number)
  return AxisInstance.get(
    `/api/products/?search=${search}&sort=${sort}&filters=${filter}&page=${page}`
    );
};

export const getNewArrivalProducts = () => {
  return AxisInstance.get(
    `/api/products/newArrival`
    );
};

export const getBestSellers = () => {
  return AxisInstance.get(
    `/api/products/getBestSellers`
    );
};

export const getProductById = (id) => {
  return AxisInstance.get(
    `/api/products/${id}`
  );
};

//ADMINS APIS
export const addProduct = (body) => {
  return AxisInstance.post(
    `/api/products/`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const updateProduct = (body) => {
  return AxisInstance.put(
    `/api/products/`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const deleteProduct = (id) => {
  return AxisInstance.delete(
    `/api/products/${id}`,
  );
};

//////////////////////////////////////////////// CATEGORIES ///////////////////////////////////////////////

//USERS APIS
export const getAllCategories = () => {
  return AxisInstance.get(
    `/api/category/`
    );
};

export const getCategoryById = (id) => {
  return AxisInstance.get(
    `/api/category/${id}`
  );
};

//ADMINS APIS
export const addCategory = (body) => {
  return AxisInstance.post(
    `/api/category/`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const updateCategory = (body) => {
  return AxisInstance.put(
    `/api/category/`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const deleteCategory = (id) => {
  return AxisInstance.delete(
    `/api/category/${id}`,
  );
};

//////////////////////////////////////////////// shippingPrice ///////////////////////////////////////////////

//USERS APIS
export const getShippingPrice = () => {
  return AxisInstance.get(
    `/api/shippingPrice/`
    );
};

//ADMINS APIS
export const addshippingPrice = (body) => {
  return AxisInstance.post(
    `/api/shippingPrice/`,
    body,
  );
};

export const updateShippingPrice = (body) => {
  return AxisInstance.put(
    `/api/shippingPrice/`,
    body,
  );
};

//////////////////////////////////////////////// Product Rating ///////////////////////////////////////////////

//USERS APIS
export const getUserRating = (userId) => {
  return AxisInstance.get(
    `/api/productRating/${userId}`
    );
};

export const addNewRating = (body) => {
  return AxisInstance.post(
    `/api/productRating`,
    body
  );
};

//////////////////////////////////////////////// Notification ///////////////////////////////////////////////

//USERS APIS
export const getUserNotifications = (userId) => {
  return AxisInstance.get(
    `/api/notifications/${userId}`
    );
};

export const deleteUserNotifications = (notificationId, userId) => {
  return AxisInstance.delete(
    `/api/notifications/${notificationId}/${userId}`
  );
};

//////////////////////////////////////////////// Orders ///////////////////////////////////////////////

//USERS APIS
export const getUserOrders = (userId) => {
  return AxisInstance.get(
    `/api/orders/${userId}`
    );
};

export const SSPaginationUserOrders = (userId, page) => {
  return AxisInstance.get(
    `/api/SSPaginationOrders/${userId}/${page}`
    );
};

export const addNewOrder = (body) => {
  return AxisInstance.post(
    `/api/orders`,
    body
  );
};

export const getOrderById = (orderId,userId) => {
  return AxisInstance.get(
    `/api/orders/${orderId}/${userId}/`,
    );
};

//ADMINS APIS
export const getAllOrders = () => {
  return AxisInstance.get(
    `/api/orders/`,
   );
};

export const updateOrderStatus = (orderId,body) => {
  return AxisInstance.put(
    `/api/orders/updateStatus/${orderId}`,
    body,
  );
};

// export const deleteOrder = (id) => {
//   return AxisInstance.delete(
//     `/api/deleteOrder/${id}`
//   );
// };

//////////////////////////////////////////////// ADDRESS ///////////////////////////////////////////////


export const addAddress = (body) => {
  return AxisInstance.post(
    `/api/address/`,
    body  
    );
};

export const updateAddress = (body) => {
  return AxisInstance.put(
    `/api/address/`,
    body  
    );
};

export const deleteAddress = (addressId, userId) => {
  return AxisInstance.delete(
    `/api/address/${addressId}/${userId}/`,
  );
};

//////////////////////////////////////////////// AUTH ///////////////////////////////////////////////

export const register = (body) => {
  return AxisInstance.post(
    `/api/register/`,
    {...body}
  );
};

export const login = (body) => {
  return AxisInstance.post(
    `/api/login/`,
    {...body}
  );
};

//////////////////////////////////////////////// Dashboard ///////////////////////////////////////////////

export const lastSixMonthsOrders = () => {
  return AxisInstance.get(
    `/api/dashboard/lastSixMonthsOrders`
  );
};

export const lastSixMonthsEarnings = () => {
  return AxisInstance.get(
    `/api/dashboard/lastSixMonthsEarnings`
  );
};

export const lastSixMonthsEarningsForUser = (userId) => {
  return AxisInstance.get(
    `/api/dashboard/lastUserSixMonthsEarnings/${userId}`
  );
};

export const lastSixMonthsEarningsForProduct = (productId) => {
  return AxisInstance.get(
    `/api/dashboard/lastProductSixMonthsEarnings/${productId}`
  );
};

export const todaysOrder = () => {
  return AxisInstance.get(
    `/api/dashboard/todaysOrder`
  );
};

export const adminCards = () => {
  return AxisInstance.get(
    `/api/dashboard/Cards`
  );
};

export const bestSellersEarning = () => {
  return AxisInstance.get(
    `/api/dashboard/bestSellersEarning`
  );
};

//////////////////////////////////////////////// GENERAL ///////////////////////////////////////////////

export const getAllGeneral = () => {
  return AxisInstance.get(
    `/api/getAllGeneral`
  );
};

export const updateSocialMediaLinkes = (body) => {
  return AxisInstance.put(
    `/api/updateSocialMediaLinkes`,
    body
  );
};

export const updateContact = (body) => {
  return AxisInstance.put(
    `/api/updateContact`,
    body
  );
};

export const addAboutUsMembers = (body) => {
  return AxisInstance.put(
    `/api/addAboutUsMembers`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const updateAboutUsMembers = (body) => {
  return AxisInstance.put(
    `/api/updateAboutUsMembers`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const removeAboutUsMembers = (id) => {
  return AxisInstance.put(
    `/api/removeAboutUsMembers/${id}`
  );
};


export const addAboutUs = (body) => {
  return AxisInstance.put(
    `/api/addAboutUs`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const updateAboutUs = (body) => {
  return AxisInstance.put(
    `/api/updateAboutUs`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const removeAboutUs = (id) => {
  return AxisInstance.put(
    `/api/removeAboutUs/${id}`
  );
};

//IMAGE SLIDER
export const updateHomeSliderImages = (body) => {
  return AxisInstance.put(
    `/api/updateHomeSliderImages/`,
    body,
    // {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const getHomeSliderImages = () => {
  return AxisInstance.get(
    `/api/getHomeSliderImages/`
    );
};

////////////////////////////////// FOOTER/////////////////////

export const getFooterInfo = () => {
  return AxisInstance.get(
    `/api/getFooterInfo/`
    );
};

////////////////////////////////// Contact Info /////////////////////

export const getContact = () => {
  return AxisInstance.get(
    `/api/getContact/`
    );
};

////////////////////////////////// About Us Info /////////////////////

export const getAboutUsInfo = () => {
  return AxisInstance.get(
    `/api/getAboutUsInfo/`
    );
};

//////////////////////////////////////////////// Gateway ///////////////////////////////////////////////

export const createSession = (body) => {
  return AxisInstance.post(
    `/api/orders/createSession`,
    body
  );
};

export const payThawani = (orderId, lang) => {
  return AxisInstance.get(
    `/api/gateway/payThawani/${orderId}/${lang}`
  );
};

//////////////////////////////////////////////// IMAGES ///////////////////////////////////////////////

export const uploadSingle = (body) => {
  return AxisInstance.post(
    `/api/uploadSingle`,
    body,
    {headers:{'Content-Type':'multipart/form-data'}}
  );
};

export const uploadMulti = (body) => {
  return AxisInstance.post(
    `/api/uploadMulti`,
    body,
    {headers:{'Content-Type':'multipart/form-data'}}
  );
};