import {shoppingCartReducer} from './shoppingCartReducer'
import {isAuthReducer} from './isAuthReducer'
import {themeReducer} from './themeReducer'
import { combineReducers } from "@reduxjs/toolkit";
import { langReducer } from "./langReducer"

// export default combineReducers({
//     shoppingCart: shoppingCartReducer,
//     isAuth : isAuthReducer,
//     theme: themeReducer
// })

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({ 
      shoppingCart: shoppingCartReducer,
      isAuth : isAuthReducer,
      theme: themeReducer, 
      lang: langReducer
    });

export const persistedReducer = persistReducer(persistConfig, rootReducer);

