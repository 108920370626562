import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik } from "formik";
import { changePassword } from "../../Network/appApis";
import { Toast } from "../../helpers/sweetAlert";
import LockIcon from "@mui/icons-material/Lock";


function ChangePasswordForm({ state, parentCallback }) {
 
  const [passwordShown, setPasswordShown] = useState(false)
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

  return (
    <>
      <div className="d-flex justify-content-center mb-3">
      <div className="category-container text-center">
          <h1 style={{ color: "lightgray", fontSize: "30px" }}>New Password</h1>
      </div>
      </div>
      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Your Password is Required";
          }
          
          if (!values.newPassword) {
            errors.newPassword = "Your New Password is Required";
          }else if (values.newPassword.length <8){
            errors.newPassword = "Minimum 8 Characters Required";
          }

          if (!values.confirmNewPassword) {
            errors.confirmNewPassword = "Password Confirmation is Required";
          }else if (values.confirmNewPassword !== values.newPassword){
            errors.confirmNewPassword = "Password Not Identical";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
           
            (async ()=>{
              try {
                parentCallback()
                const res = await changePassword(values)
                if (res.status === 200) {
                  Toast(
                    "success",`Password Successfully Updated`
                  );
                }
              } catch (err) {
                Toast("error", `${err.response.data.Error}`);
              }                  
            })()
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center flex-column"
            >
                <div className="input-group d-flex flex-row align-items-center mb-1">
                    <LockIcon className="m-1" />
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span 
                          onClick={()=>setPasswordShown(!passwordShown)}
                          style={{cursor:'pointer'}}
                          className="input-group-text">
                          {passwordShown ? <VisibilityOff/> : <Visibility/>}
                        </span>
                  </div>
                    <span style={{ color: "red",marginLeft:'10px' }} className="p-1 text-bold">
                        {errors.password && touched.password && errors.password}
                    </span>

              <div className="input-group d-flex flex-row align-items-center mb-1">
                    <LockIcon className="m-1" />
                  <input
                    type={newPasswordShown ? "text" : "password"}
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  <span 
                          onClick={()=>setNewPasswordShown(!newPasswordShown)}
                          style={{cursor:'pointer'}}
                          className="input-group-text">
                          {newPasswordShown ? <VisibilityOff/> : <Visibility/>}
                  </span>
                </div>
                <span
                  style={{ color: "red", marginLeft: "10px" }}
                  className="p-1 text-bold"
                >
                  {errors.newPassword && touched.newPassword && errors.newPassword}
                </span>
             
              <div className="input-group d-flex flex-row align-items-center mb-1">
                  <LockIcon className="m-1" />
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmNewPassword"
                    className="form-control"
                    placeholder="Confirm New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                  />
                  <span 
                          onClick={()=>setConfirmPasswordShown(!confirmPasswordShown)}
                          style={{cursor:'pointer'}}
                          className="input-group-text">
                          {confirmPasswordShown ? <VisibilityOff/> : <Visibility/>}
                  </span>
                </div>
                
                <span
                  style={{ color: "red", marginLeft: "10px" }}
                  className="p-1 text-bold"
                >
                  {errors.confirmNewPassword && touched.confirmNewPassword && errors.confirmNewPassword}
                </span>
              
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-danger px-2 m-2"
                    onClick={()=>{parentCallback()}}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    Update Password
                  </button>
                </div>
            </form>
           </div>
        )}
      </Formik>
    </>
  );
}

export default ChangePasswordForm;
