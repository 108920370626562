import AboutUsForm from "../../Components/general/aboutUsForm";

function NewAboutUs() {

  const values ={
    title:{
      en:"",
      ar:"",
    },
    image:"",
    description:{
      en:"",
      ar:"",
    },
  }

  return (
    <>
      <AboutUsForm state={values} functionId = 'ADDABOUTUS' btnName ="Add Section" />
    </>
  );
}

export default NewAboutUs;
