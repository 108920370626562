import SideBar from "../Components/Sidebar";
import AdminRouting from "../Router/router";

function IndexAdmin() {
  return (
    <>
      <div>
        <SideBar />
        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <AdminRouting />
        </main>
      </div>
    </>
  );
}

export default IndexAdmin;
