import { styled } from '@mui/material/styles';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from "@mui/icons-material/Delete";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TeamMembersForm from './teamMembersForm';
import Swal from 'sweetalert2';
import { removeAboutUsMembers } from '../../../Network/appApis';
import { Toast } from '../../../helpers/sweetAlert';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TeamMembers({list, parentCallback}) {

  const [memberList, setMemberList] = useState(list);
  const [openAddMembersModal, setOpenAddMembersModal] = useState(false);
  const [openEditMembersModal, setOpenEditMembersModal] = useState(false);
  const [targetMember, setTargetMember] = useState({});
 
  const refreshList = (data) =>{
    setMemberList(data)
    parentCallback(data)
  }

  function deleteMember(row){
    Swal.mixin({
      toast: true,
        customClass: {
        confirmButton: 'btn btn-danger m-2',
        cancelButton: 'btn btn-outline-dark m-2'
      },
      buttonsStyling: false
    }).fire({
      title: 'Delete Member',
      text: `Are you sure you want to Delete ${row.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        (async ()=>{
          try {
            const res = await removeAboutUsMembers(row._id)
            if (res.status === 200) {
              Toast("success",`Member Successfully Deleted`);
              setMemberList(res.data.data)
              parentCallback(res.data.data)
            }
          } catch (err) {
            Toast("error","Something Went Wrong");
          }                  
        })()
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Toast('error','Cancelled!')
      }
    })
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  return ( 
    <>
      <div className="d-flex flex-wrap justify-content-around mx-5 my-2">
        <div className='d-flex w-100 justify-content-between my-2'>
            <h2>All Members</h2>
            <button className='btn btn-outline-dark' onClick={()=>{setOpenAddMembersModal(true)}}>Add New Member</button>
        </div>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>#Id</StyledTableCell>
            <StyledTableCell align="right">Image</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Position</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberList.map((row) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {row._id}
              </StyledTableCell>
              <StyledTableCell align="right">
              <img
                  style={{width:"50px", height:"50px"}}
                  src={row.image}
                  alt=""
                  className="userdetails-itemImg"
              />
              </StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.position}</StyledTableCell>
              <StyledTableCell align="right">
                <BorderColorIcon onClick={()=>{
                  setOpenEditMembersModal(true)
                  setTargetMember(row)
                }} className='m-2' style={{cursor:'pointer'}}/>|
                <DeleteIcon onClick={()=>deleteMember(row)} className='m-2' style={{cursor:'pointer'}}/>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          <div className='d-flex justify-content-center w-100'>
            {memberList.length === 0 && <h3>NO DATA FOUND</h3>}
          </div>
  </div>
    
    
  <Modal
      open={openAddMembersModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
          <div className='p-1'>
          
          <TeamMembersForm 
            state = {{
              name:"",
              image:"",
              position:""
            }}
            closeModal ={()=>setOpenAddMembersModal(false)}
            functionId = 'ADDMEMBER'
            btnName ="Add Member"
            refreshList = {refreshList}
          />
          </div>       
      </Box>
    </Modal>

    <Modal
      open={openEditMembersModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
          <div className='p-1'>
          
          <TeamMembersForm 
            state = {targetMember}
            closeModal={()=>setOpenEditMembersModal(false)}
            functionId = 'EDITMEMBER'
            btnName ="Edit Member"
            refreshList = {refreshList}
          />
            </div>       
      </Box>
    </Modal>
    </> 
    );
}

export default TeamMembers;