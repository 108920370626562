import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import Person3Icon from '@mui/icons-material/Person3';
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import { Toast } from "../helpers/sweetAlert";
import { useState } from "react";
import { updateUserProfilePasswordConfirmation } from "../Network/appApis";
import { editProfileAction } from "../store/Actions/isAuthAction";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

function ChangeUserForm({ state, parentCallback }) {
  const dispatch = useDispatch()
  const [passwordShown, setPasswordShown] = useState(false)
  const { t } = useTranslation(); // Hook to access translations

  return (
    <>
      <h4 className='mx-4 my-2'>{t("Profile.UpdateProfile")}</h4>
      
      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = t("Register.FirstNameValidation");
          }
          
          if (!values.lastName) {
            errors.lastName = t("Register.lastNameValidation");
          }
          
          if (!values.email) {
            errors.email = t('Login.EmailRequired');
          }else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = t("Login.InvalidEmail");
          }
          
          if (!values.password) {
            errors.password = t("Login.PasswordRequired")
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
           
            (async ()=>{
              try {
                parentCallback()
                const res = await updateUserProfilePasswordConfirmation(values)
                if (res.status === 201) {
                  dispatch(editProfileAction(res.data.others))
                  Toast(
                    "success",`Profile Successfully Updated`
                  );
                }
              } catch (err) {
                Toast("error", `${err.response.data.Error}`);
              }                  
            })()
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center flex-column"
            >
                <div className="input-group d-flex flex-row align-items-center mb-1">
                    <PersonIcon className="m-1" />
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder={t("Register.FirstName")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                  </div>
                    <span style={{ color: "red",marginLeft:'10px' }} className="p-1 text-bold">
                        {errors.firstName && touched.firstName && errors.firstName}
                    </span>

              <div className="input-group d-flex flex-row align-items-center mb-1">
                    <Person3Icon className="m-1" />
                  <input
                    type="text"
                    name="lastName"
                    className="form-control"
                    placeholder={t("Register.lastName")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  </div>
                <span
                  style={{ color: "red", marginLeft: "10px" }}
                  className="p-1 text-bold"
                >
                  {errors.lastName && touched.lastName && errors.lastName}
                </span>
             
              <div className="input-group d-flex flex-row align-items-center mb-1">
                  <EmailIcon className="m-1" />
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={t("Login.email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                
                <span
                  style={{ color: "red", marginLeft: "10px" }}
                  className="p-1 text-bold"
                >
                  {errors.email && touched.email && errors.email}
                </span>

                <div className="input-group d-flex flex-row align-items-center mb-1">
                    <LockIcon className="m-1" />
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder={t("Profile.EnterPsw")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <span 
                          onClick={()=>setPasswordShown(!passwordShown)}
                          style={{cursor:'pointer'}}
                          className="input-group-text">
                          {passwordShown ? <VisibilityOff/> : <Visibility/>}
                        </span>
                  </div>
                    <span style={{ color: "red",marginLeft:'10px' }} className="p-1 text-bold">
                        {errors.password && touched.password && errors.password}
                    </span>
              
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-danger px-2 m-2"
                    onClick={()=>{parentCallback()}}
                  >
                    {t("General.Cancel")}
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    {t("Profile.UpdateProfile")}
                  </button>
                </div>
            </form>
           </div>
        )}
      </Formik>
    </>
  );
}

export default ChangeUserForm;
