import { useTranslation } from "react-i18next";
import NoImage from "../images/noImage.jpg";
import { useSelector } from "react-redux";

function SubmitOrderProductItem({item}) {
  let lang = useSelector((state)=>state.lang)
  const { t } = useTranslation(); // Hook to access translations

  return (
    <>  
      <div className="row col-12 text-center">
        <div className="col-4 mb-4 mb-lg-0">
          <img 
              height='100px'
              width='100px'
              src={item.image
                ? item.image
                : NoImage}
              alt={item.name}
            />
        </div>

        <div className="col-8 mb-4 mb-lg-0 d-flex justify-content-center align-items-center flex-column flex-sm-row">
          <div className="d-flex flex-column col-md-4 col-8">
            <h6>{item.name?.[lang]}</h6>
          </div>
          <div className="d-flex flex-column col-md-4 col-8">
              <h6>{item.quantity} {t("Cart.Items")}</h6>
              <h6 style={{color:'green'}}>{(item.price*item.quantity).toFixed(3)} {t("ConfirmOrder.OMR")}</h6>
          </div>
        </div>
          <hr className="my-4" />
    </div>

    </>
  );
}

export default SubmitOrderProductItem;
