import { CHANGE_LANG } from "../Actions/types";

const INITIAL_STATE = "en"

export function langReducer(state=INITIAL_STATE, action){
    switch (action.type) {

        case CHANGE_LANG:
            localStorage.setItem("lang", state === "ar" ? "en" : "ar")
            return state === "ar" ? "en" : "ar"    
        default:
            return state
    }
}