import { Formik } from "formik";
import DescriptionIcon from '@mui/icons-material/Description';
import TitleIcon from '@mui/icons-material/Title';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { addAboutUs, updateAboutUs, uploadSingle} from "../../../Network/appApis";
import { Toast } from "../../../helpers/sweetAlert";
import { TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AboutUsForm({ state, functionId, btnName }) {
  const [imgFile, setImgFile] = useState("");
  const Navigate = useNavigate()

  return (
    <>
      <div className="d-flex justify-content-center w-100 mb-3">
        <div className="category-container text-center w-100">
          {functionId === "ADDABOUTUS" ? (
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>
              Add Section
            </h1>
          ) : (
            <h1 style={{ color: "lightgray", fontSize: "30px" }}>
              Edit Section
            </h1>
          )}
        </div>
      </div>

      <Formik
        initialValues={state}
        validate={(values) => {
          const errors = {};
          if (!values.title.ar) {
            errors.titleAr = "Arabic Title is Required";
          }
          if (!values.title.en) {
            errors.titleEn = "English Title is Required";
          }
          if (!values.description.ar) {
            errors.descriptionAr = "Arabic Description is Required";
          }
          if (!values.description.en) {
            errors.descriptionEn = "English Description is Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (functionId === "ADDABOUTUS") {
            (async () => {
              try {
                let imgUrl = ''
                if(values.image){
                  const imgRes = await uploadSingle({image: values.image})
                  if(imgRes.status === 200){
                    imgUrl = imgRes.data.uploadedImagePath
                  }
                }

                const res = await addAboutUs({...values, image: imgUrl});
                if (res.status === 200) {
                  Toast("success", `Section Added`);
                  Navigate(-1)
                }
              } catch (err) {
                Toast("error", `Something Went Wrong`);
              }
            })();
          } else if (functionId === "EDITABOUTUS") {
            (async () => {
              try {
                let imgUrl = values.image
                if(imgFile){
                  const imgRes = await uploadSingle({image: values.image})
                  if(imgRes.status === 200){
                    imgUrl = imgRes.data.uploadedImagePath
                  }
                }
                
                const res = await updateAboutUs({...values, image: imgUrl});
                if (res.status === 200) {
                  Toast("success", `Section Updated`);
                  Navigate(-1)
                }
              } catch (err) {
                Toast("error", `Something Went Wrong`);
              }
            })();
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <div>
            <form
              onSubmit={handleSubmit}
              className="d-flex flex-wrap justify-content-around align-items-center"
            >
              <div className="col-12 col-lg-4">
                <div className="d-flex flex-column justify-content-center align-items-center my-3">
                  <img
                    width="200px"
                    height="200px"
                    style={{ borderRadius: "10px" }}
                    src={
                      imgFile
                        ? URL.createObjectURL(imgFile)
                        : values.image
                        ? values.image
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt=""
                  />
                  <label className="label-user" htmlFor="imgFile">
                    Upload Image{" "}
                    <DriveFolderUploadOutlinedIcon className="icon-user" />
                  </label>
                  <input
                    type="file"
                    id="imgFile"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                      setImgFile(event.target.files[0]);
                    }}
                  />
                </div>
              </div>

            <div className="col-12 col-lg-8">
              <div className="col-12 col-lg-6">
                <div className="d-flex align-items-center mb-1">
                  <TitleIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    name="title.en"
                    type="text"
                    size="small"
                    label="English Title"
                    value={values.title.en}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.titleEn && touched.title?.en && errors.titleEn}
                </span>
              </div>

              <div className="col-12 col-lg-6">
                <div className="d-flex align-items-center mb-1">
                  <TitleIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    name="title.ar"
                    type="text"
                    size="small"
                    label="Arabic Title"
                    value={values.title.ar}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.titleAr && touched.title?.ar && errors.titleAr}
                </span>
              </div>
  

              <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <DescriptionIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    name="description.en"
                    type="text"
                    size="small"
                    label="English Description"
                    value={values.description.en}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.descriptionEn && touched.description?.en && errors.descriptionEn}
                </span>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center mb-1">
                  <DescriptionIcon
                    style={{ width: "30px", height: "30px" }}
                  />
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    name="description.ar"
                    type="text"
                    size="small"
                    label="Arabic Description"
                    value={values.description.ar}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "30px" }}
                  className="p-1 text-bold"
                >
                  {errors.descriptionAr && touched.description?.ar && errors.descriptionAr}
                </span>
              </div>
            </div>

              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <span
                    onClick={()=>Navigate(-1)}
                    className="btn btn-danger px-2 m-2"
                  >
                    Cancel
                  </span>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className=" btn btn-success px-2 m-2"
                  >
                    {btnName}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
}

export default AboutUsForm;
