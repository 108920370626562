import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckIcon from "@mui/icons-material/Check";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTranslation } from 'react-i18next';


export default function OrderStepper({activeStep,orderStatus}) {
  
  const ColorlibConnectorSuccess = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
        orderStatus === "Rejected" ? 'red' : '#00ab41',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:
        orderStatus === "Rejected" ? 'red' : '#00ab41',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor:
      orderStatus === "Rejected" ? 'red' : '#00ab41',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor:
      orderStatus === "Rejected" ? 'red' : '#00ab41',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <CardGiftcardIcon />,
      2: orderStatus === "Rejected" ? <CancelIcon /> : <CheckIcon />,
      3: <LocalShippingIcon />,
      4: <DoneAllIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  const { t } = useTranslation(); // Hook to access translations
  const steps = [t("Stepper.Placed"), orderStatus === 'Rejected' ? t("Stepper.Rejected") : t("Stepper.Accepted") ,t("Stepper.Shipped"), t("Stepper.Delivered")];
  
  return (
    <Stack className='mt-3 w-100'>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnectorSuccess />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}