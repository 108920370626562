import CategoryForm from "../../Components/categoryForm";

function NewCategory() {

  const values ={
    name: {
      en: "",
      ar: "",
    },
    image:null
  }

  return (
    <>
      <CategoryForm state={values} functionId='ADDCATEGORY' btnName="Add New Category" />
    </>
  );
}

export default NewCategory;
