import ProductForm from "../../Components/productForm";
import { useLocation } from 'react-router-dom'

function EditProduct() {

    const location = useLocation()
    let values = location.state
    values.id = values._id

    return ( 
    <>
      <ProductForm state={values} functionId='EDITPRODUCT' btnName="Update Product" />
    </> );
}

export default EditProduct;