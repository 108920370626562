import ProductCard from "../../Components/productCard";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getAllCategories, getAllProducts } from "../../Network/appApis";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ProductList() {

  let handlePagenation = (e,p) =>{
    setPage(p);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  
    const { t } = useTranslation(); // Hook to access translations
    const [allProducts,setAllProducts] = useState([]) 
    const location = useLocation()
    const [filters, setFilters] = useState(location.state?.category ? location.state.category : '');
    const [sort, setSort] = useState('date');
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const searchKeyword = location.state?.searchKeyword ? location.state.searchKeyword : ''
    const [allCategories,setAllCategories] = useState([])
    let lang = useSelector((state)=>state.lang)

      const handleChange = (event) => {
        if(event.target.name === 'filters'){
            setFilters(event.target.value);
        }else if(event.target.name === 'sort'){
            setSort(event.target.value);
        }}

        useEffect(()=>{
          (async function(){
            try{
              const res = await getAllProducts(searchKeyword,sort,filters,page)
              setAllProducts(res.data.data)
              setPagesCount(Math.ceil(res.data.count/20))
             }catch(err){
              console.log(err)
            }
          })()
       },[filters,sort,searchKeyword,page])  

       useEffect(()=>{
        (async function(){
          try{
            const res = await getAllCategories()
            setAllCategories(res.data.data)
          }catch(err){
            console.log(err)
          }
        })()
      },[])
    
    return (
    <div className='container'>  
    <div className=" d-flex flex-wrap justify-content-between align-items-center my-3">
      <FormControl variant="filled" className='col-5 col-md-3'>
        <InputLabel id="demo-simple-select-filled-label">{t("Products.CatFilters")}</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={filters}
          name='filters'
          onChange={handleChange}
        >
          <MenuItem  value=''>{t("Products.AllCats")}</MenuItem>
          {allCategories.length?
          allCategories.map((category)=>{
            return(
              <MenuItem key={category._id} value={category._id}>{category.name?.[lang]}</MenuItem>
            )
          })
          :
          <MenuItem disabled value=''>{t("Home.CommingSoon")}</MenuItem>
          }
          </Select>
      </FormControl>
      <FormControl variant="filled" className='col-5 col-md-3'>
        <InputLabel id="demo-simple-select-filled-label">{t("Products.Sort")}</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={sort}
          name='sort'
          onChange={handleChange}
        >
          <MenuItem value='date'>{t("Products.Newest")}</MenuItem>
          <MenuItem value='-1'>{t("Products.HighPrice")}</MenuItem>
          <MenuItem value='1'>{t("Products.LowPrice")}</MenuItem>
        </Select>
      </FormControl>
    </div>
    <div className="d-flex flex-wrap my-2 justify-content-center">
        {
            allProducts.length ? 
            allProducts.map((item)=>{
              return(
                <>
                  <ProductCard item={item} key={item._id}/>
                </>
                    )
            })
            :<div className="text-center h-25">
                No Products Found <br/> Try to change or reset your search keyword
              </div>
        }
    </div>
  
    <div className="my-3 w-100 d-flex justify-content-center">
      <Pagination count={pagesCount} onChange={handlePagenation}/>
    </div>
    </div> 
    );
}

export default ProductList;