import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderById, updateOrderStatus } from "../../../Network/appApis";
import Table from 'react-bootstrap/Table';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Toast } from '../../../helpers/sweetAlert';
import Swal from 'sweetalert2';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoImage from '../../../images/noImage.jpg'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function OrderDetails() {

    let Params = useParams();
    const Navigate = useNavigate()
    const [order,setOrder] = useState({})
    const [openModal, setOpenModal] = useState(false);  
    const [statusValue, setStatusValue] = useState('');  

    useEffect(()=>{
        (async function(){
          try{
            const res = await getOrderById(Params.id)
            setOrder(res.data.data)
            setStatusValue(res.data.data.status)
          }catch(err){
            console.log(err)
          }
        })()
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[])  
    
      const changeStatus = () => {
        setOpenModal(false)
        Swal.mixin({
          toast: true,
            customClass: {
            confirmButton: 'btn btn-success m-2',
            cancelButton: 'btn btn-outline-dark m-2'
          },
          buttonsStyling: false
        }).fire({
          title: 'Update Status?',
          text: `Are you sure you want to Update status to ${statusValue}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Update!',
          cancelButtonText: 'No, cancel!',
        }).then((result) => {
          if (result.isConfirmed) {
            (async function(){
              try{
                const res = await updateOrderStatus(order._id,{status:statusValue})
                if(res.status === 200){
                  setStatusValue(res.data.order.status)
                  setOrder({
                    ...order,
                    status:res.data.order.status,
                    paymentStatus:res.data.order.paymentStatus
                  })
                  // setOrder(res.data?.order)
                  Toast('success','Status Changed!')
                }
              }catch(err){
                Toast('error','Something Went Wrong!')
                console.log(err)
              }
            })()
          }else if (
            result.dismiss === Swal.DismissReason.cancel
          ) {
            Toast('error','Cancelled!')
          }
      })
      }
          
    return (
    <>
    <div className='d-flex flex-wrap'>
        <div className="col-12 col-sm-12 col-md-6">
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
        <div className="d-flex justify-content-between">
            <h2 className="mb-3" style={{fontSize:'18px'}}><ArrowBackIosNewIcon style={{cursor:'pointer'}} onClick={()=>Navigate(-1)}/>  Order Information</h2>
              <BorderColorIcon style={{cursor:'pointer'}} onClick={() => setOpenModal(true)}/>
              <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                    <div className='p-1'>
                    <h5>Order Status</h5>
                    <div className="d-flex align-items-center my-3">
                        <QuestionMarkIcon className="m-1" />
                        <select
                          name="status"
                          className="form-control"
                          onChange={(e)=>{
                            setStatusValue(e.target.value)}
                          }
                          value={statusValue}
                        >
                          <option className='m-1' value="" disabled>
                            Change Status
                          </option>
                          <option value="Placed">Placed (Default)</option>
                          <option value='Accepted'>Accepted</option>
                          <option value='Rejected'>Rejected</option>
                          <option value='Shipped'>Shipped</option>
                          <option value='Delivered'>Delivered</option>
                        </select>
                      </div>
                    
                        <div className='d-flex justify-content-center'>
                          <button className='btn btn-danger mx-2' 
                          onClick={() =>{setOpenModal(false);
                                    setStatusValue(order?.status)}}>Cancel</button>
                          <button className='btn btn-success mx-2' onClick={() => changeStatus()}>Change</button>
                          </div>
                      </div>
                </Box>
              </Modal>
          </div>  
          <div className='py-2 d-flex flex-column'>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Order ID:</span>
                  {order._id}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Order Number:</span>
                  #{order.id}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Payment Method:</span>
                  {order.paymentMethod}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Payment Status:</span>
                  {order.paymentStatus}
              </div>
              <div className="userdetails-detailItem">
                <span className="userdetails-itemKey">Products Price:</span>
                  {order.productsPrice}
              </div>
              <div className="userdetails-detailItem d-flex">
                  <span className="userdetails-itemKey">Shipping Price:</span>
                  {order.shippingPrice}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Total Price:</span>
                  {order.totalPrice}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Status:</span>
                  {order.status}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Created At:</span>
                  {new Date(order.createdAt).toDateString()}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <div className="userdetails p-3 m-2" style={{ minHeight: "500px" }}>
            <h2 className="mb-3" style={{fontSize:'18px'}}>User Information</h2>
          <div className='py-2 d-flex flex-column'>
          <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">User ID:</span>
                  {order.user?._id}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">User Email:</span>
                  {order.user?.email}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Country:</span>
                  {order.address?.country}
              </div>
              <div className="userdetails-detailItem">
                <span className="userdetails-itemKey">City:</span>
                {order.address?.city}
              </div>
              <div className="userdetails-detailItem d-flex">
                  <span className="userdetails-itemKey">Location:</span>
                  {order.address?.location}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Building:</span>
                  {order.address?.building}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Floor:</span>
                  {order.address?.floor}
              </div>
              <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Apartment:</span>
                  {order.address?.apartment}
            </div>
            <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Phone Number:</span>
                  {order.address?.phoneNumber}
            </div>
            <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Additional Number:</span>
                  {order.address?.secondaryPhoneNumber}
            </div>
            <div className="userdetails-detailItem">
                  <span className="userdetails-itemKey">Full Address:</span>
                  {order.address?.address}
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className='col-12 userdetails py-4 overflow-auto'>

    <Table striped bordered className='text-center'>
      <thead>
        <tr>
          <th>#ID</th>
          <th>Product Name</th>
          <th>Product Image</th>
          <th>Quantity</th>
          <th>Product Price</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {order.cartProducts?.map((item)=>{
            return(
            <tr key={item._id?._id}>
            <td style={{cursor:'pointer'}} onClick={()=>{Navigate(`/products/${item._id?._id}`)}}>
                    {item._id?._id}
            </td>
            <td>{item.name?.['en']}</td>
            <td>
                <img 
                src={item._id?.image
                  ? item._id?.image
                  : NoImage}
                alt={item.name?.['en']}
                width='50'
                height='50'
            /></td>
            <td>{item?.quantity}</td>
            <td>{item.price}</td>
            <td>{item?.quantity * item.price}</td>
            </tr>
            )
        })}
        <tr>
          <th colSpan='5'>All Products Price</th>
          <th>{order?.productsPrice} OMR</th>
        </tr>
        <tr>
          <th colSpan='5'>Shipping Price</th>
          <th>{order?.shippingPrice} OMR</th>
        </tr><tr>
          <th colSpan='5'>Total Price with Shipping</th>
          <th>{order?.totalPrice} OMR</th>
        </tr>
      </tbody>
      </Table>
      </div>
    </> );
}

export default OrderDetails;